angular
  .module('app')
  .service('AdminSvc', function () {
    const svc = this;

    svc.setSelectedTemplate = (template) => {
      if (!template) return;

      svc.selectedTemplate = template;
    };
  });
