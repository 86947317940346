angular
  .module('app')
  .service('TemplateSvc', function ($http, AppSvc, UserSvc, BadgeSvc, CategorySvc) {
    const svc = this;
    svc.enteredData = [];
    // used for data reverse again after entering print page
    svc.isDataReversing = false;
    svc.loadedFonts = [];
    svc.imagesBase64 = [];
    let doHistory = [];
    let doHistoryStep = 0;

    if (window.localStorage.recentTemplates) {
      svc.recentTemplates = JSON.parse(window.localStorage.recentTemplates);
    } else {
      svc.recentTemplates = [];
    }

    //Admin only
    svc.getTemplates = () =>
      $http.get('/api/templates').then(({ data }) => {
        const templates = data;
        return initTemplates(templates);
      });

    svc.getTemplatesByCompany = (company) =>
      $http.get(`/api/templates/company/${company}`).then(({ data }) => {
        const templates = data;
        return initTemplates(templates);
      });

    svc.getUserTemplatesByCompany = (company) =>
      $http.get(`/api/templates/company/${company}/users`).then(({ data }) => {
        const templates = data;
        return initTemplates(templates);
      });

    svc.getTemplate = (template) => $http.get(`/api/templates/${template}`);

    svc.getTemplatesByCompanyAndUser = (company, user, badge = 'all') =>
      $http.get(`/api/templates/company/${company}/user/${user}/${badge}`).then(({ data }) => {
        const templates = data.filter(({ enabled }) => {
          if (typeof enabled === 'undefined') {
            return true;
          } else {
            return enabled;
          }
        });
        CategorySvc.setCategories(AppSvc.user.company, templates);
        return initTemplates(templates);
      });

    svc.getTemplatesByUser = (user) =>
      $http.get(`/api/templates/user/${user}`).then(({ data }) => {
        const templates = data;
        return initTemplates(templates);
      });

    svc.clearData = () => {
      svc.enteredData.length = 0;
    };

    function initTemplates(templates) {
      templates.forEach((template) => {
        if (template.type) {
          if (template.printDirection) {
            template.width = template.badge.width;
            template.height = template.badge.height;
          } else {
            template.width = template.badge.height;
            template.height = template.badge.width;
          }
          if (template.textboxes) {
            template.textboxes.forEach((textbox) => {
              textbox.originalFontName = textbox.fontName;
              textbox.originalFontLocation = textbox.fontLocation;
            });
          }
          if (!template.category) template.category = '';
        }
      });

      svc.templates = templates;
      return templates;
    }

    svc.setSelectedTemplate = (template) => {
      if (!template) return;
      template = fixTemplateComponentIndex(template);
      svc.selectedTemplate = template;

      if (_.some(svc.recentTemplates, (tmplt) => template._id === tmplt)) {
        svc.recentTemplates = svc.recentTemplates.sort((tmplt1, tmplt2) =>
          template._id !== tmplt1 ? (template._id !== tmplt2 ? 0 : 1) : -1
        );
      } else {
        if (svc.recentTemplates.length > 2) {
          svc.recentTemplates.pop();
        }
        if (template._id) svc.recentTemplates.unshift(template._id);
      }
      if (template._id) window.localStorage.selectedTemplate = template._id;
      window.localStorage.recentTemplates = JSON.stringify(svc.recentTemplates);
      BadgeSvc.setSelectedBadge(template.badge);
    };

    function fixTemplateComponentIndex (template) {
      let components = template.images.concat(template.textboxes);
      let currentNumber = components.length;

      while (currentNumber > 0) {
        let largestIndexComponent = components.reduce((max, e) => max.index > e.index ? max : e);
        components = components.filter(e => e !== largestIndexComponent);

        largestIndexComponent.index = currentNumber--;
      }

      return template;
    }

    svc.addTextbox = ({ _id }) =>
      $http.post(`/api/templates/${_id}/textbox`, {}).then((response) => {
        console.log('textbox added');
      });

    //Create style node - This is used for temporary (guest) editable images
    svc.addImageCID = (image) => {
      const cid = 'cxxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      svc.imagesBase64.push({
        class: cid,
        image,
      });
      return cid;
    };

    //Prevents fonts being loaded more than once and also appropriately loads fonts depending on their origin

    svc.getDefaultFonts = () => {
      $http.get('/api/assets/fonts', {}).then(({ data }) => {
        loadFonts(data);
      });
    };

    svc.getUserFonts = ({ _id }) => {
      $http.get(`/api/assets/fonts/user/${_id}`, {}).then(({ data }) => {
        loadFonts(data);
      });
    };

    function loadFonts(fonts) {
      svc.loadedFonts = svc.loadedFonts.concat(
        fonts
          .filter((font) => !font.fontName.includes('Noto') && font.fontName !== 'NotoSans')
          .filter(
            (font) => !_.find(svc.loadedFonts, (loadedFont) => loadedFont.fontName == font.fontName)
          )
          .map((font) => {
            $('head').append(`<!--${font.fontName}--><style>${font.fontFace}</style>`);
            return font;
          })
      );
    }

    //svc.getDefaultFonts();

    svc.loadFont = (fontName) => `"${fontName}"`;
    /*
		if (fontType === 'win') {
		return fontName;
		} else if (!svc.checkLoadedFont(fontName) && fontType !== 'google') {
		return svc.loadFileFont(fontName);
		} else if (!svc.checkLoadedFont(fontName) && fontType === 'google') {
		return svc.loadGoogleFont(fontName);
		} else {
		return fontName;
		}*/

    svc.checkLoadedFont = (font) => {
      for (let i = 0; i < svc.loadedFonts.length; i++) {
        if (svc.loadedFonts[i].fontName === font) {
          return true;
        }
      }
      return false;
    };

    svc.loadGoogleFont = (googleFont) => {
      googleFont = {
        fontName: googleFont,
        fontTypes: ['google'],
        fontFace: `<link rel='stylesheet' type='text/css' href='//fonts.googleapis.com/css?family=${googleFont.replace(
          /[ ]/g,
          '+'
        )}'>`,
      };
      svc.loadedFonts.push(googleFont);
      $('head').append(googleFont.fontFace);
      return googleFont.fontName;
    };

    svc.loadFileFont = (fileFont, fontType) => {
      fileFont = {
        fontName: fileFont,
        fontTypes: fontType.split('/'),
        fontFace: '',
      };
      fileFont.fontFace = `@font-face{font-family:'${fileFont}';`;
      fileFont.fontFace +=
        fileFont.fontTypes.filter((fontType) => fontType === 'eot').length > 0
          ? `src:url('/fonts/${fileFont.fontName}.eot');`
          : '';
      fileFont.fontFace += 'src:';
      for (let i = 0; i < fileFont.fontTypes.length; i++) {
        fileFont.fontFace += `url('/fonts/${fileFont.fontName}.${fileFont.fontTypes[i]}${
          fileFont.fontTypes[i] === 'eot' ? '?#iefix' : ''
        }') format('${svc.getFileFontType(fileFont.fontTypes[i])}')`;
        if (i < fileFont.fontTypes.length - 1) {
          fileFont.fontFace += ',';
        }
      }
      fileFont.fontFace += ';}';
      $('head').append(`<style>${fileFont.fontFace}</style>`);
      svc.loadedFonts.push(fileFont);
      return fileFont;
    };

    svc.getFileFontType = (fontType) => {
      switch (fontType) {
        case 'ttf':
          return 'truetype';
        case 'otf':
          return 'opentype';
        case 'woff':
          return 'woff';
        case 'woff2':
          return 'woff2';
        case 'eot':
          return 'embedded-opentype';
        case 'svg':
          return 'svg';
        default:
          return false;
      }
    };

    svc.getTextStyle = ({ width, height }, textbox, modifier, index = 500, optimizeBackgroundColor = false) => {
      //The modifier should be a decimal based on the screen width
      if (!textbox.adjustedFontSizePct) {
        textbox.adjustedFontSizePct = 1;
      }
      let style = {
        position: 'absolute',
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': textbox.wordwrap ? 'flex-start' : 'center',
        'white-space': textbox.wordwrap ? 'pre-line' : 'nowrap',
        'word-break': textbox.wordwrap ? 'break-word' : 'normal',
        'letter-spacing': `${textbox.letterSpacing}in`,
        width: `${(textbox.width / width) * 100}%`,
        height: `${(textbox.height / height) * 100}%`,
        left: `${(textbox.leftOffset / width) * 100}%`,
        top: `${(textbox.topOffset / height) * 100}%`,
        //'top': ( (textbox.topOffset + textbox.topOffset * ((1 - textbox.adjustedFontSizePct) / 2)) / input.badge.height ) * 100 + '%',
        'text-align':
          textbox.alignment === 0 ? 'left' : textbox.alignment === 1 ? 'center' : 'right',
        'font-family': svc.loadFont(textbox.fontName),
        'font-size': `${Math.ceil(textbox.fontSize * textbox.adjustedFontSizePct * modifier)}pt`,
        'font-weight': textbox.fontWeight === 0 ? 'normal' : 'bold',
        'font-style':
          textbox.fontStyle === 0 ? 'normal' : textbox.fontStyle === 1 ? 'italic' : 'oblique',
        color: textbox.fontColor,
        'text-transform':
          textbox.case === 0 ? 'none' : textbox.case === 1 ? 'uppercase' : 'lowercase',
        //'z-index': 2
        'z-index': index + textbox.index,
      };
      if (!textbox.fontName) {
        console.log(textbox);
      }
      if (optimizeBackgroundColor && checkFontColorIsTooLight(style.color)) {
        console.log(self);
        style['background-color'] = '#787878';
      }
      return style;
    };



    function checkFontColorIsTooLight (color) {
      if (!color) return false;
      const r = parseInt(color.substring(1, 3), 16);
      const g = parseInt(color.substring(3, 5), 16);
      const b = parseInt(color.substring(5), 16);
      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
      if (hsp > 200) {
        return true;
      } else {
        return false;
      }
    };

    svc.getFontStyle = (template, textbox, modifier, item, additional) => {
      console.log(textbox);
      //The modifier should be a decimal based on the screen width
      if (!textbox.adjustedFontSizePct) {
        textbox.adjustedFontSizePct = 1;
      }
      if (item) {
        return {
          ...{
            'font-family': svc.loadFont(item.altFontName ? item.altFontName : textbox.fontName),
            'font-weight': textbox.fontWeight === 0 ? 'normal' : 'bold',
            'font-style':
              textbox.fontStyle === 0 ? 'normal' : textbox.fontStyle === 1 ? 'italic' : 'oblique',
            'text-transform':
              textbox.case === 0 ? 'none' : textbox.case === 1 ? 'uppercase' : 'lowercase',
            'white-space': textbox.wordwrap ? 'pre-line' : 'nowrap',
            'letter-spacing': `${textbox.letterSpacing}in`,
            transform: `rotate(${textbox.rotation}deg)`,
            color: textbox.fontColor,
          },
          ...additional
        };
      } else {
        return {
          ...{
            'font-family': svc.loadFont(textbox.fontName),
            'font-size': `${Math.ceil(textbox.fontSize * textbox.adjustedFontSizePct * modifier)}pt`,
            'font-weight': textbox.fontWeight === 0 ? 'normal' : 'bold',
            'font-style':
              textbox.fontStyle === 0 ? 'normal' : textbox.fontStyle === 1 ? 'italic' : 'oblique',
            'white-space': textbox.wordwrap ? 'pre-line' : 'nowrap',
            'letter-spacing': `${textbox.letterSpacing}in`,
            'text-transform':
              textbox.case === 0 ? 'none' : textbox.case === 1 ? 'uppercase' : 'lowercase',
            transform: `rotate(${textbox.rotation}deg)`,
            color: textbox.fontColor,
          },
          ...additional
        };
      }
    };

    /*
		//Not necessary anymore as I calculate the aspect ratio on the fly now
		svc.getOriginalImageStyle = function(template, image) {
		   if (image.isBackground) {
		     return {
		       'position': 'absolute',
		       'width': '150px',
		       'height': '150px',
		       'margin': '0 auto',
		       'left': '150px'
		     };
		   } else {
		     var leftOffset = (image.leftOffset / template.badge.width);
		     var topOffset = (image.topOffset / template.badge.height);
		     var width = (image.width / template.badge.width);
		     var height = (image.height / template.badge.height);
		     return {
		      'position': 'absolute',
		      'width': width * 100 + '%',
		      'height': height * 100 + '%',
		      'left': leftOffset * 100 + '%'
		     };
		   }
		};
		*/

    svc.getImageStyle = (template, image, editing) => {
      let style = {};
      const leftOffset = image.leftOffset / template.width;
      const topOffset = image.topOffset / template.height;
      const width = image.width / template.width;
      const height = image.height / template.height;
      if (!image.originalSize) {
        image.originalSize = {
          width: image.width,
          height: image.height,
        };
      }
      if (image.isBackground) {
        $(`#${image.cid}`).css('padding-top', '0px');
        $(`#${image.cid}`).css('margin-top', '0px');
        $(`#${image.cid}`).css('margin-left', '0px');
        $(`#${image.cid}`).css('padding-left', '0px');
        $(`#${image.cid}`).css(
          'border-radius',
          template.badge.shape === 'Rectangle' ? '5px' : '50%'
        );
        $(`#${image.cid}`).css('width', '100%');
        if (template.printDirection) {
          style = {
            position: 'absolute',
            //Full Bleed Adjustment
            width: `${(image.isFullBleed ? (template.width + 0.5) / template.width : 1) * 100}%`,
            height: `${100}%`,
            margin: 0,
            padding: 0,
            'z-index': 500 + image.index,
            //'z-index': 0,
            //Full Bleed Adjustment
            left: image.isFullBleed ? `${(-0.25 / template.width) * 100}%` : '0px',
            top: 0,
            opacity: 0.6,
            overflow: 'hidden',
            ...BadgeSvc.getShapeStyle(template.badge.shape),
          };
        } else {
          style = {
            position: 'absolute',
            //Full Bleed Adjustment
            width: `${100}%`,
            height: `${(image.isFullBleed ? (template.height + 0.5) / template.height : 1) * 100}%`,
            margin: 0,
            padding: 0,
            'z-index': 500 + image.index,
            //'z-index': 0,
            //Full Bleed Adjustment
            left: 0,
            top: image.isFullBleed ? `${(-0.25 / template.height) * 100}%` : '0px',
            opacity: 0.6,
            overflow: 'hidden',
            'border-radius': template.badge.shape === 'Rectangle' ? '5px' : '50%',
          };
        }
      } else {
        style = {
          position: 'absolute',
          width: `${width * 100}%`,
          height: `${height * 100}%`,
          left: `${leftOffset * 100}%`,
          top: `${topOffset * 100}%`,
          'z-index': 500 + image.index,
          //'transform': 'rotate(' + image.rotation + 'deg)',
          //'z-index': 1
        };
      }
      return style;
    };

    svc.getImageAspectRatio = (image, location) => {
      if (!image) return;
      if (image.aspectRatio && image.aspectRatio.height) return image.aspectRatio;
      if (image.keepAspectRatio && !image.isBackground) {
        //Calculates how the image should be moved if aspect ratio is to be kept
        const div = `imgdiv_${location}`;
        const img = `img_${location}`;
        //(image.cid ? image.cid : image._id)
        //If none of the image fields are visible in data entry, then just return the image stretched to fit
        /*if (!$('#' + img).length) {
				  return {
				    'width': '100%',
				    'height': '100%',
				    'max-width': '100%',
				    'max-height': '100%'
				  };
				}*/
        let width,
          nWidth,
          height,
          nHeight,
          timeout = 0;
        //TODO: Get rid of slight delay when a new image is uploaded by the user
        //Get the dimensions of the original image and the div it's in
        if (document.getElementById(img)) {
          nWidth = document.getElementById(img).naturalWidth;
          nHeight = document.getElementById(img).naturalHeight;
        } else {
          return {};
        }
        if (!nWidth || !nHeight) {
          nWidth = image.width;
          nHeight = image.height;
        }
        width = image.width;
        height = image.height;

        //Determine whether to adjust the height or the width
        //If the height needs to be adjusted, the width will be set to 100%
        //If the width needs to be adjusted, the height will be set to 100%
        //BUG: There are some issues with keeping aspect ratio for square images (height = width)
        let adjust = '';
        let style;
        if (width / height < nWidth / nHeight) {
          adjust = 'height';
        } else {
          adjust = 'width';
        }
        if (adjust === 'width') {
          width = (((nWidth / nHeight) * height) / width) * 100;
          style = {
            position: 'absolute',
            //Apply the percentage adjustment for the height difference to the original width to get the new width
            width: `${width}%`,
            height: '100%',
            //Margin left is equal to the difference between the original width vs the new width
            //divided by 2 (because it needs to be adjusted on both sides)
            'margin-left': `${(100 - width) / 2}%`,
            top: 0,
            //left:0 because it shifts everything over 50% without it. I haven't figured out why
            left: 0,
            transform: `rotate(${image.rotation}deg)`,
          };
        } else if (adjust === 'height') {
          height = (((nHeight / nWidth) * width) / height) * 100;
          //Same logic as width but with height instead
          style = {
            position: 'absolute',
            width: '100%',
            height: `${height}%`,
            left: 0,
            //Margin-top does not work for vertical alignment - so instead here it's top and translate
            top: '50%',
            transform: `translate(0, -50%) rotate(${image.rotation}deg)`,
          };
        } else {
          console.log('Adjust nothing, Image fits perfectly');
        }
        return style;
      } else {
        //Stretched to fit
        return {
          position: 'absolute',
          left: 0,
          width: '100%',
          height: '100%',
          'max-width': '100%',
          'max-height': '100%',
        };
      }
    };

    svc.getImageSrc = (company, image, user) => {
      if (!image) return;
      if (!image.name) return '';
      //if (image.dir && image.dir.includes('/dropdowns/')) console.log(image);
      if (image.file) return image.file;

      const src =
        (image.isDropdown
          ? `${
              (image.dropdownFolder.isCurated ? 'images/dropdowns/' : `companies/${company}/`) +
              image.dropdownFolder.dir
            }/`
          : `companies/${company}${
              image.type === 'user' ? `/users/${user ? user : AppSvc.user._id}` : '/images'
            }/`) + (image.image && image.image._id ? image.image._id : image.name);
      if (!(image.image && image.image._id ? image.image._id : image.name)) console.log(image);

      return `${src}${image.type === 'user' ? `?token=${UserSvc.getToken()}` : ''}`;
    };

    svc.getTemplateSrc = (template) =>
      `companies/${template.company.number}${
        template.type === 'user' ? `/users/${template.creator}` : ''
      }/templates/${template._id}.png${
        template.type === 'user' ? `?token=${UserSvc.getToken()}` : ''
      }`;

    //The canvas has a little area for objects to "bleed" into
    svc.getCanvasStyle = (template, canvasWidth, modifier, editing) => {
      let width, height;
      if (template.printDirection) {
        width = template.badge.width;
        height = template.badge.height;
      } else {
        width = template.badge.height;
        height = template.badge.width;
      }
      return {
        width: `${
          ((width + (editing ? (template.printDirection ? 0.5 : 0) : 0)) *
            (canvasWidth * modifier)) /
          width
        }px`,
        height: `${
          ((canvasWidth * modifier) / width) * (height + (template.printDirection ? 0 : 0.5))
        }px`,
        'padding-top': editing
          ? template.printDirection
            ? 0
            : `${((0.25 * (canvasWidth * modifier)) / template.width) * modifier}px`
          : 0,
      };
    };

    svc.getTemplateStyle = (template, canvasWidth, modifier, overflow = 'hidden') => {
      if (!template.badge.shape) {
        return {};
      }
      let width, height;
      /*
			    if (template.printDirection) {*/
      height = ((canvasWidth * modifier) / template.width) * template.height;
      width = canvasWidth * modifier;
      /*
			    } else {
			      height = (canvasWidth * modifier);
			      width = ((canvasWidth * modifier) / template.badge.width * template.badge.height);
			    }*/
      const style = {
        width: `${width}px`,
        height: `${height}px`,
        overflow,
        ...(overflow === 'hidden' ? BadgeSvc.getShapeBorderStyle(template.badge, modifier) : {}),
      };

      //if (template.badge.shape.indexOf('Half') >= 0) console.log(style);
      return style;
    };
    /*
		  svc.imageStyleFix = function(image, callback) {
		    console.log('is this used?');
		    var myImage = new Image();
		    myImage.onload = function () {
		      if (image.isBackground) {
		        return;
		      }
		      var textbox = document.getElementById(image.cid);
		      //var magicNumber = 0.01041666666667;
		    };
		    myImage.src = image.file;
		    image.loading = false;
		    return callback;
		  };*/

    svc.resizeTemplates = (templates, dpi, callback) => {
      if (!templates) return;
      templates.forEach((template) => {
        if (template.printDirection) {
          template.width = template.badge.width;
          template.height = template.badge.height;
        } else {
          template.width = template.badge.height;
          template.height = template.badge.width;
        }
        //console.log(template);
        for (var j in template.textboxes) {
          const defaultFontSize =
            (AppSvc.getWidth(template.width, template.height) / dpi / template.width) *
            template.textboxes[j].fontSize;
          //var fontName = svc.loadFont(template.textboxes[j].fontName);
          template.textboxes[j].defaultFontSize = defaultFontSize;
          if (!template.textboxes[j].value) {
            template.textboxes[j].value = template.textboxes[j].isDropdown
              ? template.textboxes[j].placeholder
              : '';
          }
        }
        for (j in template.images) {
          initImage(template.images[j]);
        }
      });
      callback();
    };

    function initImage(image) {
      if (!image.originalSize) {
        image.originalSize = {
          width: image.width,
          height: image.height,
        };
      }
      //console.log(image.originalSize);
      if (image.isDropdown) {
        image.value = image.name;
      }
      image.cid = 'cxxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }

    svc.saveTemplate = (template, createImage = false) => {
      if (template._id) {
        template.modifyDate = new Date();
        return $http
          .put(`/api/templates/${template._id}${createImage ? '?image=true' : ''}`, template)
          .then(
            ({ data }) => {
              const template = data;
              if (template.printDirection) {
                template.width = template.badge.width;
                template.height = template.badge.height;
              } else {
                template.width = template.badge.height;
                template.height = template.badge.width;
              }
              svc.setSelectedTemplate(template);
              return template;
            },
            (err) => err
          );
      } else {
        template._id = ObjectId().toString();
        return $http.post('/api/templates', template).then(
          ({ data }) => {
            const template = data;
            if (template.printDirection) {
              template.width = template.badge.width;
              template.height = template.badge.height;
            } else {
              template.width = template.badge.height;
              template.height = template.badge.width;
            }
            svc.setSelectedTemplate(template);
            return template;
          },
          (err) => err
        );
      }
    };

    svc.cloneTemplate = ({ _id }, user) => {
      user.sessions = null;
      return $http.post(`/api/templates/${_id}/clone`, user);
    };

    svc.deleteTemplate = ({ _id }) => {
      if (_id) {
        return $http.delete(`/api/templates/${_id}`);
      }
    };

    svc.assignTemplate = ({ _id }, company, user) =>
      user
        ? $http.post(`/api/templates/${_id}/company/${company}/user/${user._id}`)
        : $http.post(`/api/templates/${_id}/company/${company}`);

    svc.moveObject = (template, type, object) => {
      try {
        const left = Math.round($(`#${object._id}_${type}div_edit`).position().left);
        const top = Math.round($(`#${object._id}_${type}div_edit`).position().top);
        const width = Math.round($(`#${object._id}_${type}div_edit`).width());
        const height = Math.round($(`#${object._id}_${type}div_edit`).height());
        object.leftOffset = parseFloat(
          ((left * template.width) / $('.template').width()).toFixed(4)
        );
        object.topOffset = parseFloat(
          ((top * template.height) / $('.template').height()).toFixed(4)
        );
        object.width = parseFloat(((width * template.width) / $('.template').width()).toFixed(4));
        object.height = parseFloat(
          ((height * template.height) / $('.template').height()).toFixed(4)
        );
      } catch (e) {}
      /*
			//First attempt at snapping
			var objects = template.textboxes.concat(template.images);
			var closeObject = _.find(objects, function(closeObject) {
			  return closeObject._id !== object._id && (
			      Math.abs(closeObject.leftOffset - object.leftOffset) <= 0.05 ||
			      Math.abs(closeObject.topOffset - object.topOffset) <= 0.05 ||
			      Math.abs(closeObject.width - object.width) <= 0.05 ||
			      Math.abs(closeObject.height - object.height) <= 0.05
			    );
			});
			if (closeObject) {
			  if (Math.abs(closeObject.leftOffset - object.leftOffset) <= 0.05) {
			    object.leftOffset = closeObject.leftOffset;
			  }
			  if (Math.abs(closeObject.topOffset - object.topOffset) <= 0.05) {
			    object.topOffset = closeObject.topOffset;
			  }
			  if (Math.abs((closeObject.width + closeObject.leftOffset) - (object.width + object.leftOffset)) <= 0.05) {
			    object.width = (closeObject.width + closeObject.leftOffset) - object.leftOffset ;
			  }
			  if (Math.abs((closeObject.height + closeObject.topOffset) - (object.height + object.topOffset)) <= 0.05) {
			    object.height = (closeObject.height + closeObject.topOffset) - object.topOffset ;
			  }
			}*/
    };

    svc.reset = (template) => {
      doHistory.push(JSON.parse(JSON.stringify(template)));
      doHistoryStep++;
      return doHistory[0];
    };

    svc.do = (template) => {
      doHistory.splice(doHistoryStep + 1, doHistory.length, JSON.parse(JSON.stringify(template)));
      doHistoryStep = doHistory.length;
    };

    svc.undo = (template) => {
      if (doHistoryStep === doHistory.length) {
        doHistory.push(JSON.parse(JSON.stringify(template)));
      }
      return doHistory[--doHistoryStep];
    };

    svc.redo = () => doHistory[++doHistoryStep];

    svc.resetHistory = (template) => {
      doHistory = [];
      doHistoryStep = 0;
    };

    svc.checkHistoryStep = (button) => {
      if (button === 'undo') {
        return doHistoryStep > 0;
      } else {
        return doHistoryStep < doHistory.length - 1;
      }
    };

    svc.setBadge = (template, badge) => {
      let ratioWidth, ratioHeight;
      if (badge.shape.indexOf('Half') >= 0) {
        template.printDirection = true;
      }
      if (template.printDirection) {
        template.width = badge.width;
        template.height = badge.height;
        ratioWidth = badge.width / template.badge.width;
        ratioHeight = badge.height / template.badge.height;
      } else {
        template.width = badge.height;
        template.height = badge.width;
        ratioWidth = badge.height / template.badge.height;
        ratioHeight = badge.width / template.badge.width;
      }
      template.textboxes.forEach((textbox) => {
        textbox.width = Math.round(textbox.width * ratioWidth * 10000) / 10000;
        textbox.height = Math.round(textbox.height * ratioHeight * 10000) / 10000;
        textbox.leftOffset = Math.round(textbox.leftOffset * ratioWidth * 10000) / 10000;
        textbox.topOffset = Math.round(textbox.topOffset * ratioHeight * 10000) / 10000;
        textbox.value = '';
        //This makes the font size appropriately
        textbox.fontSize = Math.round(textbox.fontSize * ratioHeight);
        const defaultFontSize =
          (AppSvc.getWidth(template.width, template.height) / AppSvc.dpi / template.width) *
          textbox.fontSize;
        textbox.defaultFontSize = defaultFontSize;
      });

      template.images.forEach((image) => {
        image.width = Math.round(image.width * ratioWidth * 10000) / 10000;
        image.height = Math.round(image.height * ratioHeight * 10000) / 10000;
        image.leftOffset = Math.round(image.leftOffset * ratioWidth * 10000) / 10000;
        image.topOffset = Math.round(image.topOffset * ratioHeight * 10000) / 10000;
      });
      template.badge = badge;
    };
  });
