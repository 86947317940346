/**
 * Angular Admin - Company Fonts Tab Controller
 * @file ng/controllers/admin/admin.company.fonts.ctrl.js
 * @class angular.controllers.AdminCompanyFontsCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'AdminCompanyFontsCtrl',
		function ($scope, $stateParams, FileSvc) {
			$scope.getFonts = function () {
				FileSvc.getCompanyFonts($stateParams.company).then(response => {
					$scope.fonts = response.data;
					$scope.loading = false;
				});
			};

			$scope.getFonts();

			$scope.uploadFont = function () {
				$scope.loading = true;
				FileSvc.uploadFontToCompany($scope.newFile, $stateParams.company).then(
					function (response) {
						//TODO: refresh Files after upload
						$scope.newFile = null;
						$scope.getFonts();
						// $state.reload();
					}
				);
			};

			$scope.deleteFile = function (filename) {
				var question = {
					title: $scope.getCopy('delete') + ' - ' + filename.name,
					question:
						$scope.getCopy('deleteFile') +
						' ' +
						$scope.getCopy('messageActionIrreversible'),
					model: 'file',
					api: 'company',
					action: 'delete',
					data: { company: $stateParams.company, filename: filename.name },
					exec: function () {
						FileSvc.deleteFileFromCompany(question.data).then(function (
							response
						) {
							$scope.$emit('questionExecuted', response, question);
							$scope.getFonts();
						});
					},
				};
				$scope.askQueryQuestion(question);
			};
		}
	);
