/**
* Angular Admin - Company Options Tab Controller
* @file ng/controllers/admin/admin.company.Options.ctrl.js
* @class angular.controllers.AdminCompanyOptionsCtrl
* @memberOf angular.controllers
*/
angular.module('app')
.controller('AdminCompanyTemplateHeaderCtrl', function ($scope, $stateParams, CompanySvc, AppSvc) {

});
