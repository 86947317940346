/**
 * Angular Support Controller
 * @file ng/controllers/support.ctrl.js
 * @class angular.controllers.SupportCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'SupportCtrl',
		function (
			$scope,
			$location,
			$stateParams,
			$http,
			$sce,
			AppSvc,
			CompanySvc
		) {
			$('.modal').modal('hide');
			$('.modal-backdrop').remove();

			$scope.selectArticle = function (article) {
				if ($scope.selectedArticle !== article) {
					$scope.selectedArticle = article;
				} else {
					$scope.selectedArticle = '';
				}
			};

			//Get the generic help.json
			$http({
				method: 'GET',
				url: 'npgcloud-help.json',
			})
				.then(
					function (response) {
						$scope.tabs = response.data;
					},
					function (error) {
						console.log(error);
					}
				)
				.then(function () {
					//Add the company specific help.json (if any)
					//Get the generic help.json
					if ($scope.loggedIn) {
						$http({
							method: 'GET',
							url: 'companies/' + AppSvc.user.company.number + '/help.json',
						}).then(
							function (response) {
								console.log(response.data);

								if (Array.isArray(response.data)) {
									//IF a help.json file exist and is a valid format
									if (AppSvc.user.guest || AppSvc.user.company.autoHelpFile) {
										$scope.tabs = response.data;
									} else {
										$.merge($scope.tabs, response.data);
									}
								}
								if ($stateParams.article) {
									$scope.selectArticle($stateParams.article);
								}
								//Below is not necessary as it already gets the help.json file
								/*else {
						    // IF there is no custom help file we will create one (help.handlebars)
						    // IF the "autoHelpFile" flag is true in the company, (by default it's off)
						    console.log(AppSvc.user.company.autoHelpFile);
						  if (AppSvc.user.company.autoHelpFile) {
						    $http.get('youwho/help.json')
						    .success(function(response) {  console.log(response); $.merge($scope.tabs, response); })
						    .error(function(error) { console.log(error); });
						  }
						}*/
							},
							function (error) {
								console.log(error);
							}
						);
					} else {
						if ($stateParams.article) {
							$scope.selectArticle($stateParams.article);
						}
					}
				});

			//This gets the handlebars generic help
			$scope.player = $sce.trustAsResourceUrl(
				$scope.loggedIn && AppSvc.user
					? '/api/companies/' + AppSvc.user.company.number + '/help'
					: ''
			);

			$scope.getIframeSrc = src =>
				`companies/${AppSvc.user.company.number}/${src}`;

			$scope.printIframe = function () {
				document.printf.printMe();
			};
		}
	);
