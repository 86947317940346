/**
 * Angular Admin - Company Categories Tab Controller
 * @file ng/controllers/admin/admin.company.categories.ctrl.js
 * @class angular.controllers.AdminCompanCategoriesCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'AdminCompanCategoriesCtrl',
		function ($scope, AppSvc, CompanySvc) {
			$scope.getColumnWidth = length => `col-${Math.floor(88 / length)}`;

			$scope.addCategory = () => {
				$scope.company.categories.push({
					name: '',
					copy: Object.keys(AppSvc.appCopy.language).map(function (language) {
						return { language: language, name: '' };
					}),
					subcategories: [],
				});
				$scope.addSubcategory(
					$scope.company.categories[$scope.company.categories.length - 1]
				);
			};

			$scope.addSubcategory = category => {
				category.subcategories.push({
					name: '',
					copy: Object.keys(AppSvc.appCopy.language).map(function (language) {
						return { language: language, name: '' };
					}),
				});
			};

			$scope.deleteCategory = category => {
				CategorySvc.deleteCategory(
					$scope.company._id,
					category
				).then(response => {});
			};

			$scope.deleteSubcategory = subcategory => {};
		}
	);
