/**
 * Angular Admin - Badges Controller
 * @file ng/controllers/admin/admin.badges.ctrl.js
 * @class angular.controllers.AdminBadgesCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'AdminBadgesCtrl',
		function ($scope, $location, AppSvc, BadgeSvc, TemplateSvc, ConstantsSvc) {
			if (!AppSvc.user || !(AppSvc.user.admin || AppSvc.user.options)) {
				$location.path(AppSvc.getURL());
			}

			BadgeSvc.getBadges().then(function (response) {
				$scope.badges = response.data;
				ConstantsSvc.getBackgrounds().then(function (response) {
					$scope.backgrounds = response.data;
					$scope.shapes = BadgeSvc.getShapes();
				});
			});

			$scope.languages = Object.keys(AppSvc.appCopy.language);

			//Gets all the languages for a badge that do not have a translation yet.
			$scope.getLanguages = function (copy) {
				return $scope.languages.filter(function (language) {
					return !_.find(copy, function (languagecopy) {
						return language === languagecopy.language;
					});
				});
			};

			$scope.addBadge = function () {
				var today = new Date();
				$scope.badges.unshift({
					code: '',
					description: '',
					creator: AppSvc.user._id,
					date: today.toISOString(),
					shape: 'Rectangle',
					background: ['silver', 'gold', 'white', 'copper'],
					width: 0.0,
					height: 0.0,
					copy: [],
					enabled: true,
				});
			};

			$scope.isBackgroundSelected = (badge, background) => {
				return _.some(badge.backgrounds, badgeBG => badgeBG == background.name)
					? 'btn-success active'
					: 'btn-default';
			};

			$scope.toggleBackground = (badge, background) => {
				if (_.some(badge.backgrounds, badgeBG => badgeBG == background.name)) {
					badge.backgrounds.splice(badge.backgrounds.indexOf(background.name), 1);
				} else {
					badge.backgrounds.push(background.name);
				}
			};

			$scope.saveBadge = function (badge) {
				console.log(badge);
				var newLanguages = $scope.getLanguages(badge.copy);
				badge.saving = true;
				for (var i in newLanguages) {
					badge.copy.push({
						language: newLanguages[i],
						name: $('#' + badge.code + '_' + newLanguages[i]).val(),
					});
				}

				if (badge._id) {
					// Update badge
					BadgeSvc.updateBadge(badge._id, badge).then(
						function () {
							$scope.$emit('popup', {
								message: badge.code + ' ' + $scope.getCopy('saved'),
								type: 'alert-success',
							});
							badge.saving = false;
						},
						err => {
							$scope.$emit('popup', {
								message: $scope.getCopy('fillAllFieldsError'),
								type: 'alert-danger',
							});
							badge.saving = false;
						}
					);
				} else {
					// Create badge
					BadgeSvc.saveBadge(badge).then(
						function (response) {
							$scope.$emit('popup', {
								message: badge.code + ' ' + $scope.getCopy('saved'),
								type: 'alert-success',
							});
							badge.saving = false;
							badge._id = response._id;
						},
						err => {
							$scope.$emit('popup', {
								message: $scope.getCopy('fillAllFieldsError'),
								type: 'alert-danger',
							});
							badge.saving = false;
						}
					);
				}
			};

			//Displays the badge measurements
			$scope.getBadgeMeasurements = function (badge) {
				return (
					Math.round(badge.width * 10) / 10 + '" x ' + Math.round(badge.height * 10) / 10 + '"'
				);
			};

			$scope.getBadgeStyle = function (badge) {
				//Change the multiplication number to increase/decrease the size of the displayed badges
				var displayWidth = $('.badge-div').width() * 0.8;
				var displayHeight = (displayWidth / badge.width) * badge.height;
				if (displayHeight > 90) {
					displayHeight = 90;
					displayWidth = (displayHeight / badge.height) * badge.width;
				}
				return {
					...BadgeSvc.getShapeStyle(badge),
					...TemplateSvc.getTemplateStyle(
						{
							width: badge.width,
							height: badge.height,
							badge: badge,
						},
						displayWidth,
						0.99,
						'hidden'
					),
				};
				return {
					width: displayWidth + 'px',
					'line-height': displayHeight / 2 + 'px',
					...BadgeSvc.getShapeStyle(badge),
				};
			};
		}
	);
