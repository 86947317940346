/**
 * @ngdoc function
 * @name npgc.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the Data Entry Page
 * @requires $scope
 * @requires $state
 * @requires $stateParams
 * @requires $location
 * @requires AppSvc
 * @requires TemplateSvc
 * @requires UserSvc
 */
angular
	.module('app')
	.controller(
		'LoginCtrl',
		function (
			$scope,
			$location,
			$stateParams,
			$state,
			AppSvc,
			UserSvc,
			CompanySvc,
			TemplateSvc,
			MagentoSvc,
			gRecaptcha,
			env
		) {
			gRecaptcha.initialize({key: env.captchaSiteKey});

			if ($location.search().rt) {
				AppSvc.changeRegisterType('SN');
			}
			if ($location.search().language) {
				let lang = $location.search().language;
				if (!['EN', 'FR', 'NL', 'ES', 'DE', 'PT'].includes(lang)) lang = 'EN';
				AppSvc.setLanguage(lang);
			}
			/**
			 * @ngdoc function
			 * @name checkState
			 * @methodOf npgc.controller:LoginCtrl
			 * @description
			 * Checks if there the browser remembers who was logged in
			 */
			if ($stateParams.token) {
				MagentoSvc.login($stateParams.token).then(function (response) {
					$scope.$emit('login', response.data);
					if (TemplateSvc.recentTemplates.length > 0) {
						$state.go('dataentry', { edit: false });
					} else {
						$location.path('/templates');
					}
				});
			} else if (Object.keys($stateParams).length > 0 || $state.current.data) {
				$scope.loading = true;
				//Check Paper size
				//ie: https://npgcloud.com/[VALIDATION CODE]/letter
				//ie: https://npgcloud.com/company/[VALIDATION CODE]?paper=letter
				//Default to Letter
				//If paper is not defined it will default to the company default on the server side
				//$state.current.data.paper -> provided by routes.js
				//$location.search().paper -> provided by URL -> ?paper=letter
				//$stateParams.paper -> provided by URL -> /letter
				var paper, validationCode;
				if ($state.current.data) {
					paper =
						$state.current.data.paper ||
						$location.search().paper ||
						$stateParams.paper ||
						AppSvc.paper;
					validationCode =
						$stateParams.validation_code || $state.current.data.validation_code;
				} else {
					paper =
						$location.search().paper || $stateParams.paper || AppSvc.paper;
					validationCode = $stateParams.validation_code;
				}
				if (paper) AppSvc.setDefaultPaper(paper);
				console.log(paper);
				console.log(validationCode);
				//getLoginType on the server looks at the validation code
				CompanySvc.getLoginType(validationCode).then(
					function (response) {
						var company = response.data.number;
						var loginType = response.data.loginType;

						if (loginType === 0) {
							//No Login
							UserSvc.guest(company, paper).then(
								function (response) {
									AppSvc.setCompany(response.data.company.number);
									console.log('Guest login suceeded, emitting login');
									$scope.$emit('login', response.data);
									if (
										TemplateSvc.recentTemplates &&
										['dataentry', ''].includes(response.data.company.startPage)
									) {
										$state.go('dataentry', { edit: false });
									} else {
										$location.path('/templates');
									}
								},
								function (err) {
									console.error('Guest login failed: ', err);
									//Login failed
									if (err.status === 401) {
										$scope.$emit('popup', {
											message: $scope.getCopy('messageLoginFailed'),
											type: 'alert-danger',
										});
									} else {
										$scope.$emit('popup', {
											message: $scope.getCopy('error'),
											type: 'alert-danger',
										});
									}
									$scope.loading = false;
								}
							);
						} else if (loginType < 0) {
							if ($stateParams.company) {
								//Serial Number Registry
								AppSvc.setRegisterType('SN');
								AppSvc.setCompany(company);
								$location.url($location.path('/home/' + paper));
							}
							$scope.loading = false;
						}
					},
					function (err) {
						console.error('Company not found: ', err);
						if ($stateParams.company) {
							$scope.$emit('popup', {
								message: $scope.getCopy('messageLoginFailed'),
								type: 'alert-danger',
							});
						}
						$scope.loading = false;
					}
				);
			}

			$scope.forgot = false;
			$scope.loginTries = 0;

			/**
			 * @ngdoc function
			 * @name login
			 * @methodOf npgc.controller:LoginCtrl
			 * @description
			 * Logs in the user or informs the user what is incorrect
			 */
			$scope.login = function () {
				$scope.loading = true;

				gRecaptcha.execute().then(function (captchaToken) {
					// data validation
					if ($scope.username && $scope.password && captchaToken) {
						UserSvc.login($scope.username, $scope.password, captchaToken).then(
							function (response) {
								//Emit to app that user is logged in
								$scope.$emit('login', response.data);
								if (TemplateSvc.recentTemplates &&
									['dataentry', ''].includes(response.data.company.startPage)) {
									$state.go('dataentry', {edit: false});
								} else {
									$location.path('/templates');
								}
							},
							function (response) {
								//Login failed
								$scope.loginTries++;
								$scope.loading = false;
								if (response.status === 401) {
									$scope.$emit('popup', {
										message: $scope.getCopy('messageInvalidPassword'),
										type: 'alert-danger',
									});
								} else if (response.status === 403) {
									$scope.$emit('popup', {
										message: $scope.getCopy('messageForgotPasswordNotAllowed'),
										type: 'alert-danger',
									});
								} else if (response.status === 404) {
									$scope.$emit('popup', {
										message: $scope.getCopy('messageUserNotFound'),
										type: 'alert-danger',
									});
								} else {
									$scope.$emit('popup', {
										message: $scope.getCopy('messageLoginFailed'),
										type: 'alert-danger',
									});
								}
							}
						);
					}
				});
			};

			/**
			 * @ngdoc function
			 * @name forgotToggle
			 * @methodOf npgc.controller:LoginCtrl
			 * @description
			 * Used for switching between "Forgot Password" and "Login" screens
			 */
			$scope.forgotToggle = function () {
				$scope.forgot = !$scope.forgot;
			};

			/**
			 * @ngdoc function
			 * @name forgotPassword
			 * @methodOf npgc.controller:LoginCtrl
			 * @description
			 * Triggered when the lost password button is clicked
			 */
			$scope.forgotPassword = function () {
				$scope.loading = true;

				if ($scope.userToSearch) {
					UserSvc.forgotPassword(
						$scope.userToSearch,
						$scope.selectedLanguage
					).then(
						function (response) {
							console.log(response.config.data.user);
							if (response.status === 200) {
								//200 = user found, password reset sent
								$scope.$emit('popup', {
									message:
										$scope.getCopy('messagePasswordResetEmail') +
										response.config.data.user,
									type: 'alert-success',
								});
								$scope.forgot = false;
								$scope.loading = false;
							} else if (response.status === 202) {
								//202 = single login user found, domain validated -> auto-login
								UserSvc.setToken(response.data).then(function (currentUser) {
									$scope.$emit('login', currentUser.data);
									$location.path('/dataentry');
									$scope.loading = false;
								});
							}
						},
						function (error) {
							if (error.status === 404) {
								//404 = the user wasn't found
								$scope.$emit('popup', {
									message: $scope.getCopy('messageUserNotFound'),
									type: 'alert-danger',
								});
							} if (error.status === 403) {
								//403 = the user is single login, reset password not allowed
								let question = {
									title: $scope.getCopy('warning'),
									question:  $scope.getCopy('messageForgotPasswordNotAllowed'),
									yes: $scope.getCopy('ok'),
									showNo: false,
									exec: function () {},
								};
								$scope.askHtmlRenderQuestion(question);
							} else if (error.status === 550) {
								//550 = there was an issue sending the email
								$scope.$emit('popup', {
									message:
										$scope.getCopy('messageEmailError') + ': ' + error.data,
									type: 'alert-danger',
								});
							} else {
								//anything else, the user was not authorized or something else happened
								$scope.$emit('popup', {
									message: $scope.getCopy('messageUserUnauthorized'),
									type: 'alert-danger',
								});
							}
							$scope.loading = false;
						}
					);
				}
			};
		}
	);
