/**
* Angular Entered Data Controller
* @file ng/controllers/admin/entered-data.ctrl.js
* @class angular.controllers.EnteredDataCtrl
* @memberOf angular.controllers
*/
angular.module('app')
.controller('EnteredDataCtrl', function($scope, TemplateSvc) {

    $scope.displayRatio = 0.6;
    $scope.id = 'dataentry';

    $scope.filteredData = function() {
      if (!TemplateSvc.enteredData || !$scope.selectedTemplate) return [];
      return TemplateSvc.enteredData.filter(function(tag) {
        return tag.badge.code === $scope.selectedTemplate.badge.code;
      });
    };

    $scope.getCanvasStyle = function(template) {
      var canvasWidth = $scope.getWidth(template.width, template.height);
      return {
        width: canvasWidth * $scope.displayRatio,
        height: canvasWidth / template.width * template.height * $scope.displayRatio
      };
    };
});
