/**
 * Angular Admin - Stats Controller
 * @file ng/controllers/admin/admin.stats.ctrl.js
 * @class angular.controllers.AdminStatsCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller('AdminStatsCtrl', function ($scope, $location, AppSvc, StatsSvc) {
		if (
			$scope.live &&
			(!AppSvc.user || !(AppSvc.user.admin || AppSvc.user.options))
		) {
			$location.path(AppSvc.getURL());
		}
		const MONTHS = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		$scope.sortBy = function (sorter) {
			$scope.reverse = $scope.sorter === sorter ? !$scope.reverse : false;
			$scope.sorter = sorter;
		};

		$scope.refreshStats = function () {
			$scope.loading = true;
			StatsSvc.getSessionStats().then(function (response) {
				$scope.sessionsStats = response.data;

				StatsSvc.getTemplateStats().then(function (response) {
					var templates = {};
					var years = {};
					response.data.forEach(function (type) {
						const stat = type.badge;
						if (!templates[stat]) {
							templates[stat] = 0;
						}
						templates[stat] += type.templates;
						if (!years[type.year]) {
							years[type.year] = {};
							years[type.year].months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
						}
						years[type.year].months[type.month - 1] += type.templates;
					});
					templates = Object.entries(templates)
						.sort((template1, template2) => template1[1] - template2[1])
						.reduce(
							(templates, template) => {
								if (template[1] < 50) {
									templates[0][1] += template[1];
								} else {
									templates.push(template);
								}
								return templates;
							},
							[['Other', 0]]
						);
					years = Object.entries(years);
					$scope.templateSizeChartData = {
						datasets: [
							{
								data: templates.map(function (template) {
									return template[1];
								}),
								backgroundColor: templates.map(function (template, index) {
									return (
										'hsl(' +
										(index / (templates.length - 1)) * 240 +
										', 100%, 50%)'
									);
								}),
							},
						],
						labels: templates.map(function (template) {
							return template[0];
						}),
					};
					$scope.templateCreationChartData = {
						labels: MONTHS,
						fill: false,
						datasets: years.map(function (year, index) {
							var color =
								'hsl(' +
								(index / (years.length - 1)) * 240 +
								', 100%, 50%, 0.6)';
							return {
								label: year[0],
								backgroundColor: color,
								borderColor: color,
								data: year[1].months,
							};
						}),
					};
					StatsSvc.getStats().then(function (response) {
						$scope.stats = response.data;
						$scope.loading = false;
					});
				});
			});
		};

		$scope.refreshStats();
	});
