angular.module('app').directive('ngTemplateLoad', function ($http, $rootScope) {
	return {
		restrict: 'EA',
		scope: {
			template: '=ngTemplateLoad',
		},
		link: function (scope, element, attrs) {
			element.bind('load', function () {
				setTimeout(function () {
					scope.$apply();
				}, 500);
			});

			element.bind('error', function (err) {
				loadTemplate();
			});

			function revokeObjectURL() {
				if (scope.template && scope.template.blobURL) {
					URL.revokeObjectURL(scope.template.blobURL);
				}
			}

			function loadTemplate() {
				if (!scope.template) {
					return;
				}
				let url = '';
				if (!scope.template.blobURL)
					url = `companies/${scope.template.company.number}/templates/${scope.template._id}.png`;
				if (url && url.indexOf('data:') === 0) {
					scope.template.blobURL = url;
				} else if (url) {
					$http.get(url).then(function (response) {
						scope.template.blobURL = response.data;
					}, console.error);
				}
			}

			scope.$watch('template.blobURL', function (blobURL) {
				element.attr('src', blobURL);
			});

			$rootScope.$on('templateUpdated', function (event, _id) {
				loadTemplate();
			});

			scope.$on('$destroy', function () {
				revokeObjectURL();
			});

			scope.$watch('ngTemplateLoad', function () {
				loadTemplate();
			});
		},
	};
});
