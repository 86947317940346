/**
 * Angular Admin - Company Images Tab Controller
 * @file ng/controllers/admin/admin.company.images.ctrl.js
 * @class angular.controllers.AdminCompanyImagesCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'AdminCompanyImagesCtrl',
		function ($scope, $stateParams, CompanySvc, FileSvc) {
			$scope.getFiles = function () {
				FileSvc.getCompanyImages($stateParams.company, '').then(function (
					response
				) {
					$scope.files = response.data;
					$scope.loading = false;
				});
			};

			$scope.getFiles();

			$scope.uploadFile = function () {
				$scope.loading = true;
				FileSvc.uploadFileToCompany($scope.newFile, $scope.company.number).then(
					function (response) {
						//TODO: refresh Files after upload
						$scope.sample = null;
						$scope.newFile = null;
						$scope.getFiles();
						// $state.reload();
					}
				);
			};

			$scope.deleteFile = function (filename) {
				var question = {
					title: $scope.getCopy('delete') + ' - ' + filename.name,
					question:
						$scope.getCopy('deleteFile') +
						' ' +
						$scope.getCopy('messageActionIrreversible'),
					model: 'file',
					api: 'company',
					action: 'delete',
					data: { company: $stateParams.company, filename: filename.name },
					exec: function () {
						FileSvc.deleteFileFromCompany(question.data).then(function (
							response
						) {
							$scope.$emit('questionExecuted', response, question);
							$scope.getFiles();
						});
					},
				};
				$scope.askQueryQuestion(question);
			};

			//Get the source of the image (dropdown / uploaded image / static image)
			$scope.getImageSrc = function (file) {
				return `${file.dir}/${file._id}`;
			};

			$scope.loadAmount = 10;
			$scope.loadMore = () => {
				$scope.loadAmount += 4;
			};
		}
	);
