angular.module('app')
.directive('newScope', function($parse){
  return {
    scope:true,
    compile: function (tElm,tAttrs){
      var init = false;
      var attr = tAttrs.newScope;
      var fn = $parse(attr);

      var linkFunction = function (scope, elm, attrs) {

        if (!init) {
          scope.$watch(attr, function (newValue, oldValue) {
            linkFunction(scope, elm, attrs);
          });
        }

        var property = fn(scope.$parent);
        property = angular.isObject(property) ? property : {};
        angular.extend(scope, property);

        init = true;

      };

      return linkFunction;
    }
  };
});
