angular.module('app').service('CategorySvc', function () {
  var svc = this;

  svc.getSubcategories = (company, template) =>
    template.categories
      ? template.categories.reduce((subcategories, category) => {
          const companyCategory = _.find(
            company.categories.filter((category) => category._id),
            (companyCategory) => companyCategory._id === category._id
          );
          return subcategories.concat(
            companyCategory
              ? companyCategory.subcategories.map((subcategory) => ({
                  ...subcategory,
                  category: {
                    name: companyCategory.name,
                    _id: companyCategory._id,
                  },
                }))
              : []
          );
        }, [])
      : [];

  svc.setCategories = (company, templates) => {
    let result = svc.categories = _.uniq(
      templates.reduce((result, template) => {
        if ((!template.categories || template.categories.length === 0)) {
          if (template.type !== 'user') {
            return result.concat([
              {
                _id: 'other',
                name: 'other',
                translation: 'global',
              },
            ]);
          } else {
            return result.concat([
              {
                _id: 'myTemplates',
                name: 'myTemplates',
                translation: 'global',
              },
            ]);
          }
        } else {
          return result.concat(
            template.categories.map((category) => {
              if (template.type === 'user') {
                return {
                  _id: 'myTemplates',
                  name: 'myTemplates',
                  translation: 'global',
                };
              } else {
                const companyCategory = _.find(
                  company.categories,
                  (companyCategory) => companyCategory._id === category._id
                );
                return {
                  ...companyCategory,
                  translation: 'copy',
                };
              }
            })
          );
        }
      }, []),
      (category) => category.name
    );
    return result;
  };

  svc.getCategoryNames = function (company, template) {
    if (!template.categories) return 'None';
    const categories = template.categories
      .filter((category) => category._id)
      .map((category) => {
        const foundCategory = _.find(
          company.categories,
          (companyCategory) => companyCategory._id === category._id
        );
        return foundCategory ? foundCategory.name : '';
      })
      .filter((category) => category);
    return categories.length > 0 ? categories.join(', ') : 'None';
  };

  svc.getSubcategoryNames = function (template, subcategories) {
    if (!subcategories) return 'None';
    return subcategories
      .filter((subcategory) => svc.isSubcategorySelected(template, subcategory))
      .map((subcategory) => subcategory.name)
      .join(', ');
  };

  svc.isCategorySelected = (template, category) =>
    template &&
    _.some(template.categories, (templateCategory) => templateCategory._id === category._id);

  svc.isSubcategorySelected = (template, subcategory) => {
    if (!subcategory.category) return false;
    const category = _.find(
      template.categories,
      (templateCategory) => templateCategory._id === subcategory.category._id
    );
    return (
      category &&
      _.some(
        category.subcategories,
        (templateSubcategory) => templateSubcategory === subcategory._id
      )
    );
  };

  svc.toggleCategory = (template, category) => {
    if (!template.categories) {
      template.categories = [
        {
          _id: category._id,
          subcategories: [],
        },
      ];
    } else {
      const index = _.findIndex(
        template.categories,
        (templateCategory) => templateCategory._id === category._id
      );
      if (index >= 0) {
        template.categories.splice(index, 1);
      } else {
        template.categories.push({
          _id: category._id,
          subcategories: [],
        });
      }
    }
  };

  svc.toggleSubcategory = (template, subcategory) => {
    const category = _.find(
      template.categories,
      (templateCategory) => templateCategory._id === subcategory.category._id
    );
    const index = _.findIndex(
      category.subcategories,
      (templateSubcategory) => templateSubcategory === subcategory._id
    );
    if (index >= 0) {
      category.subcategories.splice(index, 1);
    } else {
      category.subcategories.push(subcategory._id);
    }
  };

  svc.deleteCategory = (company, category) =>
    $http.delete(`/api/categories/${company}/category/${category}`);

  svc.deleteSubcategory = (company, subcategory) =>
    $http.delete(`/api/categories/${company}/subcategory/${subcategory}`);
});
