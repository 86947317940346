angular.module('app').service('ConstantsSvc', function ($http, TemplateSvc) {
	var svc = this;

	svc.getGoogleFonts = function () {
		$http.get('/api/assets/fonts/').then(function (response) {
			response.data.items.forEach(function (googleFont) {
				TemplateSvc.loadGoogleFont(googleFont.family);
			});
		});
	};

	svc.getTextures = function () {
		return $http.get('/api/assets/textures/');
	};

	svc.getBackgrounds = function () {
		return $http.get('/api/assets/backgrounds/');
	};
	/*
		//TODO: Needs work on the server side to get the altFonts in the right format
		svc.getAltFonts = function () {
		  $http.get('/api/assets/fonts/alt').then(function(response) {
		    console.log(response.data);
		  });
		};
		*/

	//svc.getGoogleFonts();
	//svc.getAltFonts();

	svc.altFonts = [
		{ name: 'NotoSansArabic', value: 'Arabic', type: 'ttf' },
		{ name: 'NotoSansArmenian', value: 'Armenian', type: 'ttf' },
		{ name: 'NotoSansBengali', value: 'Bengali', type: 'ttf' },
		{ name: 'Padauk', value: 'Burmese', type: 'ttf' },
		{ name: 'NotoSansCJK', value: 'Chinese (simplified)', type: 'ttf' },
		{ name: 'NotoSansCJK', value: 'Chinese (traditional)', type: 'ttf' },
		{ name: 'NotoSans', value: 'Cyrillic', type: 'ttf' },
		{ name: 'NotoSansDevanagari', value: 'Devanagari', type: 'ttf' },
		{ name: 'bjcrus', value: 'East Cree', type: 'ttf' },
		{ name: 'NotoSansEthiopic', value: 'Ethiopic', type: 'ttf' },
		{ name: 'NotoSansGeorgian', value: 'Georgian', type: 'ttf' },
		{ name: 'NotoSansGujarati', value: 'Gujarati', type: 'ttf' },
		{ name: 'NotoSansHebrew', value: 'Hebrew', type: 'ttf' },
		{ name: 'Gadugi', value: 'Indigenous', type: 'ttf' },
		{ name: 'NotoSansCJK', value: 'Japanese', type: 'ttf' },
		{ name: 'NotoSansJavanese', value: 'Javanese', type: 'ttf' },
		{ name: 'NotoSansKannada', value: 'Kannada', type: 'ttf' },
		{ name: 'NotoSansKhmer', value: 'Khmer', type: 'ttf' },
		{ name: 'NotoSansCJK', value: 'Korean', type: 'ttf' },
		{ name: 'NotoNaskhArabic', value: 'Kurdish', type: 'ttf' },
		{ name: 'NotoSansGujarati', value: 'Kutchi', type: 'ttf' },
		{ name: 'NotoSansLao', value: 'Lao', type: 'ttf' },
		{ name: 'Rachana', value: 'Malayalam', type: 'ttf' },
		{ name: 'bjcrus', value: 'Nehiyawewin (Plains Cree)', type: 'ttf' },
		{ name: 'NotoSansDevanagari', value: 'Nepali', type: 'ttf' },
		{ name: 'NotoNastaliqUrdu', value: 'Pashto', type: 'ttf' },
		{ name: 'NotoSansPunjabi', value: 'Punjabi', type: 'ttf' },
		{ name: 'NotoNaskhArabic', value: 'Sindhi', type: 'ttf' },
		{ name: 'NotoSansSinhala', value: 'Sinhala', type: 'ttf' },
		{ name: 'NotoSansSundanese', value: 'Sundanese', type: 'ttf' },
		{ name: 'NotoSansTamil', value: 'Tamil', type: 'ttf' },
		{ name: 'NotoSansTelugu', value: 'Telugu', type: 'ttf' },
		{ name: 'NotoSansThai', value: 'Thai', type: 'ttf' },
		{ name: 'NotoSerifTibetan', value: 'Tibetan', type: 'ttf' },
		{ name: 'NotoNastaliqUrdu', value: 'Urdu', type: 'ttf' },
		{ name: 'NotoSans', value: 'Vietnamese', type: 'ttf' },
		{ name: 'NotoSansHebrew', value: 'Yiddish', type: 'ttf' },
	];

	svc.curatedDropdowns = {
		languages: [
			{
				value: ' ',
				name: 'Blank',
			},
			{
				value: 'Unë flas shqip',
				name: 'Albanian',
			},
			{
				value: 'American Sign Language',
				name: 'American Sign Language',
			},
			{
				altFontName: 'NotoSansEthiopic',
				altFontType: 'ttf',
				value: 'እኔ እንግሊዝኛ እናገራለሁ',
				name: 'Amharic',
			},
			{
				altFontName: 'NotoSansArabic',
				altFontType: 'ttf',
				value: 'أنا أتحدث اللغة العربية',
				name: 'Arabic',
			},
			{
				altFontName: 'NotoSansArmenian',
				altFontType: 'ttf',
				value: 'Ես խոսում եմ հայերեն',
				name: 'Armenian',
			},
			{
				value: 'Me ka Ashanti',
				name: 'Ashanti',
			},
			{
				value: 'Ni nehirowimon',
				name: 'Atikamekw',
			},
			{
				altFontName: 'NotoSansBengali',
				altFontType: 'ttf',
				value: 'আমি বাংলা বলতে',
				name: 'Bengali',
			},
			{
				value: 'Niitaitsi’poyi',
				name: 'Blackfoot',
			},
			{
				value: 'Аз говоря български',
				name: 'Bulgarian',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '我會說廣東話',
				name: 'Cantonese',
			},
			{
				value: 'Mwen pale kreyòl',
				name: 'Creole',
			},
			{
				value: 'Govorim Hrvatski',
				name: 'Croatian',
			},
			{
				value: 'Mluvím česky',
				name: 'Czech',
			},
			{
				value: 'Dënësułınë áh yastı',
				name: 'Denesuline',
			},
			{
				value: 'Ik spreek Nederlands',
				name: 'Dutch',
			},
			{
				value: 'I Speak English',
				name: 'English',
			},
			{
				value: 'Ma räägin eesti keelt',
				name: 'Estonian',
			},
			{
				value: 'Afaan Oromoon dubbadha',
				name: 'Ethiopian - Oromo',
			},
			{
				value: 'Nagsasalita ako ng Filipino',
				name: 'Filipino',
			},
			{
				value: 'Minä puhun suomea',
				name: 'Finnish',
			},
			{
				value: 'Je parle français',
				name: 'French',
			},
			{
				value: 'Miwieɔ GA',
				name: 'Ga',
			},
			{
				value: 'Ich spreche Deutsch',
				name: 'German',
			},
			{
				value: 'Miwieɔ Ghana',
				name: 'Ghanaian - Ga',
			},
			{
				value: 'Meka Ghana',
				name: 'Ghanaian - Twi',
			},
			{
				value: 'Μιλάω ελληνικά',
				name: 'Greek',
			},
			{
				altFontName: 'NotoSansGujarati',
				altFontType: 'ttf',
				value: 'હું ગુજરાતી વાત',
				name: 'Gujarati',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansHebrew',
				value: 'אני מדברת עברית',
				name: 'Hebrew (Female)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansHebrew',
				value: 'אני מדבר עברית',
				name: 'Hebrew (Male)',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'मैं हिंदी बोलती हूँ',
				name: 'Hindi (Female)',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'मैं हिंदी बोलता हूँ',
				name: 'Hindi (Male)',
			},
			{
				value: 'Kuv hais lus Hmoob',
				name: 'Hmong',
			},
			{
				value: 'Beszélek magyarul',
				name: 'Hungarian',
			},
			{
				value: 'M asụ Igbo',
				name: 'IGBO',
			},
			{
				value: 'Saya Berbicara Bahasa Indonesia',
				name: 'Indonesian',
			},
			{
				value: 'Nunnu-aimin Nileluan',
				name: 'Innu-Aimun (Montagnais)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ᐃᓄᒃᑎᑑᓲᖑᔪᖓ',
				name: 'Inuktitut',
			},
			{
				value: 'Parlo Italiano',
				name: 'Italian',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '私は日本語を話します。',
				name: 'Japanese',
			},
			{
				value: 'Aku nggunem basa Jawa',
				name: 'Javanese',
			},
			{
				altFontName: 'NotoSansKhmer',
				altFontType: 'ttf',
				value: 'ខ្ញុំនិយាយភាសាខ្មែរ',
				name: 'Khmer',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '나는 한국말을 합니다',
				name: 'Korean',
			},
			{
				value: 'Na krio I dɛ tɔk',
				name: 'Krio',
			},
			{
				altFontName: 'NotoSansLao',
				altFontType: 'ttf',
				value: 'ຂ້ອຍເວົ້າພາສາລາວ',
				name: 'Lao',
			},
			{
				value: 'Es runāju latviski',
				name: 'Latvian',
			},
			{
				value: 'Kalbu lietuviškai',
				name: 'Lithuanian',
			},
			{
				value: 'Јас зборувам Македонски јазик',
				name: 'Macedonian',
			},
			{
				value: 'Saya bercakap Bahasa Melayu',
				name: 'Malay',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '我说普通话',
				name: 'Mandarin',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '我會說国語和粤語',
				name: 'Mandarin + Cantonese',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'मी मराठी बोलतो',
				name: 'Marathi',
			},
			{
				value: 'L’nui’si',
				name: 'Mi’kmaq',
			},
			{
				value: 'Aen Michif nipeekishkwawn',
				name: 'Michif',
			},
			{
				value: 'Jeg snakker Norsk',
				name: 'Norwegian',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ᓂᓂᐦᑕ ᐊᓂᔑᓂᓂᒼ',
				name: 'Oji-Cree',
			},
			{
				value: 'Ojibwe ndo izhigiizhiwe',
				name: 'Ojibwe (Eastern)',
			},
			{
				value: 'Nindanishinaabem',
				name: 'Ojibwe (Western)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoNastaliqUrdu',
				value: 'زه پښتو خبرې کوم',
				name: 'Pashto',
			},
			{
				value: 'من فارسی صحبت می کنم',
				name: 'Persian',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ni-nēhiyawān',
				name: 'Plains Cree',
			},
			{
				value: 'Mówię po polsku',
				name: 'Polish',
			},
			{
				value: 'Eu falo Português',
				name: 'Portuguese',
			},
			{
				altFontName: 'NotoSansPunjabi',
				altFontType: 'ttf',
				value: 'ਮੈਂ ਪੰਜਾਬੀ ਬੋਲਦੀ ਹਾਂ',
				name: 'Punjabi (Female)',
			},
			{
				altFontName: 'NotoSansPunjabi',
				altFontType: 'ttf',
				value: 'ਮੈਂ ਪੰਜਾਬੀ ਬੋਲਦਾ ਹਾਂ',
				name: 'Punjabi (Male)',
			},
			{
				value: 'Vorbesc limba română',
				name: 'Romanian',
			},
			{
				value: 'Я говорю по-русски',
				name: 'Russian',
			},
			{
				value: 'Govorim srpski',
				name: 'Serbian',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansSinhala',
				value: 'මම සිංහලෙන් කතා කරනවා',
				name: 'Sinhala',
			},
			{
				value: 'Hovorím po slovensky',
				name: 'Slovak',
			},
			{
				value: 'Waxaan ku hadlaa Soomaali',
				name: 'Somali',
			},
			{
				value: 'Hablo Español',
				name: 'Spanish',
			},
			{
				value: 'Abdi nyarios basa Sunda',
				name: 'Sudanese',
			},
			{
				value: 'Nasema Swahili',
				name: 'Swahili',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ᓂᐟ ᐃᓂᓂᒧᐣ',
				name: 'Swampy Cree',
			},
			{
				value: 'Jag talar Svenska',
				name: 'Swedish',
			},
			{
				value: 'Marunong akong mag-tagalog',
				name: 'Tagalog',
			},
			{
				altFontName: 'NotoSansTamil',
				altFontType: 'ttf',
				value: 'நான் பேசுகிபேன் தமிழ',
				name: 'Tamil',
			},
			{
				altFontName: 'NotoSansThai',
				altFontType: 'ttf',
				value: 'ฉันพูดภาษาไทย',
				name: 'Thai (Female)',
			},
			{
				altFontName: 'NotoSansThai',
				altFontType: 'ttf',
				value: 'ผมพูดภาษาไทย',
				name: 'Thai (Male)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansEthiopic',
				value: 'ትግርኛ ይዛረብ',
				name: 'Tigrinya',
			},
			{
				value: 'Türkçe Konuşuyorum',
				name: 'Turkish',
			},
			{
				value: 'Meka Twi',
				name: 'Twi',
			},
			{
				value: 'я розмовляю українською мовою',
				name: 'Ukrainian',
			},
			{
				altFontName: 'NotoNastaliqUrdu',
				altFontType: 'ttf',
				value: 'میں اردو بولتی ہوں',
				name: 'Urdu (Female)',
			},
			{
				altFontName: 'NotoNastaliqUrdu',
				altFontType: 'ttf',
				value: 'میں اردو بولتا ہوں',
				name: 'Urdu (Male)',
			},
			{
				value: 'Tôi nói tiếng việt',
				name: 'Vietnamese',
			},
		],
		languagesFrench: [
			{
				value: ' ',
				name: 'Blank',
			},
			{
				value: 'Unë flas shqip',
				name: 'Albanais',
			},
			{
				value: 'American Sign Language',
				name: 'Langue des signes américaine',
			},
			{
				altFontName: 'NotoSansEthiopic',
				altFontType: 'ttf',
				value: 'እኔ እንግሊዝኛ እናገራለሁ',
				name: 'Amharique',
			},
			{
				altFontName: 'NotoSansArabic',
				altFontType: 'ttf',
				value: 'أنا أتحدث اللغة العربية',
				name: 'Arabe',
			},
			{
				altFontName: 'NotoSansArmenian',
				altFontType: 'ttf',
				value: 'Ես խոսում եմ հայերեն',
				name: 'Arménien',
			},
			{
				value: 'Me ka Ashanti',
				name: 'Ashanti',
			},
			{
				value: 'Ni nehirowimon',
				name: 'Atikamekw',
			},
			{
				altFontName: 'NotoSansBengali',
				altFontType: 'ttf',
				value: 'আমি বাংলা বলতে',
				name: 'Bengali',
			},
			{
				value: 'Niitaitsi’poyi',
				name: 'Pieds noirs',
			},
			{
				value: 'Аз говоря български',
				name: 'Bulgare',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '我會說廣東話',
				name: 'Cantonais',
			},
			{
				value: 'Mwen pale kreyòl',
				name: 'Créole',
			},
			{
				value: 'Govorim Hrvatski',
				name: 'Croate',
			},
			{
				value: 'Mluvím česky',
				name: 'Tchèque',
			},
			{
				value: 'Dënësułınë áh yastı',
				name: 'Denesuline',
			},
			{
				value: 'Ik spreek Nederlands',
				name: 'Néerlandais',
			},
			{
				value: 'I Speak English',
				name: 'Anglais',
			},
			{
				value: 'Ma räägin eesti keelt',
				name: 'Estonien',
			},
			{
				value: 'Afaan Oromoon dubbadha',
				name: 'Ethiopien - Oromo',
			},
			{
				value: 'Nagsasalita ako ng Filipino',
				name: 'Philippin',
			},
			{
				value: 'Minä puhun suomea',
				name: 'Finlandais',
			},
			{
				value: 'Je parle français',
				name: 'Français',
			},
			{
				value: 'Miwieɔ GA',
				name: 'Ga',
			},
			{
				value: 'Ich spreche Deutsch',
				name: 'Allemand',
			},
			{
				value: 'Miwieɔ Ghana',
				name: 'Ghanéen - Ga',
			},
			{
				value: 'Meka Ghana',
				name: 'Ghanéen - Twi',
			},
			{
				value: 'Μιλάω ελληνικά',
				name: 'Grec',
			},
			{
				altFontName: 'NotoSansGujarati',
				altFontType: 'ttf',
				value: 'હું ગુજરાતી વાત',
				name: 'Gujarati',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansHebrew',
				value: 'אני מדברת עברית',
				name: 'Hébreu (Femme)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansHebrew',
				value: 'אני מדבר עברית',
				name: 'Hébreu (Homme)',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'मैं हिंदी बोलती हूँ',
				name: 'Hindi (Femme)',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'मैं हिंदी बोलता हूँ',
				name: 'Hindi (Homme)',
			},
			{
				value: 'Kuv hais lus Hmoob',
				name: 'Hmong',
			},
			{
				value: 'Beszélek magyarul',
				name: 'Hongrois',
			},
			{
				value: 'M asụ Igbo',
				name: 'IGBO',
			},
			{
				value: 'Saya Berbicara Bahasa Indonesia',
				name: 'Indonésien',
			},
			{
				value: 'Nunnu-aimin Nileluan',
				name: 'Innu-Aimun (Montagnais)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ᐃᓄᒃᑎᑑᓲᖑᔪᖓ',
				name: 'Inuktitut',
			},
			{
				value: 'Parlo Italiano',
				name: 'Italien',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '私は日本語を話します。',
				name: 'Japonais',
			},
			{
				value: 'Aku nggunem basa Jawa',
				name: 'Javanais',
			},
			{
				altFontName: 'NotoSansKhmer',
				altFontType: 'ttf',
				value: 'ខ្ញុំនិយាយភាសាខ្មែរ',
				name: 'Khmer',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '나는 한국말을 합니다',
				name: 'Coréen',
			},
			{
				value: 'Na krio I dɛ tɔk',
				name: 'Krio',
			},
			{
				altFontName: 'NotoSansLao',
				altFontType: 'ttf',
				value: 'ຂ້ອຍເວົ້າພາສາລາວ',
				name: 'Lao',
			},
			{
				value: 'Es runāju latviski',
				name: 'Letton',
			},
			{
				value: 'Kalbu lietuviškai',
				name: 'Lituanien',
			},
			{
				value: 'Јас зборувам Македонски јазик',
				name: 'Macédonien',
			},
			{
				value: 'Saya bercakap Bahasa Melayu',
				name: 'Malais',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '我说普通话',
				name: 'Mandarin',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '我會說国語和粤語',
				name: 'Mandarin + Cantonais',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'मी मराठी बोलतो',
				name: 'Marathi',
			},
			{
				value: 'L’nui’si',
				name: 'Mi’kmaq',
			},
			{
				value: 'Aen Michif nipeekishkwawn',
				name: 'Michif',
			},
			{
				value: 'Jeg snakker Norsk',
				name: 'Norvégien',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ᓂᓂᐦᑕ ᐊᓂᔑᓂᓂᒼ',
				name: 'Oji-Cree',
			},
			{
				value: 'Ojibwe ndo izhigiizhiwe',
				name: 'Ojibwe (Est)',
			},
			{
				value: 'Nindanishinaabem',
				name: 'Ojibwé (Ouest)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoNastaliqUrdu',
				value: 'زه پښتو خبرې کوم',
				name: 'Pashto',
			},
			{
				value: 'من فارسی صحبت می کنم',
				name: 'Persan',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ni-nēhiyawān',
				name: 'Cri des Plaines',
			},
			{
				value: 'Mówię po polsku',
				name: 'Polonais',
			},
			{
				value: 'Eu falo Português',
				name: 'Portugais',
			},
			{
				altFontName: 'NotoSansPunjabi',
				altFontType: 'ttf',
				value: 'ਮੈਂ ਪੰਜਾਬੀ ਬੋਲਦੀ ਹਾਂ',
				name: 'Punjabi (Femme)',
			},
			{
				altFontName: 'NotoSansPunjabi',
				altFontType: 'ttf',
				value: 'ਮੈਂ ਪੰਜਾਬੀ ਬੋਲਦਾ ਹਾਂ',
				name: 'Punjabi (Homme)',
			},
			{
				value: 'Vorbesc limba română',
				name: 'Roumain',
			},
			{
				value: 'Я говорю по-русски',
				name: 'Russe',
			},
			{
				value: 'Govorim srpski',
				name: 'Serbe',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansSinhala',
				value: 'මම සිංහලෙන් කතා කරනවා',
				name: 'Sinhala',
			},
			{
				value: 'Hovorím po slovensky',
				name: 'Slovaque',
			},
			{
				value: 'Waxaan ku hadlaa Soomaali',
				name: 'Somali',
			},
			{
				value: 'Hablo Español',
				name: 'Espagnol',
			},
			{
				value: 'Abdi nyarios basa Sunda',
				name: 'Soudanais',
			},
			{
				value: 'Nasema Swahili',
				name: 'Swahili',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ᓂᐟ ᐃᓂᓂᒧᐣ',
				name: 'Cri des marais',
			},
			{
				value: 'Jag talar Svenska',
				name: 'Suédois',
			},
			{
				value: 'Marunong akong mag-tagalog',
				name: 'Tagalog',
			},
			{
				altFontName: 'NotoSansTamil',
				altFontType: 'ttf',
				value: 'நான் பேசுகிபேன் தமிழ',
				name: 'Tamil',
			},
			{
				altFontName: 'NotoSansThai',
				altFontType: 'ttf',
				value: 'ฉันพูดภาษาไทย',
				name: 'Thai (Femme)',
			},
			{
				altFontName: 'NotoSansThai',
				altFontType: 'ttf',
				value: 'ผมพูดภาษาไทย',
				name: 'Thai (Homme)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansEthiopic',
				value: 'ትግርኛ ይዛረብ',
				name: 'Tigrinya',
			},
			{
				value: 'Türkçe Konuşuyorum',
				name: 'Turc',
			},
			{
				value: 'Meka Twi',
				name: 'Twi',
			},
			{
				value: 'я розмовляю українською мовою',
				name: 'Ukrainien',
			},
			{
				altFontName: 'NotoNastaliqUrdu',
				altFontType: 'ttf',
				value: 'میں اردو بولتی ہوں',
				name: 'Urdu (Femme)',
			},
			{
				altFontName: 'NotoNastaliqUrdu',
				altFontType: 'ttf',
				value: 'میں اردو بولتا ہوں',
				name: 'Urdu (Homme)',
			},
			{
				value: 'Tôi nói tiếng việt',
				name: 'Vietnamien',
			},
		],
		hello: [
			{
				value: 'Hello',
				name: 'English',
			},
			{
				value: 'Bonjour',
				name: 'French',
			},
			{
				value: 'Hallo',
				name: 'Afrikaans',
			},
			{
				value: 'Mema wo akye',
				name: 'Akan (Twi)',
			},
			{
				value: 'Përshëndetje',
				name: 'Albanian',
			},
			{
				altFontName: 'NotoSansEthiopic',
				altFontType: 'ttf',
				value: 'ሰላም',
				name: 'Amharic',
			},
			{
				altFontName: 'NotoSansArabic',
				altFontType: 'ttf',
				value: 'مرحبًا',
				name: 'Arabic',
			},
			{
				altFontName: 'NotoSansArmenian',
				altFontType: 'ttf',
				value: 'Ողջույն',
				name: 'Armenian',
			},
			{
				value: 'Shlama amkhon',
				name: 'Assyrian Neo-Aramaic',
			},
			{
				value: 'Kwei',
				name: 'Atikamekw',
			},
			{
				value: 'Salam əleyküm',
				name: 'Azerbaijani',
			},
			{
				value: 'Прывітанне',
				name: 'Belarusian',
			},
			{
				altFontName: 'NotoSansBengali',
				altFontType: 'ttf',
				value: 'হ্যালো',
				name: 'Bengali',
			},
			{
				value: 'Hur om brahon',
				name: 'Bilen',
			},
			{
				value: 'Zdravo',
				name: 'Bosnian',
			},
			{
				value: 'Здравейте!',
				name: 'Bulgarian',
			},
			{
				altFontName: 'Padauk',
				altFontType: 'ttf',
				value: 'မင်္ဂလာပါ',
				name: 'Burmese',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '您好',
				name: 'Cantonese',
			},
			{
				value: 'Kumusta',
				name: 'Cebuano',
			},
			{
				value: 'Shlama illokhun',
				name: 'Chaldean Neo-Aramaic',
			},
			{
				value: 'Bok',
				name: 'Croatian',
			},
			{
				value: 'Ahoj',
				name: 'Czech',
			},
			{
				altFontName: "NotoSansArabic",
				altFontType: "ttf",
				value: 'سلام',
				name: 'Dari',
			},
			{
				value: 'Hallo',
				name: 'Dutch',
			},
			{
				value: 'Hei',
				name: 'Finnish',
			},
			{
				altFontName: 'NotoSansGeorgian',
				altFontType: 'ttf',
				value: 'გამარჯობა',
				name: 'Georgian',
			},
			{
				value: 'Hallo',
				name: 'German',
			},
			{
				value: 'Γεια σας',
				name: 'Greek',
			},
			{
				altFontName: 'NotoSansGujarati',
				altFontType: 'ttf',
				value: 'નમસ્તે',
				name: 'Gujarati',
			},
			{
				value: 'Nou salye w',
				name: 'Haitian Creole',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '你好',
				name: 'Hakka',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansHebrew',
				value: 'שלום',
				name: 'Hebrew',
			},
			{
				value: 'Kamusta',
				name: 'Hiligaynon',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'नमस्ते',
				name: 'Hindi',
			},
			{
				value: 'Szia',
				name: 'Hungarian',
			},
			{
				value: 'Nnọọ',
				name: 'Igbo',
			},
			{
				value: 'Kumusta',
				name: 'Ilocano',
			},
			{
				altFontName: 'NotoSansPunjabi',
				altFontType: 'ttf',
				value: 'ਸਤ ਸ੍ਰੀ ਅਕਾਲ',
				name: 'Indian Punjabi',
			},
			{
				value: 'Halo',
				name: 'Indonesian',
			},
			{
				value: 'Kuei',
				name: 'Innu (Montagnais)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'ᐊᐃᓐᖓᐃ',
				name: 'Inuktitut',
			},
			{
				value: 'Ciao',
				name: 'Italian',
			},
			{
				altFontName: 'bjcrus',
				altFontType: 'ttf',
				value: 'ᐙᒋᔮᐦ',
				name: 'Iyiyiw-Ayimiwin (Northern East Cree)',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: 'こんにちは',
				name: 'Japanese',
			},
			{
				value: 'Azul',
				name: 'Kabyle',
			},
			{
				altFontName: 'NotoSansKannada',
				altFontType: 'ttf',
				value: 'ನಮಸ್ಕಾರ',
				name: 'Kannada',
			},
			{
				altFontName: 'NotoSansKhmer',
				altFontType: 'ttf',
				value: 'ជំរាបសួរ',
				name: 'Khmer (Cambodian)',
			},
			{
				value: 'Muraho',
				name: 'Kinyarwanda (Rwanda)',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '안녕하세요',
				name: 'Korean',
			},
			{
				altFontName: 'NotoNaskhArabic',
				altFontType: 'ttf',
				value: 'سڵاو',
				name: 'Kurdish',
			},
			{
				altFontName: 'NotoSansGujarati',
				altFontType: 'ttf',
				value: 'કીં અયો',
				name: 'Kutchi',
			},
			{
				altFontName: 'NotoSansLao',
				altFontType: 'ttf',
				value: 'ສະບາຍດີ',
				name: 'Lao',
			},
			{
				value: 'Mbóte',
				name: 'Lingala',
			},
			{
				value: 'Sveikì',
				name: 'Lithuanian',
			},
			{
				value: 'Здраво',
				name: 'Macedonian',
			},
			{
				value: 'Helo',
				name: 'Malay',
			},
			{
				altFontName: 'Rachana',
				altFontType: 'ttf',
				value: 'ഹലോ',
				name: 'Malayalam',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '您好',
				name: 'Mandarin',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'नमस्कार',
				name: 'Marathi',
			},
			{
				value: `Kwe'`,
				name: `Mi'kmaq`,
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '汝好',
				name: 'Min Nan (Chaochow, Teochow, Fukien, Taiwanese)',
			},
			{
				value: 'Сайн байна уу',
				name: 'Mongolian',
			},
			{
				value: 'Bonzur',
				name: 'Morisyen',
			},
			{
				altFontName: 'bjcrus',
				altFontType: 'ttf',
				value: 'ᑖᓂᓯ',
				name: 'Nehiyawewin (Plains Cree)',
			},
			{
				altFontName: 'NotoSansDevanagari',
				altFontType: 'ttf',
				value: 'नमस्कार',
				name: 'Nepali',
			},
			{
				altFontType: 'ttf',
				altFontName: 'Gadugi',
				value: 'Waahjiye',
				name: 'Oji-Cree',
			},
			{
				value: 'Akkam',
				name: 'Oromo',
			},
			{
				value: 'Kumustá',
				name: 'Pampangan (Kapampangan, Pampango)',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoNastaliqUrdu',
				value: 'سلام',
				name: 'Pashto',
			},
			{
				value: 'Hallo',
				name: 'Pennsylvania German',
			},
			{
				value: 'سلام',
				name: 'Persian (Farsi)',
			},
			{
				value: 'Goondach',
				name: 'Plautdietsch',
			},
			{
				value: 'Witaj',
				name: 'Polish',
			},
			{
				value: 'Olá',
				name: 'Portuguese',
			},
			{
				value: 'Bună ziua',
				name: 'Romanian',
			},
			{
				value: 'Bwakeye',
				name: 'Rundi (Kirundi)',
			},
			{
				value: 'Здравствуйте',
				name: 'Russian',
			},
			{
				value: 'Zdravo',
				name: 'Serbian',
			},
			{
				value: 'Zdravo',
				name: 'Serbo-Croatian',
			},
			{
				value: 'Kaw leh ah gay',
				name: `S'gaw Karen`,
			},
			{
				value: 'Mhoro',
				name: 'Shona',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoNaskhArabic',
				value: 'سَلامُ',
				name: 'Sindhi',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansSinhala',
				value: 'ආයුබෝවන්',
				name: 'Sinhala (Sinhalese)',
			},
			{
				value: 'Ahoj',
				name: 'Slovak',
			},
			{
				value: 'Dober dan',
				name: 'Slovene (Slovenian)',
			},
			{
				value: 'Hola',
				name: 'Spanish',
			},
			{
				value: 'Salaan',
				name: 'Somali',
			},
			{
				value: 'Habari',
				name: 'Swahili',
			},
			{
				value: 'Grüezi',
				name: 'Swiss German',
			},
			{
				value: 'Kumusta',
				name: 'Tagalog',
			},
			{
				altFontName: 'NotoSansTamil',
				altFontType: 'ttf',
				value: 'வணக்கம்',
				name: 'Tamil',
			},
			{
				altFontName: 'NotoSansTelugu',
				altFontType: 'ttf',
				value: 'హలో',
				name: 'Telugu',
			},
			{
				altFontName: 'NotoSansThai',
				altFontType: 'ttf',
				value: 'สวัสดี',
				name: 'Thai',
			},
			{
				altFontName: 'NotoSerifTibetan',
				altFontType: 'ttf',
				value: 'བཀྲིས་བདེ་ལེགས།',
				name: 'Tibetan',
			},
			{
				altFontType: 'ttf',
				altFontName: 'NotoSansEthiopic',
				value: 'ሰላም',
				name: 'Tigrinya',
			},
			{
				value: 'Merhaba',
				name: 'Turkish',
			},
			{
				value: 'Привіт',
				name: 'Ukrainian',
			},
			{
				altFontName: 'NotoNastaliqUrdu',
				altFontType: 'ttf',
				value: 'سلام',
				name: 'Urdu',
			},
			{
				value: 'Xin chào',
				name: 'Vietnamese',
			},
			{
				value: 'Salaam aleekum',
				name: 'Wolof',
			},
			{
				altFontName: 'NotoSansCJK',
				altFontType: 'ttf',
				value: '侬好',
				name: 'Wu (Shanghainese)',
			},
			{
				altFontName: 'NotoSansHebrew',
				altFontType: 'ttf',
				value: 'אַ גוטן טאָג ',
				name: 'Yiddish',
			},
			{
				value: 'Pẹlẹ o',
				name: 'Yoruba',
			},
		],

	};

	svc.getAlignmentName = function (option) {
		switch (option) {
			case 0:
				return 'Left';
			case 1:
				return 'Center';
			case 2:
				return 'Right';
		}
	};

	svc.getWeightName = function (option) {
		switch (option) {
			case 0:
				return 'None';
			case 1:
				return 'Bold';
		}
	};

	svc.getStyleName = function (option) {
		switch (option) {
			case 0:
				return 'None';
			case 1:
				return 'Italic';
			case 2:
				return 'Oblique';
		}
	};

	svc.getCaseName = function (option) {
		switch (option) {
			case 0:
				return 'Normal';
			case 1:
				return 'UPPER';
			case 2:
				return 'lower';
		}
	};

	svc.fontTypes = ['woff', 'otf', 'ttf', 'svg', 'woff/otf/ttf/svg', 'google', 'win'];

	svc.backgroundTypes = ['white', 'silver', 'gold', 'copper'];

	svc.getTypeName = function (option) {
		switch (option) {
			case 'ttf':
				return 'ttf - True Type';
			case 'otf':
				return 'otf - Open Type';
			case 'woff':
				return 'woff - Web Open Font Format';
			case 'svg':
				return 'svg - Scalable Vector Graphics';
			case 'woff/otf/ttf/svg':
				return 'w/o/t/s - Font Combo';
			case 'google':
				return 'Google Font';
			case 'win':
				return 'Client Side Font';
		}
	};
});
