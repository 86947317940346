angular.module('app').controller('AdminUsersCtrl', ($scope, AppSvc, UserSvc, CompanySvc, $localStorage) => {
  $scope.$storage = $localStorage;
  $scope.page = 1;
  $scope.limit = $scope.$storage.usersLimit || 10;
  $scope.loading = false;
  $scope.listOfLimit = [10, 20, 50, 100];
  $scope.searchFilter = {};
  $scope.sorter = null;
  $scope.reverse = null;

  $scope.getUsers = () => {
    $scope.loading = true;
    UserSvc.getUsers($scope.page, $scope.limit, $scope.sorter, $scope.reverse, $scope.searchFilter).then(({ data }) => {
      $scope.loading = false;
      $scope.totalItems = data.totalItems;
      $scope.users = data.items;

      $scope.companies = _.uniq(
        $scope.users.map((user) => user.company),
        (company) => company.number
      );
      $scope.usernames = _.uniq(
        $scope.users.map((user) => user.username),
        (username) => username
      );
      $scope.emails = _.uniq(
        $scope.users.map((user) => user.email),
        (email) => email
      );
      $scope.serialNumbers = _.uniq(
        $scope.users.map((user) => user.serialNumber),
        (serialNumber) => serialNumber
      );
    });
  };

  $scope.getUsers();

  $scope.sortBy = function (sorter) {
    $scope.reverse = $scope.sorter === sorter ? !$scope.reverse : false;
    $scope.sorter = sorter;
    $scope.getUsers();
  };

  $scope.deleteUser = function (user) {
    if (user._id == AppSvc.user._id) {
      $scope.$emit('popup', {
        message: $scope.getCopy('messageDeleteOwnUserUnable'),
        type: 'alert-danger',
      });

      var message = {
        title: $scope.getCopy('delete') + ' ' + $scope.getCopy('user'),
        content: $scope.getCopy('messageDeleteOwnUserUnable'),
      };
      $scope.showPopupNotification(message);
    } else {
      var question = {
        title: $scope.getCopy('delete') + ' ' + $scope.getCopy('user') + '?',
        question:
          $scope.getCopy('delete') +
          ' ' +
          user.username +
          '? ' +
          $scope.getCopy('messageActionIrreversible'),
        model: 'user',
        api: 'users',
        action: 'delete',
        data: user,
        exec: function () {
          console.log(`Deleting ${user.username}`);
          UserSvc.deleteUser(user.company.number, user).then(function (response) {
            $scope.getUsers();
            $scope.$emit('questionExecuted', response, question);
          });
        },
      };
      $scope.askQueryQuestion(question);
    }
  };

  $scope.pageChangeHandler = function(newPage) {
    $scope.page = newPage;
    $scope.getUsers();
  };

  $scope.changeLimit = function() {
    $scope.$storage.usersLimit = $scope.limit;
    // $scope.getUsers($scope.page, $scope.limit, $scope.sorter, $scope.reverse);
  };

  $scope.searchCompanyNumbers = (val) => {
    if (val === '') {
      $scope.searchFilter.company_number = null;
      $scope.getUsers();
      return [];
    }
    $scope.searchFilter.company_number = val;
    $scope.getUsers();
    return CompanySvc.searchCompany(null, val).then(({ data }) => {
      return data.map((company) => (company.number));
    });
  };

  $scope.searchCompanyNames = (val) => {
    if (val === '') {
      $scope.searchFilter.company_name = null;
      $scope.getUsers();
      return [];
    }
    $scope.searchFilter.company_name = val;
    $scope.getUsers();
    return CompanySvc.searchCompany(val, null).then(({ data }) => {
      return data.map((company) => (company.name));
    });
  };

  $scope.searchUsernames = (val) => {
    if (val === '') {
      $scope.searchFilter.username = null;
      $scope.getUsers();
      return [];
    }
    $scope.searchFilter.username = val;
    $scope.getUsers();
    return UserSvc.searchUser(val, null, null).then(({ data }) => {
      return data.map((user) => (user.username));
    });
  };

  $scope.searchEmails = (val) => {
    if (val === '') {
      $scope.searchFilter.email = null;
      $scope.getUsers();
      return [];
    }
    $scope.searchFilter.email = val;
    $scope.getUsers();
    return UserSvc.searchUser(null, val, null).then(({ data }) => {
      return data.map((user) => (user.email));
    });
  };

  $scope.searchSerialNumbers = (val) => {
    if (val === '') {
      $scope.searchFilter.serialNumber = null;
      $scope.getUsers();
      return [];
    }
    $scope.searchFilter.serialNumber = val;
    $scope.getUsers();
    return UserSvc.searchUser(null, null, val).then(({ data }) => {
      return data.map((user) => (user.serialNumber));
    });
  };
});
