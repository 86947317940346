angular.module('app').service('CompanySvc', function ($http) {
	var svc = this;

	svc.getCompanies = function () {
		return $http.get('/api/companies');
	};

	svc.getCustomers = function () {
		return $http.get('/api/companies/customers');
	};

	svc.getCompany = function (company) {
		return $http.get('/api/companies/' + company);
	};

	svc.searchCompany = function(name, number) {
		return $http.get('/api/companies/search', {
			params: {
				name: name,
				number: number,
			}
		});
	};

	svc.getLoginType = function (company) {
		return $http.get('/api/companies/' + company + '/logintype');
	};

	svc.createCompany = function (company) {
		return $http.post('/api/companies', company);
	};

	svc.updateCompany = function (company) {
		return $http.put('/api/companies/' + company.number, company);
	};

	svc.checkValidationCode = function (validationCode) {
		return $http.get('/api/validation/vc/' + validationCode);
	};

	svc.checkSerialNumber = function (sn, company) {
		var serialnumber = sn[0] + '-' + sn[1] + '-' + sn[2] + '-' + sn[3];
		serialnumber = serialnumber.toUpperCase();
		return $http.get(
			'/api/validation/company/' + company + '/sn/' + serialnumber
		);
	};

	svc.executeQuestion = function (question) {
		console.log(question);
		switch (question.action) {
			case 'delete':
				return $http.delete(
					'/api/companies/' +
						question.data.company._id +
						'/' +
						question.model +
						'/' +
						question.data.data
				);
		}
		return $http.post(
			'/api/companies/' + question.data._id + '/' + question.action,
			question.data
		);
	};

	svc.uploadLogo = function (logo, company) {
		var formData = new FormData();
		formData.append('logo', logo);

		// $http.post('/api/companies/' + company + '/logo', {logo: logo})

		$http.post(
			'/api/companies/' + company + '/logo',
			{ logo: logo },
			{
				transformRequest: angular.identity,
				headers: { 'Content-Type': 'application/json' },
			}
		);
	};

	svc.getHelp = function (number) {
		return $http.get('/api/companies/' + number + '/help');
	};

	svc.updateTemplateHeader = function (company, templateHeader) {
		return $http.put('/api/companies/' + company.number + '/template-header', company, templateHeader);
	};
});
