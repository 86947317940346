angular.module('app').service('FileSvc', function ($http) {
	var svc = this;

	svc.getUserImages = function (user, dir) {
		if (!dir) {
			return $http.get('/api/files/user/' + user._id + '/images');
		} else {
			if (dir.isCurated) {
				return $http.get('/api/files/images/' + dir.dir);
			} else {
				return $http.get(
					'/api/files/company/' + user.company.number + '/images/' + dir.dir
				);
			}
		}
	};

	svc.getCompanyImages = function (company, dir) {
		if (!dir) {
			return $http.get('/api/files/company/' + company + '/images/');
		} else {
			if (dir.isCurated) {
				return $http.get('/api/files/images/' + dir.dir);
			} else {
				return $http.get(
					'/api/files/company/' + company + '/images/' + dir.dir
				);
			}
		}
	};

	svc.getImageFolders = function (company) {
		return $http.get('/api/files/company/' + company + '/folders');
	};

	svc.deleteFileFromCompany = function (file) {
		return $http.delete(
			'/api/files/company/' + file.company + '/' + file.filename
		);
	};

	svc.deleteFileFromUser = function (file) {
		return $http.delete('/api/files/user/' + file.user + '/' + file.filename);
	};

	svc.uploadLogo = function (logo, company, extra) {
		return upload('/api/files/company/' + company + '/logo', logo, extra);
	};

	svc.uploadFileToCompany = function (file, company, extra) {
		return upload('/api/files/company/' + company, file, extra);
	};

	svc.uploadFileToUser = function (file, user, extra) {
		return upload('/api/files/user/' + user, file, extra);
	};

	svc.uploadSheetImage = function (file, sheet, extra) {
		return upload('/api/sheets/' + sheet._id + '/file', file, extra);
	};

	svc.getFonts = function () {
		return $http.get('/api/assets/fonts');
	};

	svc.getCompanyFonts = function (companyNumber) {
		return $http.get(`/api/assets/fonts/company/${companyNumber}`);
	};

	svc.uploadFontToCompany = function (file, company, extra) {
		return upload(`/api/files/company/${company}/font`, file, extra);
	};

	svc.digestSpreadsheet = function (file, extra) {
		return upload('/api/assets/digest/spreadsheet', file, extra);
	};

	function upload(url, file, extra) {
		extra = extra || {};
		var fd = new FormData();
		fd.append('file', file);

		if (extra.name) fd.append('name', extra.name);
		if (extra.overwrite) fd.append('overwrite', extra.overwrite);

		return $http.post(url, fd, {
			transformRequest: angular.identity,
			headers: { 'Content-Type': undefined },
		});
	}
});
