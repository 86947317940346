/**
 * Angular Object Resizers Controller (draggable corners on objects)
 * @file ng/controllers/admin/object-resizers.ctrl.js
 * @class angular.controllers.ObjectResizersCtrl
 * @memberOf angular.controllers
 */
angular.module('app')
	.controller('ObjectResizersCtrl', function($scope, $location, CompanySvc, TemplateSvc) {

		//Puts the resizer in the correct location
		$scope.getResizerStyle = function(x, y) {
			if (!$scope.selectedObject() || !$scope.editing || !$scope.selectedObjectType || $scope.selectedObjectType === 'tmplt' || !$('#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit').length) {
				return {
					display: 'none'
				};
			}
			//Try is needed because when you paste a copied object you get a brief moment where position() is not available
			//Try suppresses the irrelevant error
			//TODO: Find out why the error happens and prevent it
			try {
				var top = $('#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit').position().top;
				var left = $('#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit').position().left;
				var width = $('#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit').width();
				var height = $('#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit').height();
				switch (x) {
					case 0: //Left
						switch (y) {
							case 0: //Top
								return {
									top: top - 2,
									left: left - 2
								};
							case 1: //Middle
								return {
									top: top + height / 2 - 4,
									left: left - 2
								};
							case 2: //Bottom
								return {
									top: top + height - 6,
									left: left - 2
								};
						}
						break;
					case 1: //Center
						switch (y) {
							case 0: //Top
								return {
									top: top - 2,
									left: left + width / 2 - 4
								};
							case 2: //Bottom
								return {
									top: top + height - 6,
									left: left + width / 2 - 4
								};
						}
						break;
					case 2: //Right
						switch (y) {
							case 0: //Top
								return {
									top: top - 2,
									left: left + width - 6
								};
							case 1: //Middle
								return {
									top: top + height / 2 - 4,
									left: left + width - 6
								};
							case 2: //Bottom
								return {
									top: top + height - 6,
									left: left + width - 6
								};
						}
				}
			} catch (e) {
				console.error(e);
			}
		};

		//Executes when one of the resizers is dragged
		$scope.resizeObject = function(event, ui, x, y) {
			var top = $scope.selectedObject().originalDragDimensions.top;
			var left = $scope.selectedObject().originalDragDimensions.left;
			var width = $scope.selectedObject().originalDragDimensions.width;
			var height = $scope.selectedObject().originalDragDimensions.height;
			switch (x) {
				case 0: //Left
					if (ui.position.left > ui.originalPosition.left + width - 20) {
						left = ui.originalPosition.left + width - 20;
						ui.position.left = left - 2;
					} else {
						left = ui.position.left + 2;
					}
					width = width + (ui.originalPosition.left - left) + 2;
					break;
				case 1: //Center
					ui.position.left = ui.originalPosition.left;
					break;
				case 2: //Right
					if (ui.position.left < left + 20) {
						width = 20;
						ui.position.left = left + width - 6;
					} else {
						width = ui.position.left - left + 6;
					}
					break;
			}
			switch (y) {
				case 0: //Top
					if (ui.position.top > ui.originalPosition.top + height - 20) {
						top = ui.originalPosition.top + height - 20;
						ui.position.top = top - 2;
					} else {
						top = ui.position.top + 2;
					}
					height = height + (ui.originalPosition.top - top) + 2;
					break;
				case 1: //Middle
					//height = height - 2;
					ui.position.top = ui.originalPosition.top;
					break;
				case 2: //Bottom
					if (ui.position.top < top + 20) {
						height = 20;
						ui.position.top = top + height - 6;
					} else {
						height = ui.position.top - top + 6;
					}
					break;
			}
			$('#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit').css({
				top: top < 0 ? 0 : top,
				left: left,
				width: width,
				height: height
			});
			TemplateSvc.moveObject($scope.selectedTemplate, $scope.selectedObjectType, $scope.selectedObject());
			$scope.$apply();
		};
	});