/*jshint esversion: 6 */
/**
 * Angular Layer Controller
 * @file ng/controllers/admin/layer.ctrl.js
 * @class angular.controllers.LayerCtrl
 * @memberOf angular.controllers
 */
angular.module('app')
	.controller('LayerCtrl', function($scope, $stateParams, AppSvc, TemplateSvc) {

		//NOTE: Layers are numbered starting from 1 (not 0)

		$scope.moveLayerItem = function(object, direction) {
			$scope.do();
			var oldIndex = object.index;
			$scope.getObjects().forEach(function(obj) {
				console.log(`index ${obj.index}`);
				console.log(`oldIndex ${oldIndex}`);
				console.log(`direction: ${direction}`);
				if (obj.index === oldIndex + direction) {
					obj.index -= direction;
					object.index += direction;
					$scope.selectObject(object.placeholder ? 'tbx' : 'img', object);
					return $scope.refresh();
				}
			});
		};

		// $scope.moveLayerItemFirst = function(object) {
		// 	$scope.do();
		// 	var oldIndex = object.index;
		// 	$scope.getObjects().forEach(function(obj) {
		// 		if (obj.index > oldIndex) {
		// 			obj.index--;
		// 		}
		// 	});
		// 	object.index = $scope.getObjects().length;
		// 	$scope.selectObject(object.placeholder ? 'tbx' : 'img', object);
		// 	$scope.refresh();
		// };
		//
		// $scope.moveLayerItemLast = function(object) {
		// 	$scope.do();
		// 	var oldIndex = object.index;
		// 	var background = $scope.templateBackground();
		// 	$scope.getObjects().forEach(function(obj) {
		// 		if (obj.index < oldIndex) {
		// 			if ((background && obj.index !== 1) || !background) {
		// 				obj.index++;
		// 			}
		// 		}
		// 	});
		// 	object.index = background ? 2 : 1;
		// 	$scope.selectObject(object.placeholder ? 'tbx' : 'img', object);
		// 	$scope.refresh();
		// };

		$scope.templateBackground = function() {
			if (!$scope.selectedTemplate) return {};
			var background = _.find($scope.selectedTemplate.images, function(image) {
				return image.isBackground;
			});
			return background;
		};

		/**
		 * @ngdoc function
		 * @name getCanvasStyle
		 * @methodOf npgc.controller:DataEntryCtrl
		 * @description
		 * @returns {Object} The CSS style of canvas (area surrounding the template)
		 */
		$scope.getCanvasStyle = (template, modifier) => {
			if (template) {
				const canvasWidth = $scope.getWidth($scope.selectedTemplate.width - 0.5, $scope.selectedTemplate.height);
				return TemplateSvc.getCanvasStyle(template, canvasWidth > 300 ? 300 : canvasWidth, 1, false);
			}
		};

	});