window.jQuery = $ = window.$ = jQuery;
/**
 * Angular declaration
 * @namespace angular
 */
angular
    .module('app', [
        'ngResource',
        //'ngTouch',
        'ngAnimate',
        'ngSanitize',
        'ngFileSaver',
        'ui.router',
        'ui.bootstrap',
        'ngFileUpload',
        'ngDragDrop',
        'tc.chartjs',
        'ui.utils.masks',
        'minicolors',
        'infinite-scroll',
        'fayzaan.gRecaptcha.v3',
        'app.config',
        'textAngular',
        'ui.sortable'
    ]).config(['$httpProvider', function ($httpProvider) {
    //This is necessary as IE caches AngularJS scope elements
    //For example, without it, when a new template was saved or a template deleted, the templates list would not adjust and keep the cached templates
    //https://stackoverflow.com/questions/16098430/angular-ie-caching-issue-for-http
    //initialize get if not there
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }

    //disable IE ajax request caching
    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    // extra
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
}]);