angular
	.module('app')
	.service('PrintSvc', function ($http, TemplateSvc, BadgeSvc) {
		const svc = this;
		//enteredData will be interpreted by the server into a pdf
		//The server will return the location of the pdf which will then be loaded into the frame on the web page
		svc.getPDF = (
			sheet,
			badge,
			user,
			startingBadge,
			alignment,
			orientation,
			pdfName,
			isGuest,
			order
		) => {
			//Send the selected sheet size and created html code to the server to create the PDF
			return $http.post('/api/print', {
				sheet,
				badge,
				user: user._id,
				company: user.company.number,
				startingBadge,
				badges: BadgeSvc.getFilteredBadges(TemplateSvc.enteredData),
				alignment,
				orientation,
				pdf: pdfName,
				guest: isGuest,
				imagesBase64: TemplateSvc.imagesBase64,
				order,
			});
		};

		svc.logPrint = (sheet, user) => {
			//Send the selected sheet size and created html code to the server to create the PDF
			return $http.post('/api/print/log', {
				paper: sheet.paper.name,
				badge: sheet.badge.code,
				user: user._id,
				company: user.company._id,
				amount: BadgeSvc.getFilteredBadges(TemplateSvc.enteredData).length,
			});
		};

		svc.makeTemplateProof = template => {
			return $http.post(`/api/print/template/${template._id}/proof`, template, {
				responseType: 'arraybuffer',
			});
		};

		svc.makePdfProof = data => {
			return $http.post(`/api/print/sheet/proof`, data, {
				responseType: 'arraybuffer',
			});
		};

		svc.getSheetPDF = (user, pdf) => {
			return $http.get(
				`/api/print/${user.company.number}/${user._id}/pdf/${pdf}`,
				{
					responseType: 'arraybuffer',
				}
			);
		};
	});
