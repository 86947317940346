/*jshint esversion: 10 */
angular.module('app')
	.controller('ImportCtrl', function($scope, AppSvc, FileSvc, TemplateSvc) {

		//importables will keep track of which column should be imported into which object
		$scope.importables = {};
		$scope.imageDropdowns = {};

		$scope.displayRatio = 0.6;
		$scope.id = 'dataentry';

		//Triggered in DataEntryCtrl in $scope.selectTemplate
		$scope.$on('template-selected', async function () {
			$scope.imageDropdowns = {};
			let images = TemplateSvc.selectedTemplate.images.filter(function (image) {
				return image.isDropdown;
			});
			let _imageDropdowns = {};
			for (let i in images) {
				_imageDropdowns[images[i]._id] = await getImageDropdown(images[i]);
			}
			$scope.imageDropdowns = _imageDropdowns;

			$scope.importedData = {};
			$scope.importables = {};
		});

		async function getImageDropdown(image) {
			return FileSvc.getUserImages(AppSvc.user, image.dropdownFolder)
				.then(function(response) {
					image.dropdownItems = response.data;
					return image;
				});
		}

		//Select a file
		$scope.importFile = function(file) {
			if (!file) return;
			if ((file.size / 1024 / 1024) > 5) {
				$scope.spreadsheet = file.name + ': ' + $scope.getCopy('messageFileSizeError5MB');
				$scope.$apply(function() {
					return $scope.$emit('popup', {
						message: $scope.getCopy('messageFileSizeError5MB'),
						type: 'alert-danger'
					});
				});
				return;
			}
			$scope.spreadsheet = file.name;
			$scope.loading = true;
			//This will parse the file into JSON (server side)
			FileSvc.digestSpreadsheet(file)
				.then(function(response) {
					$scope.importedData = response.data;
					$scope.importables = {};
					let imageKeys = Object.keys($scope.imageDropdowns);
					console.log(imageKeys);
					if (imageKeys.length > 0) {
						for (let row in $scope.importedData) {
							let imageIndex = 0;
							for (let col in $scope.importedData[row]) {
								if ($scope.importedData[row][col].toString().startsWith('FLAG: ')) {
									$scope.importedData[row][imageKeys[imageIndex++]] = $scope.importedData[row][col].toString().replace('FLAG: ', '');
									delete $scope.importedData[row][col];
								}
							}

							// Fill default image if not assigned
							for (let i in imageKeys) {
								let imageKey = imageKeys[i];
								if ($scope.importedData[row][imageKey] === undefined) {
									$scope.importedData[row][imageKey] = $scope.imageDropdowns[imageKey].name;
								}
							}
						}
					}


					$scope.loading = false;
					//TODO: refresh Files after upload
					//image.name = file.name;
					// $state.reload();
				}, function(err) {
					console.trace(err);
					$scope.$apply(function() {
						return $scope.$emit('popup', {
							message: $scope.getCopy('messageImportError'),
							type: 'alert-danger'
						});
					});
				});
		};

		$scope.unselectImportable = function(index) {
			delete $scope.importables[index];
		};

		$scope.selectImportable = function(index, object) {
			$scope.importables[index] = object._id;
		};

		$scope.getObject = function(_id) {
			for (var i in $scope.selectedTemplate.textboxes) {
				if (_id === $scope.selectedTemplate.textboxes[i]._id) {
					return $scope.selectedTemplate.textboxes[i];
				}
			}
		};
		//Get the source of the image (dropdown / uploaded image / static image)
		$scope.getImage = function(image, name) {
			if (image) {
				image.name = name;
			}
			return image;
		};

		$scope.getImageDropdown = function(row, columnkey) {
			$scope.selectedRow = row;
			$scope.selectedColumnkey = columnkey;
			$scope.selectingImage = true;
			$scope.refresh();
		};

		$scope.selectImage = function(image) {
			$scope.selectedRow[$scope.selectedColumnkey] = image;
			$scope.selectingImage = false;
		};

		/*
		  $scope.getFiles = function (dir) {
		    return FileSvc.getUserImages(AppSvc.user, dir)
		    .then(function (response) {
		      $scope.files = response.data;
		      console.log($scope.files);
		    });
		  };
		*/
		$scope.getSelectableObjects = function() {
			return $scope.selectedTemplate.textboxes;
			/*
			    return _.filter($scope.selectedTemplate.textboxes, function(object) {
			      return _.filter($scope.importables, function(importable) {
			        return importable._id === object._id;
			      }).length === 0;
			    });*/
		};

		$scope.getImageDropdowns = function() {
			return $scope.selectedTemplate.images.filter(function(image) {
				return image.isDropdown;
			});
		};

		//This function checks whether all the mandatory textboxes on the template have been chosen
		$scope.checkFields = function() {
			if (!$scope.selectedTemplate ||
				!$scope.importedData ||
				JSON.stringify($scope.importedData) === '{}' ||
				!$scope.imageDropdowns && Object.keys($scope.importables).length === 0) return false;

			var mandatoryFieldsSelected = true;
			for (var i in $scope.selectedTemplate.textboxes) {
				var object = $scope.selectedTemplate.textboxes[i];
				if (!object.isEmptyAllowed) {
					var found = false;
					for (var j in $scope.importables) {
						if ($scope.importables[j] === object._id) {
							found = true;
							break;
						}
					}
					if (!found) {
						mandatoryFieldsSelected = false;
						break;
					}
				}
			}
			return mandatoryFieldsSelected;
		};

		$scope.getAllRowsToggled = function() {
			return !_.find($scope.importedData, function(row) {
				return !row.add;
			});
		};

		$scope.toggleRows = function() {
			var toggle = !$scope.getAllRowsToggled();
			Object.keys($scope.importedData).map(function(row) {
				$scope.importedData[row].add = toggle;
			});
		};

		$scope.enterImportedData = function() {
			$scope.importing = true;
			var i, j, count = 0;
			importData().then((template, imported) => {
				//This section resets the template to its original settings (Autosizes back to default font and font size)
				for (i in TemplateSvc.selectedTemplate.textboxes) {
					template.textboxes[i].fontName = TemplateSvc.selectedTemplate.textboxes[i].originalFontName;
					template.textboxes[i].fontType = TemplateSvc.selectedTemplate.textboxes[i].originalFontType;
					template.textboxes[i].fontType = TemplateSvc.selectedTemplate.textboxes[i].originalFontLocation;
					if (TemplateSvc.selectedTemplate.textboxes[i].isDropdown) {
						//Reset Dropdown
						var found = false;
						for (j in TemplateSvc.selectedTemplate.textboxes[i].dropdownItems) {
							if (TemplateSvc.selectedTemplate.textboxes[i].value === TemplateSvc.selectedTemplate.textboxes[i].dropdownItems[j].value) {
								$scope.dropdownAutosize(template, 0, template.textboxes[i]._id, TemplateSvc.selectedTemplate.textboxes[i].dropdownItems[j]);
								found = true;
								break;
							}
						}
						if (!found) {
							$scope.dropdownAutosize(template, 0, template.textboxes[i]._id, TemplateSvc.selectedTemplate.textboxes[i].dropdownItems[0]);
						}
					} else {
						//Reset Textbox
						template.textboxes[i].value = '';
						template.textboxes[i].adjustedFontSizePct = 1;
						$scope.textAutoSize(template.textboxes[i]);
					}
				}
				//Emit to the app controller that there is data entered so the Print button shows up
				if (TemplateSvc.enteredData.length > 0) {
					$scope.$emit('data-entered', true);
				}
				//Set focus to the first editable textbox
				var firstEditableTextbox = template.textboxes.filter(function(textbox) {
					return !textbox.isDropdown && textbox.editable;
				})[0];
				if (firstEditableTextbox) {
					$("#" + firstEditableTextbox._id).focus();
				}
				$scope.importing = false;
				$scope.toggleModal('importModal');
			}, function(err) {
				return $scope.$emit('popup', {
					message: $scope.getCopy('error'),
					type: 'alert-danger'
				});
			});

		};


		var importData = function() {
			return new Promise((resolve, reject) => {
				var i, j, count = 0;
				var template = $scope.selectedTemplate;
				for (var row in $scope.importedData) {
					for (i in template.textboxes) {
						template.textboxes[i].value = template.textboxes[i].editable ?  '' : template.textboxes[i].placeholder;
					}
					if ($scope.importedData[row].add) {
						for (var column in $scope.importables) {
							var textbox = $scope.getObject($scope.importables[column]);
							textbox.value += (textbox.value ? ' ' : '') + $scope.importedData[row][column];
							$scope.textAutoSize(textbox);
						}
						for (column in $scope.imageDropdowns) {
							for (i in template.images) {
								if (template.images[i]._id === column) {
									template.images[i].name = $scope.importedData[row][column];
									break;
								}
							}
						}
						for (i in template.images) {
							var image = template.images[i];
							image.aspectRatio = TemplateSvc.getImageAspectRatio(image, (image.cid ? image.cid : image._id) + '_dataentry');
						}
						/*
						for (i in template.images) {
							var image = template.images[i];
							image.aspectRatio = TemplateSvc.getImageAspectRatio(image, $scope.id + '_tmplt_' + $scope.currentTemplateIndex + '_' + (image.cid ? image.cid : image._id));
						}
						*/
						count++;
						TemplateSvc.enteredData.push(JSON.parse(JSON.stringify(template)));
						//TemplateSvc.enteredData.push(JSON.parse(angular.toJson(template)));
					}
				}
				resolve(template, count);
			});
		};

		$scope.dropdownWidth = function() {
			return {
				minWidth: (Math.floor(AppSvc.windowWidth * 0.6 / 100) * 100 + 5) + 'px'
			};
		};

	});