/*jshint esversion: 6 */
/**
 * Angular Recent Templates Controller
 * @file ng/controllers/admin/recent-templates.ctrl.js
 * @class angular.controllers.RecentTemplatesCtrl
 * @memberOf angular.controllers
 */
angular.module('app')
	.controller('RecentTemplatesCtrl', function($scope, AppSvc, UserSvc, TemplateSvc, CategorySvc) {

		$scope.displayRatio = 0.3;
		$scope.id = 'recent';
		$scope.company = AppSvc.user.company;

		TemplateSvc.getTemplatesByCompanyAndUser(AppSvc.user.company.number, AppSvc.user._id).then(function (){
			$scope.categoriesLength = CategorySvc.categories ? CategorySvc.categories.length : 0;
		});

		$scope.setDefaultTemplate = function(template) {
			AppSvc.user.templateDefault = template._id;
			UserSvc.updateUser(AppSvc.user);
		};

		$scope.getRecentTemplates = function() {
			TemplateSvc.getTemplatesByCompanyAndUser(AppSvc.user.company.number, AppSvc.user._id)
				.then(templates => {
					if ($scope.orderLine) {
						$scope.recentTemplates = templates.filter(template => template.type === 'user' && template.badge.code === $scope.orderLine.badge);
					} else {
						$scope.recentTemplates = templates.filter(template => !_.some(TemplateSvc.recentTemplates, recentTemplate => recentTemplate === template._id));
					}
				});
		};

	});