angular.module('app').service('MagentoSvc', function ($http, UserSvc) {
	var svc = this;

	svc.proof = function (order, orderLine, enteredData) {
		return $http.post('/api/magento/proof/' + order.quoteid, {
			lineid: orderLine.lineid,
			enteredData: enteredData,
		});
	};

	svc.login = function (token) {
		return $http
			.post('/api/sessions/magento', {
				token: token,
			})
			.then(function (response) {
				return UserSvc.setToken(response.data);
			});
	};
});
