/**
 * Angular Admin - Company Controller
 * @file ng/controllers/admin/admin.company.ctrl.js
 * @class angular.controllers.AdminCompanyCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'AdminCompanyCtrl',
		function (
			$scope,
			$location,
			$state,
			$stateParams,
			AppSvc,
			FileSvc,
			CompanySvc,
			UserSvc
		) {
			if (!AppSvc.user || !(AppSvc.user.admin || AppSvc.user.options)) {
				$location.path(AppSvc.getURL());
			}
			$scope.hideSettings = true;

			$scope.loginTypes = [
				{
					name: 'No Login',
					value: 0,
					icon: 'fa-sign-in',
				},
				{
					name: 'Single Login',
					value: 1,
					icon: 'fa-user',
				},
				{
					name: 'Multiple Users - Validation Code',
					value: 10000,
					icon: 'fa-users',
				},
				{
					name: 'Multiple Users - Serial Number',
					value: -1,
					icon: 'fa-barcode',
				},
			];

			$scope.getLoginType = function () {
				if (!$scope.loginTypes || !$scope.company) return;
				return _.find($scope.loginTypes, function (loginType) {
					return (
						loginType.value === $scope.company.loginType ||
						($scope.company.loginType > 1 && loginType.value > 1)
					);
				});
			};

			if ($stateParams.company !== 'new') {
				//$('#companyTabs').show();
				$scope.hideSettings = true;
				CompanySvc.getCompany($stateParams.company).then(function (response) {
					$scope.company = response.data;
					$scope.categories = $scope.company.categories;
					$scope.logo = `companies/${$scope.company.number}/images/logo.png`;
					if ($scope.company.loginType !== 0) {
						UserSvc.getUsersByCompany($stateParams.company).then(function (
							response
						) {
							$scope.company.users = response.data;
							$scope.company.defaultUser = $scope.company.users.filter(
								function (user) {
									return $scope.company.userDefault === user._id;
								}
							)[0];
							$scope.changeTracker = angular.toJson($scope.company);
						});
					} else {
						$scope.company.users = [];
						$scope.company.defaultUser = {};
					}
					var languages = Object.keys(AppSvc.appCopy.language);
					languages.forEach(function (language) {
						for (var i in $scope.company.templateOptions) {
							var option = $scope.company.templateOptions[i];
							if (!containsObject(option.copy, 'language', language)) {
								option.copy.push({
									language: language,
									name: '',
								});
							}
						}
					});
				});
			} else {
				//$('#companyTabs').hide();
				$scope.company = {
					loginType: 10000,
					enabled: true,
					paperDefault: 'Letter',
					paperChoice: false,
					isSheetBlank: true,
					orientationChoice: false,
					autoHelpFile: false,
					svgMode: true,
					mode: 'pdfkit',
				};
				CompanySvc.getCustomers().then(
					function (response) {
						$scope.customers = response.data;
						console.log($scope.customers);
					},
					function (err) {
						console.error(err);
					}
				);
			}

			$scope.selectCustomer = function (company, model, label) {
				$scope.company.number = company.number;
				if ($scope.company.customerGroup === 'B2B') {
					$scope.company.name = company.name;
				}
			};

			$scope.getURL = function () {
				if (!$scope.company || !$scope.company.validationCode) return '';
				return (
					$location.protocol() +
					'://' +
					$location.host() +
					'/company/' +
					$scope.company.validationCode.replace(/\s/g, '').toLowerCase()
				);
			};

			$scope.updateOrCreate = function (logo) {
				$scope.saving = true;
				let company = $scope.company;
				console.log(company);
				$scope.changeTracker = angular.toJson($scope.company);
				if (company.defaultUser) company.userDefault = company.defaultUser._id;
				if (!company.hasOwnProperty('enabled')) company.enabled = true;
				if (!company.creator) company.creator = AppSvc.user._id;
				if (company.loginType <= 0) {
					company.validationCode = company.validationCode
						.replace(/\s/g, '')
						.toLowerCase();
				} else if (company.loginType === 1) {
					company.validationCode = Math.floor(
						Math.random() * 999999999999999
					).toString();
				} else if (!company.validationCode) {
					const originalBg = $('#validation-code').css('backgroundColor');
					$('#validation-code')
						.animate({ backgroundColor: '#FFB6C1' }, 500)
						.animate({ backgroundColor: originalBg }, 500);
					$scope.saving = false;
					return;
				}
				if (company.customerGroup === 'Distributor' && !company._id)
					company.number =
						company.number.toUpperCase() +
						'_' +
						company.name.replace(/\s/g, '').toLowerCase();
				if ($scope.logo != logo || !company._id) company.logo = logo;

				if (!company._id)
					CompanySvc.createCompany(company).then(response => {
						$state.go('admincompany', {
							company: response.data.number,
						});
					});
				else
					CompanySvc.updateCompany(company).then(response => {
						$scope.hideSettings = true;
						$scope.saving = false;
						$scope.$emit('popup', {
							message: $scope.getCopy('saved'),
							type: 'alert-success',
						});
					});
			};

			$scope.uploadLogo = function () {
				//TODO: validate 'file' as image
				FileSvc.uploadLogo($scope.newLogo, $scope.company.number).then(
					function (response) {
						console.log(response.data);
						//TODO: refresh Files after upload
						$scope.newLogo = '';
						$scope.logo = `companies/${
							$scope.company.number
						}/images/logo.png?${Math.floor(Math.random() * 100000000)}`;
						$scope.$emit('popup', {
							message: $scope.getCopy('saved'),
							type: 'alert-success',
						});
					},
					console.error
				);
			};

			function containsObject(list, obj, item) {
				return _.find(list, function (listItem) {
					return listItem[obj] == item;
				});
			}
			$scope.getUsers = function () {
				UserSvc.getUsersByCompany($stateParams.company).then(function (
					response
				) {
					$scope.users = response.data;
					//console.log($scope.users);
				});
			};

			$scope.getUsers();

			$scope.highlightLogo = (size, url) => {
				$('.navbar-brand-link img')
					.attr('src', url)
					.css({
						'-webkit-transform': `scale(${size})`,
						'-moz-transform': `scale(${size})`,
						'-ms-transform': `scale(${size})`,
						'-o-transform': `scale(${size})`,
						transform: `scale(${size})`,
						'transform-origin': 'top left',
						'transition-duration': '1s',
					});
			};
		}
	);
