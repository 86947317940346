/**
* Angular Admin - Company Domains Tab Controller
* @file ng/controllers/admin/admin.company.domains.ctrl.js
* @class angular.controllers.AdminCompanyDomainsCtrl
* @memberOf angular.controllers
*/
angular.module('app')
  .controller('AdminCompanyDomainsCtrl', function($scope, $stateParams, CompanySvc) {

    $scope.deleteDomain = function(domain) {
      var question = {
        'title': $scope.getCopy('delete') + ' - ' + domain,
        'question': $scope.getCopy('deleteDomain') + ' ' + $scope.getCopy('messageActionIrreversible'),
        'model': 'domain',
        'api': 'company',
        'action': 'delete',
        'data': {
          company: $scope.company,
          data: domain
        },
        'exec': function() {
          CompanySvc.executeQuestion(question)
          .then(function (response) {
            $scope.$emit('questionExecuted', response, question);
          });
        }
      };
      $scope.askQueryQuestion(question);
    };

    $scope.addDomain = function(domain) {
      if (domain) {
        $scope.company.domain.push(domain);
        CompanySvc.updateCompany($scope.company)
          .success(function(response) {
            if (response._id) {
              $scope.$emit('popup', {
                message: $scope.getCopy('saved'),
                type: 'alert-success'
              });
            }
            $scope.search = '';
          });
      }
    };
  });
