/**
 * Angular Home Controller
 * @file ng/controllers/home.ctrl.js
 * @class angular.controllers.HomeCtrl
 * @memberOf angular.controllers
 */
angular.module('app')
	.controller('HomeCtrl', function($scope, $state, $stateParams, $location, AppSvc) {
		if ($location.search().registerType) {
			window.localStorage.registerType = $location.search().registerType;
		} else {
			AppSvc.setRegisterType($location.search().registerType || $state.current.data.registerType || window.localStorage.registerType || 'VC');
		}
		AppSvc.setCompany($location.search().company || $state.current.data.company || window.localStorage.company || 'imprintplus');
		AppSvc.setDefaultPaper($location.search().paper || $state.current.data.paper || $stateParams.paper || window.localStorage.paper || 'Letter');

		//Login Button
		$scope.login = function() {
			$state.go('login');
		};
		//Register Button
		$scope.register = function() {
			$state.go('register');
		};
		//Start Making Name Badges Button
		$scope.dataentry = function() {
			if (window.localStorage.recentTemplates) {
				$state.go('dataentry', { edit: false });
			} else {
				$state.go('templates');
			}
		};
	});