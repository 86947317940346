/**
 * Angular Admin - Company Users Tab Controller
 * @file ng/controllers/admin/admin.company.users.ctrl.js
 * @class angular.controllers.AdminCompanyUsersCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller('AdminCompanyUsersCtrl', function (
		$scope,
		$stateParams,
		AppSvc,
		UserSvc
	) {
		$scope.sortBy = function (sorter) {
			$scope.reverse = $scope.sorter === sorter ? !$scope.reverse : false;
			$scope.sorter = sorter;
		};

		$scope.deleteUser = function (user) {
			if (user._id == AppSvc.user._id) {
				$scope.$emit('popup', {
					message: $scope.getCopy('messageDeleteOwnUserUnable'),
					type: 'alert-danger',
				});

				var message = {
					title: $scope.getCopy('delete') + ' ' + $scope.getCopy('user'),
					content: $scope.getCopy('messageDeleteOwnUserUnable'),
				};
				$scope.showPopupNotification(message);
			} else {
				var question = {
					title: $scope.getCopy('delete') + ' ' + $scope.getCopy('user') + '?',
					question:
						$scope.getCopy('delete') +
						' ' +
						user.username +
						'? ' +
						$scope.getCopy('messageActionIrreversible'),
					model: 'user',
					api: 'users',
					action: 'delete',
					data: user,
					exec: function () {
						UserSvc.deleteUser($stateParams.company, user).then(function (
							response
						) {
							$scope.getUsers();
							$scope.$emit('questionExecuted', response, question);
						});
					},
				};
				$scope.askQueryQuestion(question);
			}
		};

		$scope.saveUser = function (user) {
			UserSvc.updateUserFromAdmin($stateParams.company, user).then(function (
				response
			) {
				if (response._id) {
					$scope.$emit('popup', {
						message: $scope.getCopy('saved'),
						type: 'alert-success',
					});
				}
			});
		};
	});
