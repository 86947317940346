angular
	.module('app')
	.directive(
		'ngImageLoad',
		function ($http, $parse, $stateParams, $rootScope, TemplateSvc, AppSvc) {
			return {
				restrict: 'EA',
				scope: {
					image: '=ngImageLoad',
				},
				link: function (scope, element, attrs) {
					element.bind('load', function () {
						setTimeout(function () {
							scope.$apply();
						}, 500);
					});

					element.bind('error', function (err) {
						loadImage();
					});

					function revokeObjectURL() {
						if (scope.image && scope.image.blobURL) {
							URL.revokeObjectURL(scope.image.blobURL);
						}
					}

					function loadImage() {
						if (!scope.image || scope.image.hasOwnProperty('fontName')) {
							console.log(scope.image);
							return;
						}
						const company = $stateParams.company
							? $stateParams.company
							: AppSvc.user.company.number;
						const user = $stateParams.user ? $stateParams.user : AppSvc.user._id;
						let url = '';
						if (scope.image.dir) {
							url = `${scope.image.dir}/${scope.image._id}`;
						} else if (scope.image.file) {
							url = scope.image.file;
						} else if (scope.image.image && scope.image.image.location) {
							url = `${scope.image.image.location}/${scope.image.image._id}`;
						} else {
							const name = scope.image.image ? scope.image.image._id : scope.image.name;
							url =
								(scope.image.isDropdown
									? `${
											scope.image.dropdownFolder.isCurated
												? 'images/dropdowns/'
												: `companies/${company}/`
									  }${scope.image.dropdownFolder.dir}/`
									: `companies/${company}${
											scope.image.type === 'user' ? `/users/${user}` : '/images'
									  }/`) + name;
						}

						if (url && url.indexOf('data:') === 0) {
							scope.image.blobURL = url;
						} else if (url) {
							$http.get(url).then(function (response) {
								scope.image.blobURL = response.data;
							}, console.error);
						}
					}

					scope.$watch('image.blobURL', function (blobURL) {
						element.attr('src', blobURL);
					});

					$rootScope.$on('imageUpdated', function (event, _id) {
						loadImage();
					});

					scope.$on('$destroy', function () {
						revokeObjectURL();
					});

					scope.$watch('ngImageLoad', function () {
						loadImage();
					});
				},
			};
		}
	);
