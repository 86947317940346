angular.module('app').service('UserSvc', function ($http) {
	var svc = this;

	svc.getUser = function () {
		return $http.get('/api/users');
	};

	svc.getUsers = function (page = 1, limit = 10, sorter = null, reverse = null, searchFilter = {}) {
		let params = {
			page: page,
			limit: limit,
			sorter: sorter,
			reverse: reverse
		};
		return $http.get('/api/users/users', {
			params: {...params, ...searchFilter}
		});
	};

	svc.searchUser = function(username, email, serialNumber) {
		return $http.get('/api/users/search', {
			params: {
				username: username,
				email: email,
				serialNumber: serialNumber
			}
		});
	};

	svc.getEmails = function (company) {
		if (company) {
			return $http.get('/api/users/company/' + company + '/emails');
		} else {
			return $http.get('/api/users/emails');
		}
	};

	svc.setToken = function (token) {
		window.sessionStorage.token = token;
		$http.defaults.headers.common['X-Auth'] = token;
		svc.token = token;
		return svc.getUser();
	};

	svc.getToken = () => $http.defaults.headers.common['X-Auth'];

	svc.getLoginInfo = function (language, email, company) {
		return $http.get(
			`/api/forgot?language=${language}&email=${email}&company=${company}`
		);
	};

	svc.login = function (username, password, captchaToken) {
		return $http
			.post('/api/sessions', {
				username: username,
				password: password,
				captchaToken: captchaToken,
			})
			.then(function (response) {
				return svc.setToken(response.data);
			});
	};

	svc.guest = function (companyName, paper) {
		return $http
			.get('/api/sessions/guest/' + companyName + (paper ? '/' + paper : ''))
			.then(function (response) {
				return svc.setToken(response.data);
			});
	};

	svc.updateUser = function (user) {
		return $http
			.post('/api/users/' + user._id, {
				user: user,
			})
			.then(function () {
				return svc.getUser();
			});
	};

	svc.saveUser = function (user) {
		return $http.post('/api/users/' + user._id, {
			user: user,
		});
	};

	svc.checkPassword = function (user, password) {
		return $http.post('/api/users/' + user + '/verification', {
			password: password,
		});
	};

	svc.checkEmail = function (email) {
		return $http.get('/api/validation/email/' + email);
	};

	svc.changePassword = function (user, oldPassword, newPassword) {
		return $http.post('/api/users/' + user + '/password', {
			oldPassword: oldPassword,
			newPassword: newPassword,
		});
	};

	svc.forgotPassword = function (user, language) {
		return $http.post('/api/forgot', {
			user: user,
			language: language,
		});
	};

	svc.resetPassword = function (token, password) {
		return $http.post('/api/reset', {
			token: token,
			password: password,
		});
	};

	svc.changeUsername = function (user, newUsername) {
		return $http.post('/api/users/' + user + '/username', {
			newUsername: newUsername,
		});
	};

	svc.logout = function (user) {
		return $http
			.post('/api/users/' + user._id + '/logout', {
				pdf: user.pdfName,
				company: user.company.number,
			})
			.then(function () {
				//window.localStorage.clear();
				$http.defaults.headers.common['X-Auth'] = '';
			});
	};

	svc.createUser = function (company, user, paper, captchaToken) {
		var serialnumber = null;
		if (user.sn) {
			serialnumber =
				user.sn[0] + '-' + user.sn[1] + '-' + user.sn[2] + '-' + user.sn[3];
			serialnumber = serialnumber.toUpperCase();
		}
		return $http
			.post(
				'/api/users',
				{
					...(user.sn
						? {
							company: user.company,
							sn: serialnumber,
							email: user.email,
							username: user.username,
							password: user.password,
							firstName: user.firstName,
							lastName: user.lastName,
							paperDefault: paper ? paper : user.company.paperDefault,
						}
						: {
							company: user.company,
							email: user.email,
							username: user.username,
							password: user.password,
							firstName: user.firstName,
							lastName: user.lastName,
							paperDefault: paper ? paper : user.company.paperDefault,
						}),
					captchaToken
				}
			);
	};

	svc.deleteUser = function (company, user) {
		return $http.delete('/api/users/company/' + company + '/user/' + user._id);
	};

	svc.executeQuestion = function (question) {
		return $http.post(
			'/api/users/' + question.data._id + '/' + question.action,
			question.data
		);
	};

	svc.getUserById = function (company, user) {
		return $http.get('/api/users/company/' + company + '/user/' + user);
	};

	svc.getStats = function () {
		return $http.get('/api/users/stats');
	};

	svc.createUserFromAdmin = function (user) {
		return $http.post('/api/users/', user);
	};

	svc.updateUserFromAdmin = function (company, user) {
		return $http.post('/api/users/' + user._id + '/update', {
			user: user,
			company: company,
		});
	};

	svc.getUsersByCompany = function (company) {
		return $http.get('/api/users/company/' + company);
	};

	svc.saveOffset = function (user, leftOffset, topOffset, name) {
		return $http.post('/api/users/' + user + '/offset', {
			name: name,
			leftOffset: leftOffset,
			topOffset: topOffset,
		});
	};
});

//