/**
 * Angular Admin - User Controller
 * @file ng/controllers/admin/admin.user.ctrl.js
 * @class angular.controllers.AdminUserCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'AdminUserCtrl',
		function (
			$scope,
			$location,
			$stateParams,
			AppSvc,
			CompanySvc,
			UserSvc,
			TemplateSvc
		) {
			if (!AppSvc.user || !(AppSvc.user.admin || AppSvc.user.options)) {
				$location.path(AppSvc.getURL());
			}

			$scope.displayRatio = 0.4;
			$scope.isNew = true;
			$scope.search = {
				badge: {
					code: '',
				},
			};
			var today = new Date();

			function getUserTemplates() {
				TemplateSvc.getTemplatesByUser($scope.selectedUser._id).then(function (
					response
				) {
					$scope.userTemplates = response;
				});
			}

			if ($stateParams.user) {
				$scope.isNew = false;
				UserSvc.getUserById($stateParams.company, $stateParams.user).then(
					function (response) {
						$scope.selectedUser = response.data;
						var birthday = new Date(response.data.birthDate);
						$scope.selectedUser.birthDate = birthday.toLocaleDateString();
						CompanySvc.getCompany($stateParams.company).then(function (
							response
						) {
							$scope.company = response.data;
							getUserTemplates();
						});
					}
				);
			}

			$scope.user = function () {
				if (!$scope.selectedUser) return {};
				return $scope.selectedUser;
			};

			CompanySvc.getCompany($stateParams.company).then(function (response) {
				$scope.company = response.data;
				if ($scope.isNew) {
					$scope.selectedUser = {
						birthDate: today.toLocaleDateString(),
						paperDefault: $scope.company.paperDefault,
						admin: false,
						options: false,
						company: $scope.company._id,
					};
				}
			});

			$scope.selectLanguage = function (language) {
				$scope.selectedUser.language = language.id;
			};

			$scope.languages = function () {
				return AppSvc.languages;
			};

			$scope.getLanguageName = function () {
				if (!$scope.selectedUser) {
					return 'English';
				}
				for (var i in AppSvc.languages) {
					if (AppSvc.languages[i].id === $scope.selectedUser.language) {
						return AppSvc.languages[i].name;
					}
				}
			};

			$scope.updateOrCreate = function (user) {
				CompanySvc.getCompany($stateParams.company).then(function (response) {
					var validationCode = response.data.validationCode;
					var companyNumber = response.data.number;
					if (!validationCode || !user.username || !user.email) {
						showIncompleteFieldsMessage();
					} else if (
						$scope.isNew &&
						(!user.password || !user.confirmPassword)
					) {
						showIncompleteFieldsMessage();
					} else if (
						!$scope.isNew &&
						user.editPassword &&
						(!user.password || !user.confirmPassword)
					) {
						showIncompleteFieldsMessage();
					} else {
						if ($scope.patternUsername.test(user.username)) {
							var skipPasswordValidation = !$scope.isNew && !user.editPassword;
							if (
								skipPasswordValidation ||
								$scope.patternPassword.test(user.password)
							) {
								if (
									skipPasswordValidation ||
									user.password === user.confirmPassword
								) {
									if ($scope.isNew) {
										user.company = $scope.company;
										UserSvc.createUserFromAdmin(user).then(
											function (response) {
												$scope.$emit('popup', {
													message: $scope.getCopy('messageUserUpdated'),
													type: 'alert-success',
												});
											},
											function (response) {
												showAfterSubmitErrorMessages(
													response,
													user.username,
													user.email
												);
											}
										);
									} else {
										UserSvc.updateUserFromAdmin(
											AppSvc.user.company.number,
											user
										).then(
											function (response) {
												$scope.$emit('popup', {
													message: $scope.getCopy('messageUserUpdated'),
													type: 'alert-success',
												});
											},
											function (response) {
												showAfterSubmitErrorMessages(
													response,
													user.username,
													user.email
												);
											}
										);
									}
								} else {
									var originalBg = $('.password').css('backgroundColor');
									$('.password')
										.animate(
											{
												backgroundColor: '#FFB6C1',
											},
											200
										)
										.animate(
											{
												backgroundColor: originalBg,
											},
											200
										);
								}
							} else {
								$scope.$emit('popup', {
									message: 'Password is invalid',
									type: 'alert-danger',
								});
							}
						} else {
							if (user.username.length < 6) {
								$scope.$emit('popup', {
									message: user.username + ' is too short',
									type: 'alert-danger',
								});
							} else if (user.username.length > 20) {
								$scope.$emit('popup', {
									message: user.username + ' is too long',
									type: 'alert-danger',
								});
							} else {
								$scope.selectedUsername = user.username.replace(
									/[^a-zA-Z\-0-9._@]/g,
									''
								);
								$scope.$emit('popup', {
									message: user.username + ' contains invalid characters',
									type: 'alert-danger',
								});
							}
						}
					}
				});
			};

			function showIncompleteFieldsMessage() {
				$scope.$emit('popup', {
					message: $scope.getCopy('messageCompleteFields'),
					type: 'alert-danger',
				});
			}

			function showAfterSubmitErrorMessages(response, username, email) {
				var message = '';
				switch (response.statusText) {
					case 'name':
						message = username + ' ' + $scope.getCopy('taken');
						break;
					case 'email':
						message = email + $scope.getCopy('alreadyRegistered');
						break;
					case 'limit':
						message = $scope.getCopy('userLimitReached');
						break;
				}
				if (message) {
					$scope.$emit('popup', {
						message: message,
						type: 'alert-danger',
					});
				} else {
					console.error(response);
					$scope.$emit('popup', {
						message: 'Check F12 Console',
						type: 'alert-danger',
					});
				}
			}

			$scope.cloneTemplate = function (template) {
				TemplateSvc.cloneTemplate(template, $scope.selectedUser)
					.success(function (response) {
						getUserTemplates();
						if (response._id) {
							$scope.$emit('popup', {
								message: $scope.getCopy('saved'),
								type: 'alert-success',
							});
						}
					})
					.error(function (error) {
						console.log(error);
					});
			};

			$scope.deleteTemplate = function (template) {
				//TODO not delete myself
				var question = {
					title: $scope.getCopy('delete') + ' - ' + template.name,
					question:
						$scope.getCopy('deleteTemplate') +
						' ' +
						$scope.getCopy('messageActionIrreversible'),
					model: 'template',
					api: 'templates',
					action: 'delete',
					data: template,
					exec: function () {
						TemplateSvc.deleteTemplate(question.data).then(function (response) {
							$scope.$emit('questionExecuted', response, question);
							getUserTemplates();
						});
					},
				};
				$scope.askQueryQuestion(question);
			};

			$scope.setDefaultTemplate = function (template) {
				$scope.selectedUser.templateDefault = template._id;
				UserSvc.updateUser($scope.selectedUser);
			};

			$scope.assignTemplate = function (template) {
				TemplateSvc.assignTemplate(template, $scope.company._id).then(
					templates => {
						console.log(templates);

						getUserTemplates();
						$scope.$emit('popup', {
							message: `Template assigned to ${$scope.company.name}`,
							type: 'alert-success',
						});
					},
					error => {
						console.log(error);
						$scope.$emit('popup', {
							message: 'Error while assigning template to ' + company.name,
							type: 'alert-danger',
						});
					}
				);
			};

			$scope.getBadges = function () {
				if (!$scope.userTemplates) return [];
				return _.uniq(
					$scope.userTemplates.map(function (template) {
						return template.badge;
					}),
					function (badge) {
						return badge.code;
					}
				).sort(function (a, b) {
					return a.code < b.code ? -1 : 1;
				});
			};

			$scope.getBadgeName = function (badge) {
				if (!badge) return '';
				var index = _.findIndex(badge.copy, {
					language: $scope.selectedLanguage,
				});
				if (index >= 0) {
					index = _.findIndex(badge.copy, { language: 'EN' });
				}
				return !badge ? '' : badge.copy[index].name;
			};
		}
	);
