/**
 * Angular Admin - Templates Controller
 * @file ng/controllers/admin/admin.template.ctrl.js
 * @class angular.controllers.AdminTemplateCtrl
 * @memberOf angular.controllers
 */
angular
  .module('app')
  .controller(
    'AdminTemplateCtrl',
    function (
      $scope,
      $stateParams,
      $state,
      $location,
      AppSvc,
      CompanySvc,
      UserSvc,
      TemplateSvc,
      BadgeSvc,
      FileSvc,
      ConstantsSvc,
      CategorySvc
    ) {
      if (!AppSvc.user || !(AppSvc.user.admin || AppSvc.user.options)) {
        $location.path(AppSvc.getURL());
      }
      $scope.id = 'admin';

      $scope.editing = true;
      $scope.altFonts = ConstantsSvc.altFonts;
      $scope.fontTypes = ConstantsSvc.fontTypes;
      $scope.backgroundTypes = ConstantsSvc.backgroundTypes;
      $scope.displayRatio = 1;

      $scope.$on('init', function () {
        //$scope.getTemplates();
        //$scope.getCompanies();
      });
      $scope.selectedCompany = $stateParams.company;

      $scope.$on('selectTemplate', function (event, data) {
        init(data);
      });
      /*
			if (!$scope.templates && !$scope.$parent.templates) {
				TemplateSvc.getTemplatesByCompany($stateParams.company).then(function (
					templates
				) {
					$scope.templates = templates;
				});
			}

			$scope.getTemplates = () =>
				$scope.templates && $scope.selectedTemplate
					? $scope.templates
							.filter(
								template =>
									template.badge.code == $scope.selectedTemplate.badge.code
							)
							.slice(0, 30)
					: [];
*/
      //Loads the available fonts
      const loadFonts = () => {
        const uniqueFonts = _.uniq(TemplateSvc.loadedFonts, (font) => font.fontName).map(
          (font) => font.fontName
        );
        $scope.fonts = uniqueFonts.map((font) => {
          const filteredFonts = TemplateSvc.loadedFonts.filter(
            (loadedFont) => loadedFont.fontName === font
          );
          return {
            fontName: font,
            companies: filteredFonts,
            global: _.some(filteredFonts, (loadedFont) => loadedFont.fontCompany === ''),
          };
        });
      };

      $scope.getAlignmentName = function (option) {
        return ConstantsSvc.getAlignmentName(option);
      };

      $scope.getWeightName = function (option) {
        return ConstantsSvc.getWeightName(option);
      };

      $scope.getStyleName = function (option) {
        return ConstantsSvc.getStyleName(option);
      };

      $scope.getCaseName = function (option) {
        return ConstantsSvc.getCaseName(option);
      };

      $scope.getTypeName = function (option) {
        return ConstantsSvc.getTypeName(option);
      };

      loadFonts();
      const init = (template) => {
        CompanySvc.getCompany($stateParams.company).then(function (response) {
          $scope.company = response.data;
          $scope.categories = $scope.company.categories;
          if (!$stateParams.template && !template) {
            BadgeSvc.getBadges().then(function (response) {
              $scope.badges = response.data.map(function (badge) {
                badge.name = badge.code + ' - ' + badge.shape + ' - ' + badge.description;
                return badge;
              });
              let defaultBadge =
                _.find($scope.badges, (badge) => badge.code === 'MD') || $scope.badges[0];
              //new template
              $scope.selectedTemplate = {
                badge: defaultBadge,
                width: defaultBadge.width,
                height: defaultBadge.height,
                company: $scope.company._id,
                category: '',
                type: 'company',
                name: 'New Template',
                creator: AppSvc.user._id,
                background: 'white',
                printDirection: true,
                enabled: true,
                categories: [],
                textboxes: [
                  {
                    _id: ObjectId().toString(),
                    placeholder: 'Name',
                    height: Math.round(defaultBadge.height * 0.5 * 10000) / 10000,
                    width: Math.round(defaultBadge.width * 0.8 * 10000) / 10000,
                    topOffset: Math.round(defaultBadge.height * 0.25 * 10000) / 10000,
                    leftOffset: Math.round(defaultBadge.width * 0.1 * 10000) / 10000,
                    print: true,
                    case: 0,
                    editable: true,
                    fontName: 'Roboto',
                    fontSize: Math.round(defaultBadge.height * 18),
                    fontColor: '#000000',
                    fontWeight: 0,
                    fontStyle: 0,
                    alignment: 1,
                    isEmptyAllowed: false,
                    isDropdown: false,
                    index: 1,
                    isLengthFixed: false,
                  },
                ],
                images: [],
              };
              if ($stateParams.company && $stateParams.user) {
                UserSvc.getUserById($stateParams.company, $stateParams.user).then(function (response) {
                  $scope.selectedUser = response.data;
                  $scope.selectedTemplate.type = 'user';
                  $scope.selectedTemplate.user = $scope.selectedUser;
                });
              }
            });
          } else {
            $scope.selectTemplate($stateParams.template || template);
          }
        });
      };

      init();

      $scope.selectTemplate = function (template) {
        TemplateSvc.getTemplate(template)
          .then(function (response) {
            $scope.selectedTemplate = response.data;
            if ($scope.selectedTemplate.printDirection) {
              $scope.selectedTemplate.width = $scope.selectedTemplate.badge.width;
              $scope.selectedTemplate.height = $scope.selectedTemplate.badge.height;
            } else {
              $scope.selectedTemplate.width = $scope.selectedTemplate.badge.height;
              $scope.selectedTemplate.height = $scope.selectedTemplate.badge.width;
            }
            setSubcategories();
          })
          .then(function () {
            BadgeSvc.getBadges().then(function (response) {
              $scope.badges = _.uniq(
                response.data.map(function (badge) {
                  badge.name = badge.code + ' - ' + badge.shape + ' - ' + badge.description;
                  return badge;
                }),
                function (badge) {
                  return badge.name;
                }
              );
              if ($stateParams.user || template.type === 'user') {
                UserSvc.getUser($stateParams.user || template.user._id).then(function (response) {
                  $scope.selectedUser = response.data;
                });
              }
            });
          });
      };

      FileSvc.getCompanyImages($stateParams.company, '').then(function (response) {
        $scope.companyImages = response.data;
      });

      //Necessary to use the template.html component to load the preview badge
      $scope.getTemplate = function () {
        return [$scope.selectedTemplate];
      };

      $scope.getCanvasStyle = function (template, modifier) {
        if (!template) return {};
        var canvasWidth = $scope.getWidth(template.width, template.height);
        return {
          width:
            ((template.width + (template.printDirection ? 0.5 : 0)) * canvasWidth) / template.width,
          height:
            (canvasWidth / template.width) *
            (template.height + (template.printDirection ? 0 : 0.5)),
          paddingTop: template.printDirection ? 0 : (0.25 * canvasWidth) / template.width,
        };
        /*
			if (template) {
			  return TemplateSvc.getCanvasStyle(template, $scope.getWidth(), modifier, true);
			}
			*/
      };

      $scope.getTemplateStyle = function (template, modifier) {
        if (template) {
          return TemplateSvc.getTemplateStyle(
            template,
            $scope.getWidth(template.width, template.height),
            modifier,
            'visible'
          );
        }
      };

      $scope.getShapeStyle = (template) =>
        template
          ? {
              ...BadgeSvc.getShapeStyle(template.badge, template.background),
              opacity: template.badge.shape === 'Special' ? 0.7 : 1,
            }
          : {};

      //Select an object on the template (image, textbox or resizer)
      $scope.selectObject = function (type, object) {
        if (type !== 'resizer') {
          $scope.selectingObject = true;
          $scope.selectedObjectType = type;
          $scope.selectedObjectIndex = object
            ? _.findIndex(
                type === 'tbx' ? $scope.selectedTemplate.textboxes : $scope.selectedTemplate.images,
                ({ _id }) => object._id === _id
              )
            : -1;
        }
      };

      $scope.changeSelectedObject = function (attributes) {
        if (!$scope.selectedTemplate || $scope.selectedObjectType === 'tmplt') return;
        if ($scope.selectedObjectType === 'tbx')
          $scope.selectedTemplate.textboxes[$scope.selectedObjectIndex] = {
            ...$scope.selectedTemplate.textboxes[$scope.selectedObjectIndex],
            ...attributes,
          };
        else
          $scope.selectedTemplate.images[$scope.selectedObjectIndex] = {
            ...$scope.selectedTemplate.images[$scope.selectedObjectIndex],
            ...attributes,
          };
      };

      $scope.selectedObject = function () {
        if (!$scope.selectedTemplate) return {};
        if ($scope.selectedObjectType !== 'tmplt') {
          return $scope.selectedObjectType === 'tbx'
            ? $scope.selectedTemplate.textboxes[$scope.selectedObjectIndex]
            : $scope.selectedTemplate.images[$scope.selectedObjectIndex];
        } else {
          return $scope.selectedTemplate;
        }
      };

      $scope.toggleFontModal = function (textbox) {
        $scope.selectObject('tbx', textbox);
        $('#font-modal').modal();
      };

      $scope.selectFont = function (font) {
        $scope.changeSelectedObject({
          fontName: font.fontName,
          fontLocation: font.fontLocation,
        });
        $('#font-modal').modal('hide');
      };

      $scope.addTextbox = function () {
        $scope.selectedTemplate.textboxes.push({
          _id: ObjectId().toString(),
          placeholder: 'Name',
          height: 0.3,
          width: 1.0,
          topOffset: 0.1,
          leftOffset: 0.1,
          case: 0,
          editable: true,
          fontName: 'Roboto',
          fontLocation: 'global',
          fontSize: 12,
          fontWeight: 0,
          fontStyle: 0,
          fontColor: '#000000',
          alignment: 0,
          rotation: 0,
          print: true,
          isEmptyAllowed: false,
          isDropdown: false,
          dropdownItems: [],
          index: addLayer(),
        });
        $scope.selectObject(
          'tbx',
          $scope.selectedTemplate.textboxes[$scope.selectedTemplate.textboxes.length - 1]
        );
      };

      $scope.addImage = function () {
        $scope.selectedTemplate.images.push({
          _id: ObjectId().toString(),
          name: 'logo.png',
          type: 'company',
          height: 0.5,
          width: 0.5,
          topOffset: 0.1,
          leftOffset: 0.1,
          rotation: 0,
          print: true,
          keepAspectRatio: true,
          editable: false,
          isBackground: false,
          isDropdown: false,
          dropdownFolder: {
            name: 'flags',
            dir: 'flags',
            isCurated: true,
          },
          index: addLayer(),
        });
        $scope.selectObject(
          'img',
          $scope.selectedTemplate.images[$scope.selectedTemplate.images.length - 1]
        );
      };

      $scope.cloneObject = function (type, object) {
        var newObject = JSON.parse(JSON.stringify(object));
        newObject.topOffset += 0.05;
        newObject.leftOffset += 0.05;
        newObject._id = ObjectId().toString();
        $scope.selectedTemplate[type === 'tbx' ? 'textboxes' : 'images'].push(newObject);
        $scope.selectObject(
          type,
          $scope.selectedTemplate[type === 'tbx' ? 'textboxes' : 'images'][
            $scope.selectedTemplate[type === 'tbx' ? 'textboxes' : 'images'].length - 1
          ]
        );
      };

      //Delete an object from the template
      $scope.deleteObject = function (object) {
        $scope.selectObject('tmplt', $scope.selectedTemplate);
        if (object.placeholder) {
          $scope.selectedTemplate.textboxes.splice(
            _.findIndex($scope.selectedTemplate.textboxes, function (textbox) {
              return textbox._id === object._id;
            }),
            1
          );
        } else {
          $scope.selectedTemplate.images.splice(
            _.findIndex($scope.selectedTemplate.images, function (image) {
              return image._id === object._id;
            }),
            1
          );
        }
        removeLayer(object);
      };

      $scope.getImageSrc = function (image) {
        if (!image || !image.image) {
          return;
        }
        if (image.image.image) {
          return `${image.image.image.location}/${image.image.image._id}`;
        } else {
          return `${image.image.location}/${image.image._id}`;
        }
      };

      $scope.centerImage = function (object) {
        object.leftOffset =
            Math.round(
                ($scope.selectedTemplate.width / 2 - object.width / 2) *
                10000
            ) / 10000;
        object.topOffset =
            Math.round(
                ($scope.selectedTemplate.height / 2 - object.height / 2) *
                10000
            ) / 10000;
      };

      $scope.saveTemplate = function () {
        $scope.saving = true;
        console.log($scope.selectedTemplate);
        TemplateSvc.saveTemplate($scope.selectedTemplate, true).then(
          function (response) {
            if (response._id && (['admintemplate', 'adminusertemplate'].includes($state.current.name))) {
              $scope.$emit('popup', {
                message: $scope.getCopy('saved'),
                type: 'alert-success',
              });
              $state.transitionTo(
                'admintemplate',
                {
                  template: response._id,
                  company: $stateParams.company,
                },
                { notify: false }
              );
            } else {
              $scope.$emit('init');
              //$('#template-edit-modal').modal('hide');
            }
            $scope.saving = false;
          },
          function (error) {
            console.log(error);
            $scope.$emit('popup', {
              message: error.message,
              type: 'alert-danger',
            });
            $scope.saving = false;
          }
        );
      };

      $scope.cloneTemplate = function () {
        $scope.selectedTemplate.name += ' - New';
        TemplateSvc.cloneTemplate($scope.selectedTemplate, AppSvc.user).then(
          function (response) {
            if (response.data._id && $stateParams.template) {
              $state.go('admintemplate', {
                template: response.data._id,
                company: $scope.selectedTemplate.company.number,
              });
              $scope.$emit('popup', {
                message: $scope.getCopy('saved'),
                type: 'alert-success',
              });
            } else {
              $scope.$parent.$parent.$parent.$broadcast('selectTemplate', response.data._id);
            }
          },
          function (error) {
            console.log(error);
            $scope.$emit('popup', {
              message: error.message,
              type: 'alert-danger',
            });
          }
        );
      };

      $scope.imageUpload = function (object) {
        var index = this.$index;
        var file = object.files[0];
        FileSvc.uploadFileToCompany(file, $stateParams.company).then(function (response) {
          FileSvc.getCompanyImages($stateParams.company, '').then(function (response) {
            $scope.files = response.data;
          });
        });
      };

      $scope.isOptionSelected = function (companyOption) {
        if (!$scope.selectedTemplate) return false;
        return _.findWhere($scope.selectedTemplate.options, {
          name: companyOption.name,
        });
      };

      $scope.toggleOption = function (companyOption) {
        if (!$scope.selectedTemplate) return;
        for (var i = 0; i < $scope.selectedTemplate.options.length; i++) {
          if (companyOption.name === $scope.selectedTemplate.options[i].name) {
            $scope.selectedTemplate.options.splice(i, 1);
            return;
          }
        }
        $scope.selectedTemplate.options.unshift({
          name: companyOption.name,
          values: [],
        });
      };

      $scope.isValueSelected = function (companyOption, companyValue) {
        if (!$scope.selectedTemplate) return false;
        var templateOption = _.findWhere($scope.selectedTemplate.options, {
          name: companyOption.name,
        });
        if (!templateOption) return false;
        return _.findWhere(templateOption.values, { name: companyValue.name });
      };

      $scope.toggleValue = function (companyOption, companyValue) {
        if (!$scope.selectedTemplate) return;
        var templateOption = _.findWhere($scope.selectedTemplate.options, {
          name: companyOption.name,
        });
        if (!templateOption) return;
        var templateValue = _.findWhere(templateOption.values, {
          name: companyValue.name,
        });
        if (templateValue) {
          for (var i = 0; i < templateOption.values.length; i++) {
            if (templateValue.name === templateOption.values[i].name) {
              templateOption.values.splice(i, 1);
              break;
            }
          }
        } else {
          templateOption.values.unshift(companyValue);
        }
      };

      $scope.getSelectedOptionValues = function (companyOption) {
        var option = _.find($scope.selectedTemplate.options, function (templateOption) {
          return companyOption.name === templateOption.name;
        });
        return option.values;
      };

      //Resize function, works in conjunction with ng/controllers/components/objectresizers.ctrl.js
      //I had to put these functions here because using a resizer automatically selected the template after resizing -> very annoying
      $scope.startResizeObject = function () {
        $scope.resizingObject = true;
        //objectBeingResized = $scope.selectedObject;
        //objectTypeBeingResized = $scope.selectedObjectType;
        $scope.changeSelectedObject({
          originalDragDimensions: {
            top: $(
              '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
            ).position().top,
            left: $(
              '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
            ).position().left,
            width: $(
              '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
            ).width(),
            height:
              $(
                '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
              ).height() + 2,
          },
        });
      };

      $scope.stopResizeObject = function () {
        //$scope.selectObject(objectTypeBeingResized, objectBeingResized);
        TemplateSvc.moveObject(
          $scope.selectedTemplate,
          $scope.selectedObjectType,
          $scope.selectedObject()
        );
        $scope.changeSelectedObject({
          originalSize: {
            width: $scope.selectedObject().width,
            height: $scope.selectedObject().height,
          },
        });
        $scope.resizingObject = false;
        $scope.$apply();
      };

      $scope.toggleImageModal = function () {
        if (!$scope.files || $scope.files.length === 0) {
          $scope
            .getFiles(
              $scope.selectedObject().isDropdown ? $scope.selectedObject().dropdownFolder : ''
            )
            .then((response) => {
              $scope.files = response.data;
              console.log($scope.files);
              if ($scope.selectedObject().isDropdown) {
                $scope.selectedDropdownFolder = $scope.selectedObject().dropdownFolder;
              }
              $scope.toggleModal('image-select-modal');
            });
        } else {
          if ($scope.selectedObject().isDropdown) {
            $scope.selectedDropdownFolder = $scope.selectedObject().dropdownFolder;
          }
          $scope.toggleModal('image-select-modal');
        }
      };

      //Get all the files for the company
      $scope.getFiles = (dir) => FileSvc.getCompanyImages($stateParams.company, dir);

      //Get all the files for the company
      function getImageFolders() {
        return FileSvc.getImageFolders($stateParams.company).then(function (response) {
          $scope.folders = response.data;
        });
      }
      getImageFolders();
      //Return all the objects on the template
      $scope.getObjects = function () {
        if (!$scope.selectedTemplate) return [];
        return $scope.selectedTemplate.textboxes
          .concat($scope.selectedTemplate.images)
          .sort(function (a, b) {
            return a.index < b.index ? 1 : -1;
          });
      };

      $scope.templateBackground = function () {
        if (!$scope.selectedTemplate) return undefined;
        var background = _.find($scope.selectedTemplate.images, function (image) {
          return image.isBackground;
        });
        return background;
      };

      function addLayer() {
        var background = $scope.templateBackground();
        $scope.getObjects().forEach(function (obj) {
          if (!background || background.index !== obj.index) {
            obj.index++;
          }
        });
        if (background) {
          return 2;
        } else {
          return 1;
        }
      }

      function removeLayer(object) {
        var oldIndex = object.index;
        $scope.getObjects().forEach(function (obj) {
          if (obj.index > oldIndex) {
            obj.index--;
          }
        });
      }

      const setSubcategories = () => {
        $scope.subcategories = $scope.selectedTemplate
          ? CategorySvc.getSubcategories($scope.company, $scope.selectedTemplate)
          : [];
      };

      $scope.getCategoryNames = () =>
        $scope.selectedTemplate
          ? CategorySvc.getCategoryNames($scope.company, $scope.selectedTemplate)
          : 'None';
      $scope.getSubcategoryNames = () =>
        $scope.subcategories
          ? CategorySvc.getSubcategoryNames($scope.selectedTemplate, $scope.subcategories)
          : 'None';
      $scope.isCategorySelected = (category) =>
        CategorySvc.isCategorySelected($scope.selectedTemplate, category);
      $scope.isSubcategorySelected = (subcategory) =>
        CategorySvc.isSubcategorySelected($scope.selectedTemplate, subcategory);

      $scope.toggleCategory = (category, e) => {
        CategorySvc.toggleCategory($scope.selectedTemplate, category);
        setSubcategories();
        e.stopPropagation();
      };

      $scope.toggleSubcategory = (subcategory, e) => {
        CategorySvc.toggleSubcategory($scope.selectedTemplate, subcategory);
        e.stopPropagation();
      };

      //Triggered when another badge size is chosen in design and keeps the aspect of all the objects
      $scope.setBadge = function (badge) {
        TemplateSvc.setBadge($scope.selectedTemplate, badge);
      };

      $scope.setPrintDirection = function () {
        $scope.selectedTemplate.printDirection = !$scope.selectedTemplate.printDirection;
        var width, height;
        if ($scope.selectedTemplate.printDirection) {
          width = $scope.selectedTemplate.badge.width;
          height = $scope.selectedTemplate.badge.height;
        } else {
          width = $scope.selectedTemplate.badge.height;
          height = $scope.selectedTemplate.badge.width;
        }
        $scope.selectedTemplate.textboxes.forEach(function (textbox) {
          textbox.width =
            Math.round(
              ((textbox.width * $scope.selectedTemplate.height) / $scope.selectedTemplate.width) *
                10000
            ) / 10000;
          textbox.height =
            Math.round(
              ((textbox.height * $scope.selectedTemplate.width) / $scope.selectedTemplate.height) *
                10000
            ) / 10000;
          textbox.leftOffset =
            Math.round(
              ((textbox.leftOffset * $scope.selectedTemplate.height) /
                $scope.selectedTemplate.width) *
                10000
            ) / 10000;
          textbox.topOffset =
            Math.round(
              ((textbox.topOffset * $scope.selectedTemplate.width) /
                $scope.selectedTemplate.height) *
                10000
            ) / 10000;
          textbox.fontSize = Math.round(
            (textbox.fontSize * $scope.selectedTemplate.height) / $scope.selectedTemplate.width
          );
          textbox.defaultFontSize = textbox.fontSize;
        });
        $scope.selectedTemplate.images.forEach(function (image) {
          image.width =
            Math.round(
              ((image.width * $scope.selectedTemplate.height) / $scope.selectedTemplate.width) * 10000
            ) / 10000;
          image.height =
            Math.round(
              ((image.height * $scope.selectedTemplate.width) / $scope.selectedTemplate.height) *
                10000
            ) / 10000;
          image.leftOffset =
            Math.round(
              ((image.leftOffset * $scope.selectedTemplate.height) /
                $scope.selectedTemplate.width) *
                10000
            ) / 10000;
          image.topOffset =
            Math.round(
              ((image.topOffset * $scope.selectedTemplate.width) / $scope.selectedTemplate.height) *
                10000
            ) / 10000;
        });
        $scope.selectedTemplate.width = width;
        $scope.selectedTemplate.height = height;
      };

      $scope.do = function () {};

      $scope.setCuratedDropdown = (name) => {
        $scope.selectedObject().dropdownItems = ConstantsSvc.curatedDropdowns[name];
      };
    }
  );
