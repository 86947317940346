/**
* Angular Reset Controller
* @file ng/controllers/reset.ctrl.js
* @class angular.controllers.ResetCtrl
* @memberOf angular.controllers
*/
angular.module('app')
.controller('ResetCtrl', function ($scope, $location, $stateParams, UserSvc) {

  $scope.resetPassword = function (password, confirmPassword) {
    //Check if passwords match
    if (password === confirmPassword) {
      //Send token in URL with new password
      UserSvc.resetPassword($stateParams.token, password)
      .then(function (response) {
        //Code 200
        $scope.$emit('popup', {
          message: $scope.getCopy('messageChangedPassword'),
          type: 'alert-success'
        });
      }, function () {
        //Code 401
        $scope.$emit('popup', {
          message: $scope.getCopy('messageTokenExpired'),
          type: 'alert-danger'
        });
      });
      //Go back to Login page to either resend the reset password token or login using the new password
      $location.path('/login');
    } else {
      var originalBg = $(".password").css("backgroundColor");
      $(".password").animate({ backgroundColor: "#FFB6C1" }, 200).animate({ backgroundColor: originalBg }, 200);
    }
  };

});
