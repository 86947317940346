angular
  .module('app')
  .controller(
    'TemplatesCtrl',
    function (
      $state,
      $stateParams,
      $location,
      $rootScope,
      $scope,
      AppSvc,
      TemplateSvc,
      BadgeSvc,
      CategorySvc
    ) {
      $scope.displayRatio = 0.8;

      //Returns whether the category should be visible
      $scope.getCategoryVisible = function (category) {
        return true;
        /*
    if (!$scope.filteredTemplates) return;
    return _.find($scope.filteredTemplates, function (template) {
      return (template.user ? AppSvc.getCopy('myTemplates') : (template.category ? template.category : AppSvc.getCopy('other'))) === category;
    });
    */
      };

      /**
       * @ngdoc function
       * @name categories
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Function that returns all the categories of templates
       * @returns {String[]} direction Direction of the arrow
       */
      $scope.categories = () => {
        if (!CategorySvc.categories) { return []; }

        let categories = CategorySvc.categories.sort((a, b) =>
            a.name && b.name ?
                a.name.toUpperCase() < b.name.toUpperCase() ?
                    -1: 1
                : 1
        );

        // Move myTemplates and other to the last of the array
        if (categories.map(e => e._id).indexOf('myTemplates') > -1) {
          categories.push(categories.splice(categories.map(e => e._id).indexOf('myTemplates'), 1)[0]);
        }
        if (categories.map(e => e._id).indexOf('other') > -1) {
          categories.push(categories.splice(categories.map(e => e._id).indexOf('other'), 1)[0]);
        }

        return categories;
      };

      $scope.subcategoriesLength = () => {
        let categories = $scope.categories();
        if (categories) {
          return categories.map(c => c.subcategories).flat(1).length;
        } else {
          return 0;
        }
      };

      BadgeSvc.getBadges().then(function (response) {
        $scope.badges = response.data.filter(function (badge) {
          if (AppSvc.user && AppSvc.user.company.sizes) {
            return _.some(AppSvc.user.company.sizes, function (companySize) {
              return companySize === badge.code;
            });
          } else {
            return true;
          }
        });
      });

      /**
       * @ngdoc function
       * @name initTemplates
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Initializes all categories, templates and options
       */
      var initTemplates = new Promise(function (resolve, reject) {
        $scope.templatesFilter = AppSvc.user.company.templateOptions;
        $scope.templateHeader = AppSvc.user.company.templateHeader;
        //Get the templates for the user's company
        TemplateSvc.getTemplatesByCompanyAndUser(AppSvc.user.company.number, AppSvc.user._id).then(
          function (templates) {
            TemplateSvc.enteredData = TemplateSvc.enteredData.reverse();
            if (templates) {
              $scope.templates = TemplateSvc.templates;

              //This ensures that when you log in as another company, the template displayed in Data Entry will refresh
              var found = _.some($scope.templates, function (template) {
                return (
                  TemplateSvc.selectedTemplate && template._id === TemplateSvc.selectedTemplate._id
                );
              });

              //if there is no saved selected templated in the TemplateSvc, select a specific one
              if (!TemplateSvc.selectedTemplate || (!found && TemplateSvc.selectedTemplate)) {
                var defaultUserTemplate = _.find($scope.templates, function (template) {
                  return template._id === AppSvc.user.templateDefault;
                });
                var defaultCompanyTemplate = _.find($scope.templates, function (template) {
                  return template._id === AppSvc.user.company.templateDefault;
                });
                //if there's no default template, select the first one
                setSelectedTemplate(
                  defaultUserTemplate || defaultCompanyTemplate || $scope.templates[0]
                );
                if (defaultUserTemplate) {
                  $state.go('dataentry', {
                    template: defaultUserTemplate,
                    edit: false,
                  });
                }
              } else {
                //If there is already a template that was selected previously, selected that one
                setSelectedTemplate(TemplateSvc.selectedTemplate);
              }
              if (TemplateSvc.selectedTemplate) {
                //Below sets initial values in the template options if there are any
                $scope.templatesFilter.forEach(function (filterOption) {
                  var templateOption = _.find(
                    TemplateSvc.selectedTemplate.options,
                    function (templateOption) {
                      return filterOption.name === templateOption.name;
                    }
                  );
                  $scope.filterTemplates(
                    filterOption,
                    templateOption ? templateOption.values[0] : filterOption.values[0]
                  );
                });
                $scope.selectCategory();
              }
              refresh();
            } else {
              //Go to home page if no templates are found
              //TODO: Some smarter handling to deal with this
              //Note: this has never been an issue as of June 2017
              $scope.$emit('popup', {
                message: 'Unable to find templates for ' + AppSvc.user.company.name,
                type: 'alert-danger',
              });
              $location.path('/');
            }
          }
        );
      });

      /**
       * @ngdoc function
       * @name filterTemplates
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Sets the filters for the visible templates based on the options chosen by the user
       * @param {Object} option Option
       * @param {String} option.name Name of the option i.e. 'Country'
       * @param {String} option.type Type of the option i.e. 'combobox', 'dropdown', 'radio'
       * @param {Object[]} option.values Array of the values i.e. [{name: 'Canada'}, {name: 'USA'}]
       * @param {Object} value Value
       * @param {String} value.name Name of the value i.e. 'Canada'
       */
      $scope.filterTemplates = function (option, value) {
        var changedValue = _.find(
          _.find($scope.templatesFilter, function (filterOption) {
            return filterOption.name === option.name;
          }).values,
          function (filterOptionValue) {
            return filterOptionValue.name === value.name;
          }
        );
        if (option.type === 'dropdown' || option.type === 'radio') {
          option.selectedValue = value.name;
          _.find($scope.templatesFilter, function (filterOption) {
            return filterOption.name === option.name;
          }).values.forEach(function (filterOptionValue) {
            filterOptionValue.selected = false;
          });
          changedValue.selected = true;
        } else if (option.type === 'combobox') {
          changedValue.selected = !changedValue.selected;
        }
        var ok = _.find($scope.getFilteredTemplates(), function (template) {
          return template._id === TemplateSvc.selectedTemplate._id;
        });
        if (ok && ok.length === 0) {
          setSelectedTemplate($scope.getFilteredTemplates()[0]);
        }
      };

      const filteredTemplates = (categoryId, subcategoryId) => {
        if (!$scope.templates) return [];
        return $scope.templates.filter(({ type, categories }) => {
          if (categoryId === 'myTemplates') return type === 'user';
          if (categoryId === 'allTemplates') return true;
          if (categoryId === 'other') return (!categories || categories.length === 0) && type !== 'user';
          if (subcategoryId)
            return _.some(
              categories,
              (templateCategory) =>
                templateCategory._id === categoryId &&
                _.some(
                  templateCategory.subcategories,
                  (templateSubcategory) => templateSubcategory === subcategoryId
                )
            );
          else return _.some(
              categories,
              (templateCategory) =>
                  templateCategory._id === categoryId &&
                  templateCategory.subcategories.length === 0);
        });
      };

      /**
       * @ngdoc function
       * @name getFilteredTemplates
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Returns the filtered templates based on the user's input
       * @returns {Object[]} Filtered Templates
       */
      $scope.getFilteredTemplates = function () {
        if (!$scope.templates) return;
        if (!$scope.templatesFilter) return $scope.templates;
        //Goes through all the options in the template and checks whether it matches with the selectedImageDropdown
        //If 1 option doesn't match, the template will not show
        //A template does not need to have ALL options specified in the company, as long as the specified options match, it will show
        //i.e. some templates have NO options, they will ALWAYS show regardless of the options chosen.

        return filteredTemplates($scope.selectedCategory, $scope.selectedSubcategory).filter(
          function (template) {
            if (template.options.length === 0) return true;
            return (
              template.options.length ===
              template.options.filter(function (templateOption) {
                let filter = _.find($scope.templatesFilter, function (filterOption) {
                  return filterOption.name === templateOption.name;
                });
                if (!templateOption || !filter) {
                  return true;
                }
                return templateOption.values.some(function (templateOptionValue) {
                  var filterOptionValue = _.find(filter.values, function (filterOptionValue) {
                    return filterOptionValue.name === templateOptionValue.name;
                  });
                  return filterOptionValue ? filterOptionValue.selected : false;
                });
              }).length
            );
          }
        );
      };

      /**
       * @ngdoc function
       * @name selectCategory
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Triggered when the user selects another template category (tab)
       * @param {String} category Category Name
       */
      $scope.selectCategory = function (category, subcategory) {
        if (!category) {
          if (TemplateSvc.selectedTemplate.categories && TemplateSvc.selectedTemplate.categories.length > 0) {
            let category = TemplateSvc.selectedTemplate.categories[0];
            $scope.selectedCategory = category._id;
            $scope.selectedCategoryDescription = $scope.categories().filter(x => x._id === $scope.selectedCategory)[0].description;

            if (TemplateSvc.selectedTemplate.categories[0].subcategories.length > 0) {
              $scope.selectedSubcategory = TemplateSvc.selectedTemplate.categories[0].subcategories[0];
            } else {
              $scope.selectedSubcategory = subcategory ? subcategory._id : null;
            }
          } else if (TemplateSvc.selectedTemplate.type === 'user') {
            $scope.selectedCategory = 'myTemplates';
          } else {
            $scope.selectedCategory = 'other';
          }

          //Refresh the scope otherwise the image aspect ratios are incorrectly applied to the templates in the modal
          setTimeout(function () {
            refresh();
          }, 200);
        } else {
          $scope.selectedCategory = category._id;

          $scope.selectedCategoryDescription = subcategory ? subcategory.description : category.description;
          $scope.selectedSubcategory = subcategory ? subcategory._id : null;
        }

        if ($scope.selectedCategoryDescription && $scope.selectedCategoryDescription.match(/<p>.*<\/p>/g)) {
          $scope.selectedCategoryDescription = $scope.selectedCategoryDescription.substring(3, $scope.selectedCategoryDescription.length-4);
        }
      };

      /**
       * @ngdoc function
       * @name totalTemplates
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Counts the templates to determine whether the templates button should appear
       * @returns {Integer} Amount of templates
       * @todo Test to see if the button appears if a second template is added by the user
       */
      $scope.totalTemplates = function () {
        var categories = $scope.categories();
        if (!categories) return 0;
        var total = Object.keys(categories).reduce(function (total, category) {
          return total + categories[category].data.length;
        }, 0);

        return total;
      };

      /**
       * @ngdoc function
       * @name totalCategories
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Counts the categories to determince whether it needs to show them
       * @returns {Integer} Amount of categories
       */
      $scope.totalCategories = function () {
        if (!$scope.categories()) {
          return 0;
        }
        return $scope.categories().length;
      };

      /**
       * @ngdoc function
       * @name selectTemplate
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Triggered when the user selects a new template
       * @param {Object} Template Template object
       */
      $scope.selectTemplate = function (template) {
        if ($state.current.name !== 'dataentry') {
          //If $stateParams.edit is provided -> use that
          //If it is a user created template -> go straight to data entry
          //Ohterwise base design mode based on whether the user is able to create user templates
          var editing =
            $stateParams.edit === undefined
              ? template.type === 'user'
                ? false
                : AppSvc.user.company.permissions.userTemplates
              : $stateParams.edit;
          $scope.$emit('editing', { edit: editing });
          $state.go('dataentry', { template: template, edit: editing });
        } else {
          $scope.$parent.selectTemplate(template);
        }
      };

      /**
       * @ngdoc function
       * @name getOptionName
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Gets the tranlated name of the options
       * @param {String} option The option for which to get the translation
       */
      $scope.getOptionName = function (option) {
        if (!option || !option.copy) return '';
        var index = _.findIndex(option.copy, { language: AppSvc.language });
        if (index < 0) {
          index = _.findIndex(option.copy, { language: 'EN' });
        }
        return option.copy[index].name;
      };

      /**
       * @ngdoc function
       * @name setSelectedTemplate
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Triggered when the user selects a new template
       * @param {Object} Template Template object
       */
      function setSelectedTemplate(template) {
        if (!template) return;
        //TemplateSvc.createTextbox(template)
        TemplateSvc.setSelectedTemplate(template);
        $rootScope.$broadcast('template-selected');
      }

      /**
       * @ngdoc function
       * @name refresh
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * Emits a global refresh
       */
      function refresh() {
        $scope.refresh();
      }

      //If no one is logged in this will redirect the user, if there is a logged in user, it will set up the data entry sreen
      /**
       * @ngdoc event
       * @name checkUser
       * @methodOf npgc.controller:TemplatesCtrl
       * @description
       * If no one is logged in this will redirect the user, if there is a logged in user, it will set up the data entry sreen
       */
      if (AppSvc.user || $state.current.data.guest) {
        $('.scrollable-menu').css('max-height', $(window).height());
        if ($state.current.data.guest) {
          //This is triggered if the company is set up as "No Login" (loginType === 0)
          UserSvc.guest().then(function (response) {
            $scope.$emit('login', response.data);
            initTemplates.then(function () {
              console.log('Guest state detected, auto-login');
            });
          });
        } else {
          initTemplates.then(function () {
            console.log('User already logged in');
          });
        }
      } else {
        $location.path('/');
      }
    }
  );
