angular
  .module('app')
  .controller(
    'AdminCompanyTemplatesCtrl',
    function ($scope, $stateParams, AppSvc, TemplateSvc, CompanySvc, BadgeSvc, CategorySvc, AdminSvc) {
      $scope.displayRatio = 0.3;
      $scope.id = 'admin';
      $scope.search = {
        name: '',
        category: {},
        subcategory: {},
        badge: {
          code: '',
        },
        enabled: true,
      };
      $scope.sorter = 'createDate';
      $scope.reverse = true;
      $scope.random = Math.floor(Math.random() * 100000);

      $scope.$on('init', function () {
        $scope.getTemplates();
        $scope.getCompanies();
      });

      $scope.setDefaultTemplate = function (template) {
        $scope.company.templateDefault = template._id;
        CompanySvc.updateCompany($scope.company);
      };

      $scope.sortBy = function (sorter) {
        $scope.reverse = $scope.sorter === sorter ? !$scope.reverse : false;
        $scope.sorter = sorter;
      };

      $scope.getTemplates = (selectedTemplates = []) => {
        $scope.loading = true;
        TemplateSvc.getTemplatesByCompany($stateParams.company).then(function (templates) {
          $scope.templates = templates;

          for (let selectedTemplate of selectedTemplates) {
            let template = _.find(
              $scope.templates,
              (template) => selectedTemplate._id === template._id
            );
            if (template) template.selected = true;
          }
          for (let template of $scope.templates) {
            setSubcategories(template);
          }
          $scope.loading = false;
          $scope.refresh();
        });
      };

      $scope.loadTemplate = (template) => {
        TemplateSvc.getTemplate(template._id).then((loadedTemplate) => (template = loadedTemplate));
      };

      BadgeSvc.getBadges().then(function (response) {
        $scope.badges = response.data
          .map(function (badge) {
            badge.name = badge.code + ' - ' + badge.shape + ' - ' + badge.description;
            return badge;
          })
          .sort(function (a, b) {
            return a.code < b.code ? -1 : 1;
          });
      });

      $scope.getBadges = function () {
        if (!$scope.templates) return [];
        return _.uniq(
          $scope.templates.map(function (template) {
            return template.badge;
          }),
          function (badge) {
            return badge.code;
          }
        ).sort(function (a, b) {
          return a.code < b.code ? -1 : 1;
        });
      };

      $scope.getBadgeName = function (badge) {
        if (!badge) return '';
        var index = _.findIndex(badge.copy, {
          language: $scope.selectedLanguage,
        });
        if (index >= 0) {
          index = _.findIndex(badge.copy, { language: 'EN' });
        }
        return !badge ? '' : badge.copy[index].name;
      };

      $scope.getCategories = function () {
        if (!$scope.templates) return [];
        return _.uniq(
          $scope.templates.map(function (template) {
            return template.category;
          }),
          function (category) {
            return category;
          }
        )
          .filter(function (category) {
            return category;
          })
          .sort(function (a, b) {
            return a < b ? -1 : 1;
          });
      };

      $scope.setCategory = function (template, category) {
        template.category = category;
        $scope.saveTemplate(template);
      };

      $scope.getTemplates();

      $scope.getCompanies = function () {
        CompanySvc.getCompanies().then(
          function (response) {
            $scope.companies = response.data;
          },
          function (err) {
            $scope.companies = [];
            console.error(err);
          }
        );
      };
      $scope.getCompanies();

      $scope.saveTemplate = function (template) {
        if (!template.enabled && $scope.templates.filter(({ enabled }) => enabled).length <= 1) {
          template.enabled = true;
          return alert('A minimum of 1 enabled template is required');
        }
        TemplateSvc.saveTemplate(template, true).then(function (response) {
          if (response._id) {
            $scope.$emit('popup', {
              message: $scope.getCopy('saved'),
              type: 'alert-success',
            });
          }
        });
      };

      $scope.cloneTemplate = function (template) {
        TemplateSvc.cloneTemplate(template, AppSvc.user).then(
          function (response) {
            $scope.getTemplates();
            if (response._id) {
              $scope.$emit('popup', {
                message: $scope.getCopy('saved'),
                type: 'alert-success',
              });
            }
          },
          function (error) {
            console.log(error);
          }
        );
      };

      $scope.cloneTemplates = (template) => {
        Promise.all(
          $scope
            .getSelectedTemplates()
            .map((template) => TemplateSvc.cloneTemplate(template, AppSvc.user))
        ).then(
          function (responses) {
            $scope.getTemplates(responses);
            $scope.$emit('popup', {
              message: $scope.getCopy('saved'),
              type: 'alert-success',
            });
          },
          function (error) {
            console.log(error);
          }
        );
      };

      $scope.deleteTemplate = function (template) {
        if ($scope.templates.filter(({ enabled }) => enabled).length <= 1)
          return alert('A minimum of 1 enabled template is required');
        var question = {
          title: $scope.getCopy('delete') + ' - ' + template.name,
          question:
            $scope.getCopy('deleteTemplate') + ' ' + $scope.getCopy('messageActionIrreversible'),
          model: 'template',
          api: 'templates',
          action: 'delete',
          data: template,
          exec: function () {
            TemplateSvc.deleteTemplate(question.data).then(function (response) {
              $scope.$emit('questionExecuted', response, question);
            });
          },
        };
        $scope.askQueryQuestion(question);
      };
      $scope.deleteTemplates = function () {
        var question = {
          title: $scope.getCopy('delete'),
          question:
            $scope.getCopy('deleteTemplates') + ' ' + $scope.getCopy('messageActionIrreversible'),
          model: 'template',
          api: 'templates',
          action: 'delete',
          data: '',
          exec: function () {
            Promise.all(
              $scope.getSelectedTemplates().map((template) => TemplateSvc.deleteTemplate(template))
            ).then(
              function (responses) {
                $scope.getTemplates();
                $scope.$emit('questionExecuted', response, question);
              },
              function (error) {
                console.log(error);
              }
            );
          },
        };
        $scope.askQueryQuestion(question);
      };

      $scope.assignTemplate = function (template) {
        AdminSvc.setSelectedTemplate(template);
        $('#template-assign-modal').modal();
      };

      $scope.editTemplate = (template) => {
        $scope.$parent.$parent.$broadcast('selectTemplate', template._id);
      };

      $scope.assignTemplateToIPAdmin = function (template) {
        $scope.assignTemplateToCompany(
          template,
          _.findWhere($scope.companies, { number: 'ipadmin' })
        );
      };

      $scope.assignTemplateToCompany = function (template, company) {
        TemplateSvc.assignTemplate(template, company._id).then(templatesSaved, function (error) {
          console.log(error);
          $scope.$emit('popup', {
            message: 'Error while assigning template to ' + company.name,
            type: 'alert-danger',
          });
        });
      };

      $scope.assignTemplateToUser = function (template, user) {
        TemplateSvc.assignTemplate(template, user.company, user).then(
          templatesSaved,
          function (error) {
            console.log(error);
            $scope.$emit('popup', {
              message: 'Error while assigning template to ' + company.name,
              type: 'alert-danger',
            });
          }
        );
      };

      $scope.assignTemplates = (company) => {
        let selectedTemplates = $scope.getSelectedTemplates();
        Promise.all(
          selectedTemplates.map((template) => TemplateSvc.assignTemplate(template, company._id))
        ).then(templatesSaved, function (error) {
          console.log(error);
          $scope.$emit('popup', {
            message: 'Error while assigning templates to ' + company.name,
            type: 'alert-danger',
          });
        });
      };

      $scope.updateTemplates = (attribute, value) => {
        if (
          attribute === 'enabled' &&
          !value &&
          $scope.templates.filter(({ enabled }) => enabled).length <= 1
        )
          return alert('A minimum of 1 enabled template is required');
        let selectedTemplates = $scope.getSelectedTemplates();
        Promise.all(
          selectedTemplates.map((template) => {
            template[attribute] = value;
            return TemplateSvc.saveTemplate(template);
          })
        ).then(templatesSaved, function (error) {
          console.log(error);
        });
      };

      //Triggered when another badge size is chosen in design and keeps the aspect of all the objects
      $scope.updateTemplateSizes = function (badge) {
        let selectedTemplates = $scope.getSelectedTemplates();
        Promise.all(
          selectedTemplates.map((template) => {
            TemplateSvc.setBadge(template, badge);
            return TemplateSvc.saveTemplate(template);
          })
        ).then(templatesSaved, function (error) {
          console.log(error);
        });
      };

      $scope.loadAmount = 10;
      $scope.loadMore = () => {
        $scope.loadAmount += 4;
      };

      $scope.getVisibleTemplates = () =>
        $scope.templates ? $scope.templates.filter($scope.filter) : [];

      $scope.getSelectedTemplates = () =>
        $scope.templates ? $scope.templates.filter((template) => template.selected) : [];

      $scope.selectTemplate = (template) => {
        template.selected = !template.selected;
        getSubcategories();
      };

      $scope.selectAllVisibleTemplates = () => {
        const visibleTemplates = $scope.getVisibleTemplates();
        const select =
          visibleTemplates.length !==
          visibleTemplates.filter((template) => template.selected).length;
        for (let template of $scope.templates) {
          if (_.some(visibleTemplates, (visibleTemplate) => visibleTemplate._id === template._id))
            template.selected = select;
        }
        getSubcategories();
      };

      $scope.getSubcategoryFilter = () =>
        $scope.company && $scope.company.categories
          ? $scope.company.categories
              .filter(
                (category) =>
                  !$scope.search.category._id || category._id === $scope.search.category._id
              )
              .reduce(
                (subcategories, category) =>
                  _.uniq(
                    subcategories.concat(category.subcategories),
                    (subcategory) => subcategory.name
                  ),
                []
              )
          : [];
      const getSubcategories = () => {
        $scope.subcategories = $scope.company
          ? $scope.company.categories
              .filter((category) =>
                _.every($scope.getSelectedTemplates(), (template) =>
                  _.some(
                    template.categories,
                    (templateCategory) => templateCategory._id === category._id
                  )
                )
              )
              .reduce(
                (subcategories, category) =>
                  subcategories.concat(
                    category.subcategories.map((subcategory) => ({
                      ...subcategory,
                      category: {
                        name: category.name,
                        _id: category._id,
                      },
                    }))
                  ),
                []
              )
          : [];
      };
      const setSubcategories = (template) =>
        (template.subcategories = template
          ? CategorySvc.getSubcategories($scope.company, template)
          : []);

      $scope.getCategoryNames = (template) =>
        template ? CategorySvc.getCategoryNames($scope.company, template) : 'None';
      $scope.getSubcategoryNames = (template) =>
        template.subcategories
          ? CategorySvc.getSubcategoryNames(template, template.subcategories)
          : 'None';
      $scope.isCategorySelected = (template, category) =>
        CategorySvc.isCategorySelected(template, category);
      $scope.isSubcategorySelected = (template, subcategory) =>
        CategorySvc.isSubcategorySelected(template, subcategory);

      $scope.toggleCategory = (template, category, e) => {
        CategorySvc.toggleCategory(template, category);
        $scope.saveTemplate(template);
        setSubcategories(template);
        e.stopPropagation();
      };

      $scope.toggleSubcategory = (template, subcategory, e) => {
        CategorySvc.toggleSubcategory(template, subcategory);
        $scope.saveTemplate(template);
        e.stopPropagation();
      };

      $scope.toggleCategories = (category, e) => {
        const selectedTemplates = $scope.getSelectedTemplates();
        const selected = _.every(selectedTemplates, (template) =>
          _.some(template.categories, (templateCategory) => templateCategory._id === category._id)
        );
        Promise.all(
          selectedTemplates.map((template) => {
            const index = _.findIndex(
              template.categories,
              (templateCategory) => templateCategory._id === category._id
            );
            if (selected) {
              template.categories.splice(index, 1);
            } else if (index < 0) {
              template.categories.push({
                _id: category._id,
                subcategories: [],
              });
            }
            return TemplateSvc.saveTemplate(template);
          })
        ).then(templatesSaved, function (error) {
          console.log(error);
        });
      };

      $scope.toggleSubcategories = (subcategory, e) => {
        const selectedTemplates = $scope.getSelectedTemplates();
        const selected = _.every(selectedTemplates, (template) =>
          _.some(template.categories, (templateCategory) =>
            _.some(
              templateCategory.subcategories,
              (templateSubcategory) => templateSubcategory === subcategory._id
            )
          )
        );
        Promise.all(
          selectedTemplates.map((template) => {
            let category = _.find(
              template.categories,
              (templateCategory) => templateCategory._id === subcategory.category._id
            );
            if (!category) {
              template.categories.push({
                _id: category._id,
                subcategories: [],
              });
              category = template.categories[template.categories.length - 1];
            }
            const index = _.findIndex(
              category.subcategories,
              (templateSubcategory) => templateSubcategory === subcategory._id
            );
            if (selected) {
              category.subcategories.splice(index, 1);
            } else if (index < 0) {
              category.subcategories.push(subcategory._id);
            }
            return TemplateSvc.saveTemplate(template);
          })
        ).then(templatesSaved, function (error) {
          console.log(error);
        });
      };

      const templatesSaved = (templates) => {
        $scope.getTemplates(templates);
        getSubcategories();
        $scope.$emit('popup', {
          message: $scope.getCopy('saved'),
          type: 'alert-success',
        });
      };

      $scope.getTemplateSrc = (template) => TemplateSvc.getTemplateSrc(template);

      $scope.filter = (template) => {
        if (!$scope.search) return true;
        //console.log($scope.search);
        return (
          template.enabled === $scope.search.enabled &&
          template.name.toLowerCase().includes($scope.search.name) &&
          (template.badge.code === $scope.search.badge.code || $scope.search.badge.code === '') &&
          (!$scope.search.category._id ||
            _.some(
              template.categories,
              (category) => category._id === $scope.search.category._id
            )) &&
          (!$scope.search.subcategory.name ||
            _.some(template.categories, (category) =>
              _.some(
                category.subcategories,
                (subcategory) => subcategory.name === $scope.search.subcategory.name
              )
            ))
        );
      };

      $scope.$on('template-assigned', async function () {
        templatesSaved([]);
        $('#template-assign-modal').modal('toggle');
      });
    }
  );
