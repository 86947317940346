angular.module('app').service('AppSvc', function ($http, CompanySvc) {
	var svc = this;
	const defaultLang = 'EN';

	//Sets the language of the app
	svc.setLanguage = function (language) {
		//Force a language:
		//language = 'FR'
		svc.language = language;
		window.localStorage.language = language;
		console.log('Language      : ' + svc.language);
		return svc.language;
	};

	//Gets the tranlsations of all text in the app
	$http.get('/api/assets/copy').then(
		function (response) {
			svc.appCopy = response.data;
			svc.languages = [];
			Object.keys(svc.appCopy.languageName).forEach(function (language) {
				svc.languages.push({
					id: language,
					name: svc.appCopy.languageName[language],
				});
			});
			svc.languages.sort(function (language1, language2) {
				return language1.name.localeCompare(language2.name);
			});
		},
		function (error) {
			console.log(error);
		}
	);

	//Gets the copy to populate the site. This function is needed everywhere in the software where language specific copy is required
	svc.getCopy = function (word) {
		if (!svc.appCopy || !svc.appCopy[word]) return '';
		let language = svc.language ? svc.language : defaultLang;
		let result = svc.appCopy[word][language];
		if (result) {
			return result;
		} else {
			return svc.appCopy[word][defaultLang];
		}
	};

	//Sets the logged in company - especially important with no login and serial number registration companies
	svc.setCompany = function (company) {
		window.localStorage.company = company;
		svc.company = company;
		console.log('Company       : ' + svc.company);
	};

	//Sets the logged in user (and default paper if applicable)
	svc.setUser = function (user, type) {
		return new Promise((resolve, reject) => {
			svc.user = user;
			svc.type = type;
			if (window.localStorage.paper !== user.paperDefault.toUpperCase()) {
				window.localStorage.paper = user.paperDefault.toUpperCase();
			}
			svc.paper = user.paperDefault.toUpperCase();
			if (user.order) {
				console.log('Magento User  : ' + svc.user.username);
				console.log(user.order);
				//Restrict Badge Sizes when not adhoc
				if (user.order && user.order.quoteid !== 'adhoc') {
					svc.user.badges = user.order.lines.reduce(function (badges, line) {
						if (badges.indexOf(line.badge) < 0) badges.push(line.badge);
						return badges;
					}, []);
				}
			} else if (user.guest) {
				console.log('Guest User    : ' + svc.user.company.name);
			} else {
				console.log('User          : ' + svc.user.username);
			}
			if (svc.user.order && svc.user.order.quoteid) {
				window.localStorage.quoteid = svc.user.order.quoteid;
				if (svc.user.order.lineid) {
					window.localStorage.lineid = svc.user.order.lineid;
				}
			}
			if (
				svc.user.order &&
				!svc.user.order.lineid &&
				window.localStorage.lineid
			) {
				svc.user.order.lineid = window.localStorage.lineid;
			}
			svc.user.pdfName = svc.user.order
				? svc.user.order.quoteid + '_' + svc.user.order.lineid
				: svc.user._id + '_' + new Date().valueOf();
			resolve(svc.user);
		});
	};

	//Delete the user (log out)
	svc.deleteUser = function () {
		delete svc.user;
	};

	//Sets the default paper size
	svc.setDefaultPaper = function (paper) {
		if (!window.localStorage.paper) {
			window.localStorage.paper = paper.toUpperCase();
		}
		svc.paper = paper.toUpperCase();
		console.log('Paper         : ' + svc.paper);
	};

	//Sets the registration type if applicable (Serial number or validation code)
	svc.setRegisterType = function (type) {
		if (!window.localStorage.registerType) {
			window.localStorage.registerType = type;
		}
		console.log('Register type : ' + type);
	};

	//Changes the registration type - This is the only way to override and existing register type
	svc.changeRegisterType = function (type) {
		window.localStorage.registerType = type;
		console.log('Register type : ' + type);
	};

	//Gets the registration type
	svc.getRegisterType = function () {
		return window.localStorage.registerType;
	};

	//Saves the print offsets in local storage
	svc.setOffset = function (leftOffset, topOffset) {
		window.localStorage.leftOffset = leftOffset;
		window.localStorage.topOffset = topOffset;
	};

	//Gets the print offsets from local storage
	svc.getOffset = function () {
		if (
			!(
				isNaN(window.localStorage.leftOffset) &&
				isNaN(window.localStorage.topOffset)
			)
		) {
			return [window.localStorage.leftOffset, window.localStorage.topOffset];
		} else {
			return [0, 0];
		}
	};

	//Gets the "home" url. This is dependent on how the user came to the site. It will ensure that "no login" companies don't see the login screen
	svc.getURL = function () {
		/*
			    if (window.localStorage.company) {
			      CompanySvc.getCompany(window.localStorage.company)
			      .then(function(response) {
			        console.log(response);
			      }, function(err) {
			        console.error(err);
			      });
			    }*/
		return (
			'/' +
			(window.localStorage.company ? window.localStorage.company : 'home') +
			'/' +
			(svc.paper ? svc.paper.toLowerCase() : 'letter')
		);
	};

	//Gets the company
	svc.getCompany = function () {
		return window.localStorage.company;
	};

	//Sets the screen width used when the window is resized
	svc.setWidth = function (width, modifier, stretch) {
		modifier *= 0.98;
		if (width > 1200) {
			svc.width = stretch ? (width - 684) * modifier : 440;
		} else if (width > 992) {
			svc.width = stretch ? (width - 369) * modifier : 440;
		} else if (width > 768) {
			svc.width = stretch
				? (width - 369) * modifier
				: ((width - 400) / 620) * 160 + 240;
			/*
							} else if (width > 400) {
								svc.width = (width - 69) * modifier;
							} else if (width <= 400) {
								svc.width = 240;*/
		} else {
			svc.width = stretch
				? (width - 69) * modifier
				: ((width - 400) / 620) * 160 + 240;
		}
		svc.windowWidth = width;
	};

	//Gets the screen width used to caclulate the size of badges and its assets on the screen
	svc.getWidth = function (width, height) {
		if (width < height) {
			return Math.round((svc.width * width) / height) * 0.95;
		} else {
			return svc.width * 0.95;
		}
	};
});
