/**
 * Angular Object Controller
 * @file ng/controllers/admin/object.ctrl.js
 * @class angular.controllers.ObjectCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'ObjectCtrl',
		function ($scope, $stateParams, AppSvc, TemplateSvc) {
			//Drag and drop object functions
			$scope.dragObject = function (event, ui) {
				TemplateSvc.moveObject(
					$scope.$parent.selectedTemplate,
					$scope.$parent.selectedObjectType,
					$scope.$parent.selectedObject()
				);
			};

			$scope.startDragObject = function (event, ui) {
				$scope.$parent.draggingObject = true;
				$scope.$parent.do();
				$scope.$parent.refresh();
			};

			$scope.stopDragObject = function (event, ui) {
				$scope.$parent.draggingObject = false;
				//$scope.$parent.saveTemplate();
				$scope.$parent.refresh();
			};

			//Function to get the style of text within badges
			$scope.getTextStyle = function (template, object, displayRatio, optimizeBackgroundColor = false) {
				if (!object || !template) return {};
				return TemplateSvc.getTextStyle(
					template,
					object,
					($scope.getWidth() *
						(displayRatio ? displayRatio : $scope.displayRatio)) /
						$scope.dpi /
						template.badge.width,
					600,
					optimizeBackgroundColor
				);
			};

			//Function to get the font style of dropdown buttons (if there's an alternate font, use that instead)
			$scope.getFontStyle = function (textbox, item) {
				if (!$scope.selectedTemplate || !textbox) return {};
				return TemplateSvc.getFontStyle(
					$scope.$parent.selectedTemplate,
					textbox,
					$scope.getWidth() /
						$scope.dpi /
						$scope.$parent.selectedTemplate.badge.width,
					item
				);
			};

			//Function to get the style of images in badges and templates
			$scope.getImageStyle = function (template, object) {
				if (!object || !template) return {};
				return TemplateSvc.getImageStyle(template, object);
			};

			//Get the source of the image (dropdown / uploaded image / static image)
			$scope.getImageSrc = function (image) {
				if (AppSvc.user) {
					const src = TemplateSvc.getImageSrc(
						$stateParams.company
							? $stateParams.company
							: AppSvc.user.company.number,
						image,
						$stateParams.user ? $stateParams.user : AppSvc.user._id
					);
					return src;
				}
			};

			//Returns the appropriate aspect ratio
			$scope.getImageAspectRatio = function (image, id, location) {
				return TemplateSvc.getImageAspectRatio(image, id, location);
			};

			$scope.toggleBackground = function (image) {
				if (image.isBackground) {
					var oldIndex = image.index;
					$scope.getObjects().forEach(function (obj) {
						if (obj.index < oldIndex) {
							obj.index++;
						}
					});
					image.index = 1;
					$scope.$parent.selectObject('img', image);
					$scope.$parent.refresh();
				}
			};
		}
	);
