/**
 * @file ng/controllers/app.ctrl.js
 * @ngdoc function
 * @name npgc.controller:AppCtrl
 * @description
 * # DataEntryCtrl
 * Controller of the Data Entry Page
 * @requires $scope
 * @requires $state
 * @requires $window
 * @requires $rootScope
 * @requires $location
 * @requires $http
 * @requires $timeout
 * @requires AppSvc
 * @requires TemplateSvc
 * @requires PaperSvc
 * @requires ConstantsSvc
 * @requires UserSvc
 * @requires CompanySvc
 */
angular
	.module('app')
	.controller(
		'AppCtrl',
		function (
			$scope,
			$state,
			$window,
			$rootScope,
			$location,
			$http,
			$timeout,
			AppSvc,
			UserSvc,
			CompanySvc,
			TemplateSvc,
			ConstantsSvc,
			PaperSvc
		) {
			$scope.live =
				$location.host().includes('npgcloud.com') ||
				$location.host() === 'design.imprintplus.com';
			$scope.ie =
				navigator.appName == 'Microsoft Internet Explorer' ||
				!!(
					navigator.userAgent.match(/Trident/) ||
					navigator.userAgent.match(/rv:11/)
				) ||
				(typeof $.browser !== 'undefined' && $.browser.msie == 1);
			$scope.loggedIn = false;

			//This makes sure everything resizes accurately when the window resizes
			var w = angular.element($window);

			//Patterns for usernames and passwords (global)
			$scope.patternUsername = /^(?=.{6,50}$)[a-zA-Z\-0-9._@]+$/;
			$scope.patternPassword = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
			$scope.patternNumber = /^[0-9]+([.]{0,1}[0-9])*$/;
			$scope.maxFontSize = 100;
			$scope.selectedTexture = 'blank.jpg';
			$scope.previousTexture = $scope.selectedTexture;
			//Random number for various purposes
			$scope.random = Math.floor(Math.random() * 1000000);
			$scope.editing = false;
			var textureArray = [];

			//Don't load textures if not live
			//TODO: Should be adjusted if textures should be included
			if (!$scope.live) {
				ConstantsSvc.getTextures().then(function (response) {
					$scope.textures = response.data;
				});
			} else {
				$scope.textures = [];
			}

			$scope.selectTexture = function (texture) {
				if (
					!(
						$state.current.name === 'dataentry' ||
						$state.current.name === 'print'
					)
				) {
					if (
						$scope.selectedTexture === 'blank.jpg' &&
						$scope.previousTexture !== texture
					) {
						$scope.previousTexture = texture;
						return;
					}
				}
				textureArray = [$scope.selectedTexture, texture];
				$scope.selectedTexture = texture;
				//$('#texture').hide();
				textureFade(true);
			};

			$scope.getTextureButtonVisible = function () {
				return (
					!$scope.loggedIn ||
					!(
						($state.current.name === 'dataentry' ||
							$state.current.name === 'print') &&
						!$scope.editing
					)
				);
			};

			function textureFade(proceed) {
				var k = 0;
				if (!proceed) return;
				for (var i in textureArray) {
					setTimeout(function () {
						document.body.style.background =
							'url(/images/textures/' + textureArray[k] + ') repeat 0 0';
						if (k + 1 === textureArray.length) {
							setTimeout(function () {
								textureFade(false);
							}, 100);
						} else {
							k++;
						}
					}, 100 * i);
				}
			}

			$scope.$watch(
				function () {
					return $state.current.name;
				},
				function (newValue, oldValue) {
					if (
						(newValue === 'dataentry' || newValue === 'print') &&
						(oldValue === 'dataentry' || oldValue === 'print')
					)
						return;
					if (
						$state.current.name === 'dataentry' ||
						$state.current.name === 'print'
					) {
						$scope.selectedTexture = 'blank.jpg';
						$scope.selectTexture($scope.previousTexture);
					} else {
						if ($scope.selectedTexture === 'blank.jpg') return;
						$scope.previousTexture = $scope.selectedTexture;
						$scope.selectTexture('blank.jpg');
					}
				}
			);

			//DPI of the screen, used to calculate display sizes
			//  $scope.dpi = document.getElementById('dpi').offsetWidth * (window.devicePixelRatio || 1);
			$scope.dpi = document.getElementById('dpi').offsetWidth * 1;

			$scope.setWidth = function () {
				AppSvc.setWidth(window.innerWidth);
			};

			//Function that resizes displayed badges and templates depending on the screen size. The missing piece with this one is the print preview which currently doesn't resize
			$scope.getWidth = function (width, height) {
				return AppSvc.getWidth(width, height);
			};

			w.bind('resize', function () {
				$scope.setWidth();
			});

			$scope.setWidth();

			//When the user navigates aways or refreshes, they logout.
			if (!$scope.proofLocked) {
				const beforeUnloadHandler = function(e) {
					if ($scope.ie) {
						if ($scope.dataEntered) {
							e.preventDefault();
							e.returnValue = $scope.getCopy('messageLeaveWebsite');
							return $scope.getCopy('messageLeaveWebsite');
						}
						//This causes a lot of headache, especially for "no login" customers that refresh the page
						/*
						} else {
						if ($scope.live) $scope.logout();
						*/
					}
				};
				
				// Store the handler on the scope so we can remove it later
				$scope.beforeUnloadHandler = beforeUnloadHandler;
				window.addEventListener('beforeunload', beforeUnloadHandler);
			}

			//Get all paper sizes
			PaperSvc.getPapers().then(function (response) {
				$scope.papers = response.data;
			});

			//Selects a language
			$scope.selectLanguage = function (language) {
				$scope.selectedLanguage = AppSvc.setLanguage(language);
			};

			$scope.getSelectedLanguage = () => AppSvc.language;

			//Gets the copy to populate the site. This function is needed everywhere in the software where language specific cope is required
			$scope.getCopy = word => AppSvc.getCopy(word);
			$scope.getEmbeddedCopy = word => {
				if (!word) return '';
				const copy = _.find(
					word.copy,
					copy => copy.language === $scope.selectedLanguage
				);
				return copy ? copy.name : word.name;
			};

			//Sets a list of the available languages in app-copy with visible name and id
			function initLanguage() {
				//This auto-selects the language based on the user's browser or previous session
				if ($location.search().language) {
					let lang = $location.search().language;
					if (!['EN', 'FR', 'NL', 'ES', 'DE', 'PT'].includes(lang)) lang = 'EN';
					$scope.selectLanguage(lang);
				} else
					if (window.localStorage.language)
					$scope.selectLanguage(window.localStorage.language);
				else if (navigator.userLanguage)
					// Explorer
					$scope.selectLanguage(
						navigator.userLanguage.substring(0, 2).toUpperCase()
					);
				else if ($window.navigator.language)
					$scope.selectLanguage(
						$window.navigator.language.substring(0, 2).toUpperCase()
					);
				else if (navigator.language)
					// FF
					$scope.selectLanguage(
						navigator.language.substring(0, 2).toUpperCase()
					);
				//If no language is returned from the browser, it will default to English
				else $scope.selectLanguage('EN');
				$scope.languages = [];
			}

			initLanguage();

			$scope.languages = function () {
				return AppSvc.languages;
			};

			//Boolean that dictates whether data has been entered
			$scope.dataEntered = false;

			//Enables Tooltips. Hasn't been used anywhere but it's available
			$(document).ready(function () {
				$('[data-toggle="tooltip"]').tooltip({
					container: 'body',
				});
			});
			$('.btn-group [title]').tooltip({
				container: 'body',
			});

			//If the user is logged in still this executes
			if (window.sessionStorage.token) {
				$scope.loggedIn = true;
				UserSvc.setToken(window.sessionStorage.token).then(
					function (response) {
						if (!response.data) {
							clearData();
						}
						AppSvc.setUser(response.data);
						// $scope.selectLanguage(AppSvc.user.language);
						TemplateSvc.getUserFonts(AppSvc.user);
					},
					function (response) {
						console.log('Invalid session ID');
						clearData();
						$state.reload();
					}
				);
			} else {
				$scope.loggedIn = false;
			}

			$scope.user = function () {
				return AppSvc.user;
			};

			$scope.language = function () {
				return AppSvc.language;
			};

			$scope.registerType = function () {
				return AppSvc.getRegisterType();
			};

			$scope.setRegisterType = function (type) {
				AppSvc.changeRegisterType(type);
			};
						
			//Executes when a user logs in
			$scope.$on('login', function (_, user) {
				$scope.loggedIn = true;
				AppSvc.setUser(user);
				AppSvc.setCompany(user.company.number);
				// $scope.selectLanguage(AppSvc.user.language);
				TemplateSvc.clearData();
				TemplateSvc.getUserFonts(AppSvc.user);
				$scope.dataEntered = false;
			});

			$scope.logoSrc = function () {
				if (AppSvc.user && $scope.loggedIn) {
					return `companies/${AppSvc.user.company.number}/images/logo.png`;
				} else {
					return 'images/npgc.png';
				}
			};

			$scope.navigate = function (state, params) {
				if ($state.current.name === state) {
					$scope.$broadcast('state-change', params);
				} else {
					$state.go(state, params);
				}
			};

			$scope.getState = function () {
				return $state.current;
			};

			$scope.homeURL = function () {
				return AppSvc.getURL();
			};

			//Executes when the user changes
			$scope.$on('update', function (_, user) {
				AppSvc.setUser(user);
			});

			//This is used for status updates in the software. It appears on the menu bar for a short period
			$scope.$on('popup', function (_, message) {
				popup(message);
			});

			//Executes when Data Entry goes into Design mode
			$scope.$on('editing', function (event, args) {
				$scope.editing = args.edit;
				if ($scope.editing) {
					$scope.previousTexture = $scope.selectedTexture;
					$scope.selectTexture('blank.jpg');
				} else {
					$scope.selectTexture($scope.previousTexture);
				}
			});

			//Generic popup function (over the navbar)
			function popup(message) {
				$scope.popupMessage = message.message;
				$scope.popupType = message.type;
				$('#appPopup')
					.css({ top: 0 })
					.fadeIn(200)
					.delay(1500)
					.fadeOut(1000, function () {
						$('#appPopup').hide().css({ top: -120 });
					});
			}

			//This brings attention to the print button in the menu when data is entered so users know to click there when they're done typing
			$scope.$on('data-entered', function (_, isDataEntered) {
				$scope.dataEntered = isDataEntered;
			});

			//Function that executes all logout commands
			$scope.logout = function () {
				var url = AppSvc.getURL();
				if (AppSvc.user) {
					if ($scope.dataEntered) {
						$scope.question = {
							title: $scope.getCopy('logout'),
							body: $scope.getCopy('messageLeaveWebsite'),
							type: 'warning',
							answers: [
								{
									value: true,
									name: $scope.getCopy('yes'),
								},
								{
									value: false,
									name: $scope.getCopy('no'),
								},
							],
							answer: function (answer) {
								if (answer) {
									UserSvc.logout(AppSvc.user).then(function () {
										clearData();
										$location.path(url);
									});
								} else {
									$location.path('/dataentry');
								}
								$('#questionModal').modal('hide');
							},
						};
						$('#questionModal').modal();
					} else {
						UserSvc.logout(AppSvc.user).then(function () {
							clearData();
							$location.path(url);
						});
					}
				}
			};

			function clearData() {
				//Clearing local storage was necessary as IE did not automatically do this
				sessionStorage.clear();
				TemplateSvc.clearData();
				TemplateSvc.getDefaultFonts();
				$scope.dataEntered = false;
				$scope.loggedIn = false;
				AppSvc.deleteUser();
				$location.path($scope.homeURL());
			}

			// YouWho meta branding
			if (window.location.href.indexOf('youwho') !== -1) {
				// YouWho meta branding
				document.getElementById('DOM_Title').innerHTML = 'YouWho Badges';
				document.getElementById('DOM_Favicon').href = '/youwho/ios/favicon.png';
				document.getElementById('DOM_Apple_57').href =
					'/youwho/ios/youwho-icon-57x57_w.png';
				document.getElementById('DOM_Apple_72').href =
					'/youwho/ios/youwho-icon-72x72_w.png';
				document.getElementById('DOM_Apple_114').href =
					'/youwho/ios/youwho-icon-114x144_w.png';
				document.getElementById('DOM_Apple_144').href =
					'/youwho/ios/youwho-icon-144x144_w.png';
				document.getElementById('DOM_Apple_Welcome').href =
					'/youwho/ios/welcome_screen.png';
			} else if (window.location.href.indexOf('themightybadge') !== -1) {
				// The mighty Badge meta branding
				document.getElementById('DOM_Title').innerHTML = 'The Mighty Badge';
				document.getElementById('DOM_Favicon').href =
					'/themightybadge/favicon.ico';
			}

			//Formats any date in the software
			$scope.formatDate = function (date) {
				if (!date) return '';
				var months = [
					'Jan',
					'Feb',
					'Mar',
					'Apr',
					'May',
					'Jun',
					'Jul',
					'Aug',
					'Sep',
					'Oct',
					'Nov',
					'Dec',
				];
				if (typeof date === 'string') {
					var day = date.substring(8, 10);
					var month = date.substring(5, 7);
					var year = date.substring(0, 4);
					var hour = date.substring(11, 13);
					var minute = date.substring(14, 16);
					//Month - 1 as arrays start from 0
					return (
						day +
						'-' +
						months[parseInt(month) - 1] +
						'-' +
						year +
						' ' +
						hour +
						':' +
						minute
					);
				} else {
					return (
						date.getDate() +
						'-' +
						months[date.getMonth() - 1] +
						'-' +
						date.getFullYear() +
						' ' +
						date.getHours() +
						':' +
						date.getMinutes()
					);
				}
			};

			//Switches value to metric for display purposes
			$scope.convertMeasurement = function (value) {
				return AppSvc.user.system === 'Metric'
					? Math.round((value / 2.54) * 10000) / 10000
					: value;
			};

			//Ask the user to confirm DB query
			$scope.askQueryQuestion = function (question) {
				$scope.question = question;
				$('.modal').modal('hide');
				$('#queryQuestionModal').modal();
			};

			$scope.askHtmlRenderQuestion = function (question) {
				$scope.question = question;
				$('.modal').modal('hide');
				$('#htmlRenderQuestionModal').modal();
			};

			//Show a notification modal
			$scope.showPopupNotification = function (message) {
				$scope.messageNotification = message;
				$('#notificationModal').modal();
			};

			$scope.showPrintWarning = function (printWarningExec) {
				$scope.printWarningExec = printWarningExec;

				// get user browser
				$scope.userBrowser = platform.name;
				let browser;
				switch ($scope.userBrowser) {
					case 'Microsoft Edge':
						$scope.printWarningImageSrc = 'images/printwarning/edge.gif';
						browser = 'Edge';
						break;
					case 'Safari':
						$scope.printWarningImageSrc = 'images/printwarning/safari.png';
						browser = 'Safari';
						break;
					default: // Chrome or others
						$scope.printWarningImageSrc = 'images/printwarning/chrome.gif';
						browser = 'Chrome';
						break;
				}

				$scope.printWarningContents = [];
				let i = 1;
				while (true) {
					let step = $scope.getCopy(`printWarningSteps${browser}${i}`);
					if (!step) {
						break;
					}
					$scope.printWarningContents.push(step);
					i++;
				}

				$('.modal').modal('hide');
				$('#printWarningModal').modal();
			};

			//Generic modal toggle
			$scope.toggleModal = function (modal) {
				if ($('#' + modal).hasClass('in')) {
					$('#' + modal).modal('hide');
				} else {
					$('#' + modal).modal();
				}
				$scope.refresh();
			};

			$scope.hideModals = function (article) {
				$('.modal').modal('hide');
				$('.modal-backdrop').remove();
			};

			//Sends the DB query to the server
			$scope.executeQuery = function (question) {
				question.exec();
			};

			//Executes when a user logs in
			$scope.$on('questionExecuted', function (_, response, question) {
				questionExecuted(response, question);
			});

			//Answer from the server after query executed
			function questionExecuted(response, question) {
				if (response.status === 200) {
					$scope.$broadcast('init');
					//$scope.$apply();
				} else {
					popup({
						message:
							$scope.getCopy('error') +
							': ' +
							$scope.getCopy(question.action) +
							' ' +
							$scope.getCopy(question.model),
						type: 'alert-danger',
					});
					console.log(response.status);
					console.log(response.message);
				}
			}

			$scope.refresh = function () {
				try {
					$timeout(function () {
						$scope.$apply();
					}, 50);
				} catch (e) {
					console.error(e);
					try {
						$timeout(function () {
							$scope.$apply();
						}, 50);
					} catch (e) {
						console.error(e);
					}
				}
			};

			$scope.downloadEmails = function (company) {
				UserSvc.getEmails(company).then(function (response) {
					var csv = response.data;
					var filename = 'NPGCloud-Users-' + new Date().toDateString() + '.csv';
					if (window.navigator.msSaveOrOpenBlob) {
						var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
							type: 'text/csv;charset=utf-8;',
						});
						navigator.msSaveBlob(blob, filename);
					} else {
						var a = document.createElement('a');
						a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
						a.target = '_blank';
						a.download = filename;
						document.body.appendChild(a);
						a.click();
					}
				});
			};
		}
	);
