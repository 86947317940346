angular
    .module('app')
    .controller(
        'ImageSelectNpgCtrl',
        function ($scope, $stateParams, $rootScope, AppSvc, FileSvc, TemplateSvc) {
            //This used to be recursive but I removed it as it's really not necessary to make multi-level folders
            //This will open a dropdown folder and display the images therein
            $scope.selectDropdownFolder = function (dir) {
                $scope.getFiles(dir).then(function (response) {
                    $scope.do();
                    $scope.changeSelectedObject({
                        dropdownFolder: dir,
                    });
                    $scope.files = response.data;
                    selectImage($scope.files[0]);
                });
            };

            $scope.selectImage = function (image) {
                $scope.do();
                selectImage(image);
                $('#image-select-modal').modal('hide');
            };

            $scope.toggleDropdown = function () {
                $scope.do();
                $scope.selectedObject().isDropdown = !$scope.selectedObject().isDropdown;
                if ($scope.selectedObject().isDropdown && !$scope.selectedObject().dropdownFolder.dir) {
                    $scope.selectDropdownFolder({
                        dir: 'flags',
                        name: 'flags',
                        isCurated: true,
                    });
                } else if (
                    $scope.selectedObject().isDropdown &&
                    $scope.selectedObject().dropdownFolder.dir
                ) {
                    $scope.selectedObject().name = '';
                    $scope.selectDropdownFolder($scope.selectedObject().dropdownFolder);
                } else {
                    $scope.selectDropdownFolder(undefined);
                    $scope.selectedObject().dropdownItems = undefined;
                }
            };

            function selectImage(image) {
                const selectedObject = $scope.selectedObject();
                $scope.changeSelectedObject({
                    name: image.name,
                    type: image.type,
                    file: '',
                    image,
                });
                $scope.do();
                console.log(`Image ID: ${image._id}`);
            }

            //Infinite scroll
            $scope.search = {image: ''};
            $scope.loadAmount = 25;
            $scope.loadMoreImages = () => {
                $scope.loadAmount += 10;
            };

            $scope.filteredFiles = function (types) {
                if (!$scope.files) return [];
                return $scope.files.filter(function (file) {
                    return (
                        (!$scope.search.image || file.name.includes($scope.search.image)) &&
                        !(file.name === 'logo.png' && file.type === 'company') &&
                        types.includes(file.type)
                    );
                });
                //.slice(0, $scope.loadAmount);
            };

            //Upload a new image
            //TODO:Add logic so it uploads into a [Company] dropdown folder
            //Note:Think about when the above logic is implemented together with the "generic" dropdown folders
            $scope.saveImage = async function (file, image) {
                if (!file) return;

                // Size Checking
                let size = file.size / 1024 / 1024;
                if (size > 2.0) {
                    alert($scope.getCopy('messageFileSizeError2MB'));
                    return;
                }

                $scope.loading = true;
                if ($stateParams.company) {
                    FileSvc.uploadFileToCompany(file, $stateParams.company).then(
                        function (response) {
                            $scope.getFiles('').then(function (response) {
                                $scope.do();
                                //TODO: refresh Files after upload
                                image.name = file.name;
                                image.type = 'company';
                                $scope.loading = false;
                                $scope.files = response.data;

                                // $state.reload();
                            });
                        },
                        function (err) {
                            console.log(err);
                            $scope.loading = false;
                        }
                    );
                } else {
                    FileSvc.uploadFileToUser(file, AppSvc.user._id).then(
                        function (response) {
                            // if (response.data.dpi) {
                            //     console.log(`dpi: ${response.data.dpi}, width: ${image.width}`);
                            //     if (response.data.dpi * image.width < 300) {
                            //         showMessage($scope.getCopy('messageLowQualityImage'));
                            //     }
                            // }

                            $scope.getFiles('').then(function () {
                                $scope.do();
                                //TODO: refresh Files after upload
                                image.name = file.name;
                                image.type = 'user';
                                $scope.loading = false;
                                // $state.reload();
                            });
                        },
                        function (err) {
                            if (err.status === 406) {
                                showMessage($scope.getCopy('messageInvalidImage'));
                            } else if (err.status === 403) {
                                showMessage($scope.getCopy('messageFolderSizeError'));
                            } else if (err.status === 415) {
                                showMessage($scope.getCopy('messageImageFormatError'));
                            }
                            $scope.loading = false;
                            console.log(err);
                        }
                    );
                }
            };

            //Get the source of the image (dropdown / uploaded image / static image)
            $scope.getImageSrc = function (image) {
                if (AppSvc.user) {
                    const src = TemplateSvc.getImageSrc(
                        $stateParams.company ? $stateParams.company : AppSvc.user.company.number,
                        image,
                        $stateParams.user ? $stateParams.user : AppSvc.user._id
                    );
                    return src;
                }
            };

            //Deleting doesnt actually delete the file, it hides it
            //Otherwise old orders using deleted images could be rendered useless
            $scope.deleteFile = function (filename) {
                if (
                    _.find(
                        $scope.templates.filter(function (template) {
                            return template.type === 'user';
                        }),
                        function (template) {
                            return _.find(template.images, function (image) {
                                return image.name === filename;
                            });
                        }
                    )
                ) {
                    showMessage($scope.getCopy('messageFileUsedError'));
                } else {
                    var question = {
                        title: $scope.getCopy('delete') + ' - ' + filename,
                        question:
                            $scope.getCopy('deleteFile') + ' ' + $scope.getCopy('messageActionIrreversible'),
                        model: 'file',
                        api: 'user',
                        action: 'delete',
                        data: {
                            user: AppSvc.user._id,
                            filename: filename,
                        },
                        exec: function () {
                            FileSvc.deleteFileFromUser(question.data).then(function (response) {
                                $scope.do();
                                $scope.$emit('questionExecuted', response, question);
                                $scope.toggleImageModal();
                            });
                        },
                    };
                    $scope.askQueryQuestion(question);
                }
            };

            function showMessage(message) {
                $scope.message = message;
                $('#image-select-message').show(500, function () {
                    setTimeout(function () {
                        $('#image-select-message').hide(500);
                    }, 4000);
                });
            }
        }
    );
