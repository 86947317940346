/**
* Angular Admin - Company Sizes Tab Controller
* @file ng/controllers/admin/admin.company.sizes.ctrl.js
* @class angular.controllers.AdminCompanySizesCtrl
* @memberOf angular.controllers
*/
angular.module('app')
.controller('AdminCompanySizesCtrl', function($scope, CompanySvc, BadgeSvc) {

  BadgeSvc.getBadges()
  .then(function(response) {
    $scope.badges = response.data.map(function(badge) {
      badge.name = badge.code + " - " + badge.shape + " - " + badge.description;
      return badge;
    });
  });

  $scope.deleteSize = function(size) {
    if (size) {
      $scope.company.sizes = $scope.company.sizes.filter(function(companySize) {
        return companySize !== size;
      });
      CompanySvc.updateCompany($scope.company)
      .success(function(response) {
        if (response._id) {
          $scope.$emit('popup', {
            message: $scope.getCopy('saved'),
            type: 'alert-success'
          });
        }
      });
    }
  };

  $scope.addSize = function(size) {
    if (size) {
      $scope.company.sizes.push(size);
      CompanySvc.updateCompany($scope.company)
      .success(function(response) {
        if (response._id) {
          $scope.$emit('popup', {
            message: $scope.getCopy('saved'),
            type: 'alert-success'
          });
        }
      });
    }
  };

  $scope.checkSize = function (size) {
    return _.some($scope.company.sizes, function (companySize) {
      return size === companySize;
    });
  };

  $scope.checkSizes = function () {
    if (!$scope.company || !$scope.badges || !$scope.company.sizes) return false;
    return $scope.company.sizes.length === $scope.badges.length;
  };

  $scope.toggleSizes = function () {
    var i = 0;
    var sizeFound = false;
    var sizesToToggle = [];
    var toggle = !$scope.checkSizes();
    if (toggle) {
      $scope.company.sizes = $scope.badges.map(function(badge) {
        return badge.code;
      });
    } else {
      $scope.company.sizes = ['MD'];
    }
    CompanySvc.updateCompany($scope.company)
    .success(function(response) {
      if (response._id) {
        $scope.$emit('popup', {
          message: $scope.getCopy('saved'),
          type: 'alert-success'
        });
      }
    });
  };

  $scope.toggleSize = function(size) {
    if ($scope.checkSize(size)) {
      $scope.deleteSize(size);
    } else {
      $scope.addSize(size);
    }
  };

});
