/**
 * Angular Admin - CompaniesController
 * @file ng/controllers/admin/admin.companies.ctrl.js
 * @class angular.controllers.AdminCompaniesCtrl
 * @memberOf angular.controllers
 */
angular.module('app')
	.controller('AdminCompaniesCtrl', function($scope, $state, $location, AppSvc, CompanySvc) {

		if (AppSvc.user && !AppSvc.user.admin) {
			$location.path('/admin/company/' + AppSvc.user.company.number);
		}

		$scope.$on('init', function() {
			$scope.getCompanies();
		});

		$scope.getCompanies = function() {
			CompanySvc.getCompanies()
				.then(function(response) {
					$scope.companies = response.data;
				});
		};

		$scope.reverse = true;
		$scope.sorter = 'date';

		$scope.getCompanies();

		$scope.editCompany = function(company) {
			$location.path('/admin/company/' + company.number);
		};

		$scope.getLoginType = function(company) {
			switch (company.loginType) {
				case -1:
					return 'Serial Number';
				case 0:
					return 'Guest';
				case 1:
					return 'Single Login';
				default:
					return 'Validation Code';
			}
		};

		$scope.getValidationValue = function(company) {
			switch (company.loginType) {
				case -1:
					return $location.host() + '/company/' + company.validationCode;
				case 0:
					return $location.host() + '/company/' + company.validationCode;
				case 1:
					return (company.userDefault ? company.userDefault.username : 'No Default Chosen');
				default:
					return company.validationCode;
			}
		};

		$scope.deleteCompany = function(company) {
			var question = {
				'title': $scope.getCopy('delete') + ' - ' + company.name + '  (' + company.number + ')',
				'question': $scope.getCopy('deleteCompany') + ' ' + $scope.getCopy('messageActionIrreversible'),
				'model': 'company',
				'api': 'companies',
				'action': 'delete',
				'data': { company: company, data: company._id },
				'exec': function() {
					CompanySvc.executeQuestion(question)
						.then(function(response) {
							$scope.$emit('questionExecuted', response, question);
						});
				}
			};
			$scope.askQueryQuestion(question);
		};

		$scope.sortBy = function(sorter) {
			$scope.reverse = ($scope.sorter === sorter) ? !$scope.reverse : false;
			$scope.sorter = sorter;
		};

	});