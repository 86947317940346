
angular.module('app')
.directive('navbarBtnCollapse', function () {
  return {
    restrict: 'C',
    link: function (scope, element) {
      //watch for state/route change (Angular UI Router specific)
      element.bind('click', function () {
        if ($('#navbar').hasClass('in')) {
          $('#navbar').collapse('hide');
        }
      });
    }
  };
});
