angular.module('app').directive('onError', [
	function () {
		return {
			link: function (scope, element, attrs) {
				element.bind('error', function () {
					scope.template.imageLoadFail = true;
				});
			},
		};
	},
]);
