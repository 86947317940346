/**
 * Angular Admin - Sheets Controller
 * @file ng/controllers/admin/admin.sheets.ctrl.js
 * @class angular.controllers.AdminSheetsCtrl
 * @memberOf angular.controllers
 */
angular.module('app')
	.controller('AdminSheetsCtrl', function($scope, $location, AppSvc, BadgeSvc, PaperSvc, SheetSvc, FileSvc) {

		if (!AppSvc.user || !(AppSvc.user.admin || AppSvc.user.options)) {
			$location.path(AppSvc.getURL());
		}

		function init() {
			BadgeSvc.getBadges()
				.then(function(response) {
					$scope.badges = response.data;
				});

			PaperSvc.getPapers()
				.then(function(response) {
					$scope.papers = response.data;
				});

			SheetSvc.getSheets()
				.then(function(response) {
					$scope.sheets = response.data.filter(sheet => sheet._id);
				});
		}

		$scope.languages = AppSvc.languages;

		$scope.array = function(number) {
			return new Array(number);
		};

		$scope.getBadges = function(paper) {
			if (!paper) return [];
			var sheets = $scope.sheets.filter(function(sheet) {
				if (!sheet._id) return false;
				return sheet.paper._id === paper._id;
			});
			return $scope.badges.filter(function(badge) {
				for (var i in sheets) {
					if (badge.code === sheets[i].badge.code) {
						return false;
					}
				}
				return true;
			});
		};

		//Gets all the languages for a badge that do not have a translation yet.
		$scope.getLanguages = function(copy) {
			return $scope.languages.filter(function(language) {
				return !_.find(copy, function(languagecopy) {
					return language === languagecopy.language;
				});
			});
		};

		$scope.saveSheetImage = function(file, sheet) {
			if (!file) return;
			if ((file.size / 1024 / 1024) > 5) {
				$scope.$apply(function() {
					return $scope.$emit('popup', {
						message: $scope.getCopy('messageFileSizeError'),
						type: 'alert-danger'
					});
				});
				return;
			}
			FileSvc.uploadSheetImage(file, sheet)
				.then(function() {
					init();
				});
		};

		$scope.addSheet = function() {
			var today = new Date();
			$scope.sheets.push({
				name: '',
				creator: AppSvc.user._id,
				date: today.toISOString(),
				paper: null,
				badge: null,
				orientation: true,
				rows: 0,
				columns: 0,
				horizontalGap: 0.0,
				verticalGap: 0.0,
				leftOffset: 0.0,
				topOffset: 0.0
			});
		};

		$scope.saveSheet = function(sheet) {
			sheet.name = sheet.paper.name + '_' + sheet.badge.code;
			SheetSvc.saveSheet(sheet)
				.then(function(response) {
					$scope.$emit('popup', {
						message: sheet.name + ' ' + $scope.getCopy('saved'),
						type: 'alert-success'
					});
					init();
				});
		};

		$scope.deleteSheet = function (sheet) {
			let question = {
				title: $scope.getCopy('delete') + ' - ' + sheet.name,
				question:
					$scope.getCopy('deleteSheet') +
					' ' +
					$scope.getCopy('messageActionIrreversible'),
				action: 'delete',
				data: sheet,
				exec: function () {
					SheetSvc.deleteSheet(question.data.name).then(function (response) {
						$scope.$emit('questionExecuted', response, question);
						init();
					});
				},
			};
			$scope.askQueryQuestion(question);
		};

		$scope.styleBadge = function(sheet) {
			//Change the multiplication number to increase/decrease the size of the displayed badges
			var displayWidth = $('.sheet-div').width() / sheet.columns * 0.5;
			var displayHeight = displayWidth / sheet.badge.width * sheet.badge.height;
			if ((displayHeight * sheet.rows + (sheet.verticalGap ? 19 : 0)) > 250) {
				displayHeight = (250 - (sheet.verticalGap ? 19 : 0)) / sheet.rows;
				displayWidth = displayHeight / sheet.badge.height * sheet.badge.width;
			}
			var radius;
			switch (sheet.badge.shape) {
				case 'Rectangle':
					radius = '5px';
					break;
				case 'Oval':
					radius = '50%';
					break;
				case 'Half Oval':
					radius = '50% 100%';
					break;
			}
			return {
				'width': displayWidth + 'px',
				'height': displayHeight + 'px',
				'line-height': (displayHeight / 2) + 'px',
				'border-top-left-radius': radius,
				'border-top-right-radius': radius,
				'border-bottom-left-radius': sheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
				'border-bottom-right-radius': sheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius
			};
			/*
			  	//Change the multiplication number to increase/decrease the size of the displayed badges
			  	var displayWidth = sheet.badge.width * 30;
			  	var displayHeight = sheet.badge.height * 30;
			  	if (sheet.badge.shape === 'Rectangle') {
			  		//Rectangular badge
						return {
							'width': displayWidth + 'px',
							//using line-height as it centers text vertically
							'line-height': displayHeight + 'px',
							'height': displayHeight + 'px',
							'-webkit-border-radius': '2px',
							'-moz-border-radius': '2px',
							'border-radius': '2px'
						};
			  	} else {
			  		//Oval badge
				  	return {
							'width': displayWidth + 'px',
							'line-height': displayHeight + 'px',
							'height': displayHeight + 'px',
							'-webkit-border-radius': '50%',
							'-moz-border-radius': '50%',
							'border-radius': '50%'
				  	};
				  }*/
		};

		init();

	});