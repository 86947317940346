/**
 * Angular Template Controller
 * @file ng/controllers/admin/template.ctrl.js
 * @class angular.controllers.TemplateCtrl
 * @memberOf angular.controllers
 */
angular.module('app')
	.controller('TemplateCtrl', function($scope, $stateParams, AppSvc, TemplateSvc, BadgeSvc) {

		/**
		 * @param {Float} $scope.displayRatio
		 * @memberOf angular.controllers.TemplateCtrl
		 */
		if (!$scope.displayRatio) {
			$scope.displayRatio = 1;
		}

		/**
		 * Chooses ws or wss based on the location of the app
		 * @function getTemplateStyle
		 * @param {Object} template Template Object as defined by the Model
		 * @returns {Object} css style of the template
		 * @memberOf angular.controllers.TemplateCtrl
		 */
		$scope.getTemplateStyle = function(template, displayRatio, maxWidth = 10000, maxHeight = 10000) {
			if (template) {
				if ($scope.orderLine) {
					template.background = $scope.orderLine.background;
				}
				var canvasWidth = $scope.getWidth(template.width, template.height);
				var canvasHeight = canvasWidth / template.width * template.height;
				if (canvasHeight > maxHeight) {
					maxWidth *= maxHeight / canvasHeight;
				}
				return TemplateSvc.getTemplateStyle(template, canvasWidth > maxWidth ? maxWidth : canvasWidth, (displayRatio ? displayRatio : $scope.displayRatio), 'hidden');
			}
		};

		$scope.getShapeStyle = template =>
			template ? BadgeSvc.getShapeStyle(template.badge, template.background) : {};

		$scope.getShapeMaskStyle = (template, type = 'mask') =>
			template ? BadgeSvc.getShapeMaskStyle(template.badge, template.background, 1, type) : {};

		$scope.getTextStyle = function(template, object, displayRatio, maxWidth = 10000, maxHeight = 10000, index = 600) {
			var canvasWidth = $scope.getWidth(template.width, template.height);
			var canvasHeight = canvasWidth / template.width * template.height;
			if (canvasHeight > maxHeight) {
				maxWidth *= maxHeight / canvasHeight;
			}
			return TemplateSvc.getTextStyle(template, object, ((canvasWidth > maxWidth ? maxWidth : canvasWidth) * (displayRatio ? displayRatio : $scope.displayRatio) / $scope.dpi) / template.badge.width, index);
		};

		$scope.getImageStyle = function(template, image) {
			return TemplateSvc.getImageStyle(template, image);
		};

		//Returns the appropriate aspect ratio
		$scope.getImageAspectRatio = function(image, location) {
			return TemplateSvc.getImageAspectRatio(image, location);
		};

		//Get the source of the image (dropdown / uploaded image / static image)
		$scope.getImageSrc = function(image) {
			if (AppSvc.user) {
				return TemplateSvc.getImageSrc($stateParams.company ? $stateParams.company : AppSvc.user.company.number, image, $stateParams.user ? $stateParams.user : AppSvc.user._id);
			}
		};

	});