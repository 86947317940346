/**
 * Angular Register Controller
 * @file ng/controllers/register.ctrl.js
 * @class angular.controllers.RegisterCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'RegisterCtrl',
		function ($scope, $state, $location, $http, AppSvc, UserSvc, CompanySvc, gRecaptcha, env) {
			if (!AppSvc.getRegisterType()) {
				$location.path(AppSvc.getURL());
			}

			gRecaptcha.initialize({key: env.captchaSiteKey});


			//Test Data
			/*$scope.registrant = {
		  sn: ['1qaz', '2wsx','3edc','4rfv'],
		  firstName: 'Single',
		  lastName: 'User',
		  username: 'SingleUser' + Math.floor((Math.random() * 1000000)),
		  email: 'helpdesk@imprintplus.com',
		  password: 'Vaneigens',
		  confirmPassword: 'Vaneigens'
		};*/
			$scope.registrant =
				AppSvc.getRegisterType() === 'VC'
					? {
							firstName: '',
							lastName: '',
							username: '',
							email: '',
							password: '',
							confirmPassword: '',
					  }
					: {
							sn: [],
							firstName: '',
							lastName: '',
							username: '',
							email: '',
							password: '',
							confirmPassword: '',
					  };

			$scope.regType = AppSvc.getRegisterType();

			//Triggers when the Register button is pushed
			$scope.register = function () {
				gRecaptcha.execute().then(function (captchaToken) {

					if (
						!$scope.registrant.username ||
						!$scope.registrant.email ||
						!$scope.registrant.password ||
						!$scope.registrant.confirmPassword
					) {
						$scope.$emit('popup', {
							message: $scope.getCopy('messageCompleteFields'),
							type: 'alert-danger',
						});
					} else {
						$scope.loading = true;
						/*
						  UserSvc.checkEmail($scope.registrant.email)
						  .then(function(response) {
							if (response.data.address) {
							*/
						if ($scope.patternUsername.test($scope.registrant.username)) {
							if ($scope.patternPassword.test($scope.registrant.password)) {
								if ($scope.registrant.username && $scope.registrant.password) {
									if (
										$scope.registrant.password ===
										$scope.registrant.confirmPassword
									) {
										if (AppSvc.getRegisterType() === 'VC') {
											if (!$scope.validationCode) {
												$scope.loading = false;
												$scope.$emit('popup', {
													message: $scope.getCopy('messageCompleteFields'),
													type: 'alert-danger',
												});
											}
											CompanySvc.checkValidationCode($scope.validationCode).then(
												function (response) {
													$scope.registrant.company = response.data;
													let _registrant = {...$scope.registrant};
													delete _registrant.sn;
													createUser(_registrant, captchaToken);
												},
												function (err) {
													$scope.loading = false;
													$scope.trouble = true;
													$scope.$emit('popup', {
														message: $scope.getCopy(
															'messageInvalidValidationCode'
														),
														type: 'alert-danger-reg-vc',
													});
													$scope.validationCode = '';
												}
											);
										} else if (AppSvc.getRegisterType() === 'SN') {
											if (
												!$scope.registrant.sn[0] ||
												!$scope.registrant.sn[1] ||
												!$scope.registrant.sn[2] ||
												!$scope.registrant.sn[3]
											) {
												$scope.loading = false;
												$scope.$emit('popup', {
													message: $scope.getCopy('messageCompleteFields'),
													type: 'alert-danger',
												});
											} else {
												CompanySvc.checkSerialNumber(
													$scope.registrant.sn,
													AppSvc.getCompany() === 'ipadmin' ? 'imprintplus' : AppSvc.getCompany()
												).then(
													function (response) {
														$scope.registrant.company = response.data;
														createUser($scope.registrant, captchaToken);
													},
													function (err) {
														$scope.loading = false;
														console.error(err);
														$scope.trouble = true;
														if (err.status === 404) {
															$scope.$emit('popup', {
																message: $scope.getCopy(
																	'messageInvalidSerialNumber'
																),
																type: 'alert-danger-reg-sn',
															});
														} else {
															console.log(err);
															$scope.$emit('popup', {
																message: 'Error: ' + err.statusText,
																type: 'alert-danger',
															});
														}
													}
												);
											}
										}
									} else {
										$scope.loading = false;
										var originalBg = $('.password').css('backgroundColor');
										$('.password')
											.animate({backgroundColor: '#FFB6C1'}, 200)
											.animate({backgroundColor: originalBg}, 200);
									}
								}
							} else {
								$scope.loading = false;
								$scope.$emit('popup', {
									message: 'Password is invalid',
									type: 'alert-danger',
								});
							}
						} else {
							$scope.loading = false;
							if ($scope.registrant.username.length < 6) {
								$scope.$emit('popup', {
									message: $scope.registrant.username + ' is too short',
									type: 'alert-danger',
								});
							} else if ($scope.registrant.username.length > 20) {
								$scope.$emit('popup', {
									message: $scope.registrant.username + ' is too long',
									type: 'alert-danger',
								});
							} else {
								$scope.loading = false;
								//This will automatically take out the invalid characters
								$scope.registrant.username = $scope.registrant.username.replace(
									/[^a-zA-Z\-0-9._@]/g,
									''
								);
								$scope.$emit('popup', {
									message:
										$scope.registrant.username + ' contains invalid characters',
									type: 'alert-danger',
								});
							}
						}
						/*
			} else {
			  $scope.loading = false;
			  $scope.$emit('popup', {
				message: 'Error: ' + response.data.message,
				type: 'alert-danger'
			  });
			}
		  });*/
					}
				});
			};

			$scope.getCompany = AppSvc.getCompany;

			$scope.getLoginInfo = function (email, company) {
				UserSvc.getLoginInfo(
					$scope.selectedLanguage,
					email,
					company ? company : AppSvc.getCompany()
				).then(
					function (response) {
						//200 -> User Found -> Password reset email sent
						if (response.status === 200) {
							if (response.data === 'password_reset') {
								$scope.$emit('popup', {
									message: $scope.getCopy('messagePasswordResetEmail') + email,
									type: 'alert-success',
								});
							} else {
								$scope.$emit('popup', {
									message: $scope.getCopy('messageValidationCodeEmail'),
									type: 'alert-success',
								});
							}
							//202 -> Domain found
						} else if (response.status === 202) {
							if (response.data.loginType === 0) {
								//No Login
								$location.path('/company/' + response.data.validationCode);
							} else if (!response.data.loginType) {
								//Single Login
								UserSvc.setToken(response.data).then(function (currentUser) {
									$scope.$emit('login', currentUser.data);
									$state.go('templates');
									$scope.loading = false;
								});
							} else {
								//Multi-Login
								$scope.validationCode = response.data.validationCode;
								var originalBg = $('.validation-code').css('backgroundColor');
								$('.validation-code')
									.animate({ backgroundColor: 'rgba(92,184,92,0.6)' }, 1000)
									.animate({ backgroundColor: originalBg }, 500);
							}
						}
					},
					function (error) {
						/*
					//If nothing is found, open a mail template for the user to ask for a validation code.
					var text = "mailto:techsupport@imprintplus.com?subject=NPG Cloud - " + $scope.getCopy('validationCode') +
					"&body=" + $scope.getCopy('hi') +
					",%0D%0A%0D%0A" + $scope.getCopy('emailValidationCodeMyNameIs') +
					":%0D%0A" + $scope.getCopy('email') + ": " + email +
					"%0D%0A" + $scope.getCopy('emailValidationCodeRequest') + ":";
					window.location.href = text;*/
					}
				);
				$('#validationCodeModal').modal('hide');
				$('.modal-backdrop').remove();
			};

			$scope.changeSNFocus = function (index) {
				$scope.registrant.sn[index] = $scope.registrant.sn[index].toUpperCase();
				if ($scope.registrant.sn[index].length >= 4 && index < 3) {
					$('#sn' + (index + 1)).select();
				} else if ($scope.registrant.sn[index].length === 0 && index > 0) {
					$('#sn' + (index - 1)).focus();
				} else if ($scope.registrant.sn[index].length > 4 && index === 3) {
					$('#sn' + index).val(
						$('#sn' + index)
							.val()
							.substring(0, 4)
					);
				}
				if ($scope.registrant.sn[index].length > 4) {
					$scope.registrant.sn[index] = $scope.registrant.sn[index].substring(
						0,
						4
					);
				}
			};

			function createUser(registrant, captchaToken) {
				UserSvc.createUser(registrant.company, registrant, AppSvc.paper, captchaToken).then(
					function (response) {
						gRecaptcha.execute().then(function (captchaToken) {
							UserSvc.login(registrant.username, registrant.password, captchaToken).then(
								function (response) {
									$scope.loading = false;
									$scope.$emit('login', response.data);
									$state.go('templates');
								}
							);
						});
					},
					function (err) {
						console.error(err);
						$scope.loading = false;
						var message = '';
						switch (err.data.error) {
							case 'name':
								message = registrant.username + ' ' + $scope.getCopy('taken');
								break;
							case 'email':
								message = $scope.getCopy('messageEmailUsed');
								break;
							case 'limit':
								message = $scope.getCopy('userLimitReached');
								break;
							case 'sn':
								var serialnumber =
									registrant.sn[0] +
									'-' +
									registrant.sn[1] +
									'-' +
									registrant.sn[2] +
									'-' +
									registrant.sn[3];
								serialnumber = serialnumber.toUpperCase();
								message = serialnumber + $scope.getCopy('alreadyRegistered');
								break;
							case 'captcha':
								message = $scope.getCopy('captchaError');
								break;
							case 'login type':
								message = $scope.getCopy('loginTypeError');
								break;
							default:
								message = err.message;
						}
						$scope.$emit('popup', {
							message: message,
							type: 'alert-danger',
						});
					}
				);
			}
		}
	);
