/**
* Angular routing
* @namespace routes
* @memberOf angular
*/
angular.module('app')
.config(function ($httpProvider, $stateProvider, $urlRouterProvider, $locationProvider) {
  $stateProvider
  .state('default', {
    url: '/',
    controller: 'HomeCtrl',
    templateUrl: 'home.html',
    data: {
      registerType: 'VC'
    }
  })
  .state('home', {
    url: '/home/:paper',
    controller: 'HomeCtrl',
    templateUrl: 'home.html',
    data: {
      registerType: 'VC'
    }
  })
  .state('company', {
    url: '/company/:validation_code',
    controller: 'LoginCtrl',
    templateUrl: 'login.html'
  })
  .state('company_paper', {
    url: '/company/:validation_code/:paper',
    controller: 'LoginCtrl',
    templateUrl: 'login.html'
  })
  .state('youwho', {
    url: '/youwho/',
    controller: 'LoginCtrl',
    templateUrl: 'login.html',
    data: {
      validation_code: 'youwho',
      paper: 'Envelope',
      guest: true
    }
  })
  .state('themightybadge', {
    url: '/themightybadge/:paper',
    controller: 'HomeCtrl',
    templateUrl: 'home.html',
    data: {
      validation_code: 'tmb',
      registerType: 'SN'
    }
  })
  .state('wyndhamtravelodge', {
    url: '/wyndhamtravelodge',
    controller: 'HomeCtrl',
    templateUrl: 'home.html',
    data: {
      company: 'WYNWOR_wyndhamtravelodge',
      registerType: 'SN'
    }
  })
  .state('holidayinn', {
    url: '/holidayinn',
    controller: 'HomeCtrl',
    templateUrl: 'home.html',
    data: {
      company: 'IP000000_holidayinn-intercontinentalhotelsgroup-americas',
      registerType: 'SN'
    }
  })
  .state('magento', {
    url: '/magento/:token',
    controller: 'LoginCtrl',
    templateUrl: 'login.html'
  })
  .state('imprintplus', {
    url: '/imprintplus/:paper',
    controller: 'HomeCtrl',
    templateUrl: 'home.html',
    data: {
      validation_code: 'imprintplus',
      registerType: 'SN'
    }
  })
  .state('register', {
    url: '/register',
    controller: 'RegisterCtrl',
    templateUrl: 'register.html'
  })
  .state('login', {
    url: '/login',
    controller: 'LoginCtrl',
    templateUrl: 'login.html'
  })
  .state('reset', {
    url: '/reset/:token',
    controller: 'ResetCtrl',
    templateUrl: 'reset.html'
  })
  .state('user', {
    url: '/user',
    controller: 'UserCtrl',
    templateUrl: 'user.html'
  })
  .state('templates', {
    url: '/templates',
    controller: 'TemplatesCtrl',
    templateUrl: 'templates.html',
    data: {
      guest: false
    }
  })
  .state('dataentry', {
    url: '/dataentry',
    controller: 'DataEntryCtrl',
    templateUrl: 'dataentry.html',
    params: {
       template: null,
       edit: false,
    },
    data: {
      guest: false
    }
  })
  .state('print', {
    url: '/print',
    controller: 'PrintCtrl',
    templateUrl: 'print.html'
  })
  .state('support', {
    url: '/support',
    params: {
      article: null,
    },
    controller: 'SupportCtrl',
    templateUrl: 'support.html'
  })
  .state('admin', {
    url: '/admin',
    controller: 'AdminCtrl',
    templateUrl: 'admin/admin.html'
  })
  .state('admincompanies', {
    url: '/admin/companies',
    controller: 'AdminCompaniesCtrl',
    templateUrl: 'admin/admin.companies.html'
  })
  .state('admincompany',{
    url: '/admin/company/:company',
    controller: 'AdminCompanyCtrl',
    templateUrl: 'admin/admin.company.html'
  })
  .state('admintemplate', {
    url: '/admin/company/:company/template/:template',
    controller: 'AdminTemplateCtrl',
    templateUrl: 'admin/admin.template.html'
  })
  .state('adminusertemplate', {
    url: '/admin/company/:company/user/:user/template/:template',
    controller: 'AdminTemplateCtrl',
    templateUrl: 'admin/admin.template.html'
  })
  .state('adminuser', {
    url: '/admin/company/:company/user/:user',
    controller: 'AdminUserCtrl',
    templateUrl: 'admin/admin.user.html'
  })
  .state('adminusers', {
    url: '/admin/users',
    controller: 'AdminUsersCtrl',
    templateUrl: 'admin/admin.users.html'
  })
  .state('adminbadges', {
    url: '/admin/badges',
    controller: 'AdminBadgesCtrl',
    templateUrl: 'admin/admin.badges.html'
  })
  .state('adminsheets', {
    url: '/admin/sheets',
    controller: 'AdminSheetsCtrl',
    templateUrl: 'admin/admin.sheets.html'
  })
  .state('adminstats', {
    url: '/admin/stats',
    controller: 'AdminStatsCtrl',
    templateUrl: 'admin/admin.stats.html'
  })
  .state('404', {
    url: '/404',
    templateUrl: 'errors/404.html'
  });

  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });
  $urlRouterProvider.otherwise('/');
});
