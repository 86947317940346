/*jshint esversion: 6 */
/**
 * @file ng/controllers/dataentry.ctrl.js
 * @ngdoc function
 * @name npgc.controller:DataEntryCtrl
 * @description
 * # DataEntryCtrl
 * Controller of the Data Entry Page
 * @requires $scope
 * @requires $state
 * @requires $stateParams
 * @requires $location
 * @requires $window
 * @requires $interval
 * @requires $timeout
 * @requires AppSvc
 * @requires TemplateSvc
 * @requires FileSvc
 * @requires BadgeSvc
 * @requires ConstantsSvc
 * @requires UserSvc
 * @requires CompanySvc
 */
angular
  .module('app')
  .controller(
    'DataEntryCtrl',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $location,
      $window,
      $interval,
      $timeout,
      FileSaver,
      AppSvc,
      TemplateSvc,
      FileSvc,
      BadgeSvc,
      ConstantsSvc,
      UserSvc,
      CompanySvc,
      PrintSvc
    ) {
      $scope.altFonts = ConstantsSvc.altFonts;
      $scope.fontTypes = ConstantsSvc.fontTypes;
      $scope.backgroundTypes = ConstantsSvc.backgroundTypes;
      $scope.displayRatio = 1;
      $scope.editing = false;
      $scope.saving = false;
      $scope.id = 'dataentry';
      /*
		  if (!TemplateSvc.selectedTemplate) {
		    $location.path('/templates');
		  }
		*/

      // reverse data again after entering print page
      if (TemplateSvc.isDataReversing) {
        TemplateSvc.enteredData = TemplateSvc.enteredData.reverse();
        TemplateSvc.isDataReversing = false;
      }

      $scope.$on('state-change', function (event, args) {
        if ($scope.editing !== args.edit) {
          $scope.editing = !args.edit;
          $scope.toggleDesignMode();
        }
      });

      /**
       * @ngdoc function
       * @name resizeTemplates
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Resizes all templates based in screen width
       */
      function resizeTemplates() {
        TemplateSvc.resizeTemplates($scope.templates, $scope.dpi, function () {
          for (let template of $scope.templates) {
            if (template._id === TemplateSvc.selectedTemplate._id) {
              for (let textbox of template.textboxes) {
                if (textbox.isDropdown) {
                  for (let dropdownItem in textbox.dropdownItems) {
                    //Autosize the text in the Dropdown
                    if (textbox.value === dropdownItem.value) {
                      $scope.dropdownAutosize(template, 0, textbox._id, dropdownItem);
                      break;
                    }
                  }
                }
              }
            }
          }
          refresh();
        });
      }

      /**
       * @ngdoc function
       * @name getAlignmentName
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Gets the alignment name of a given option
       * @param {Integer} option Font Alignment Option
       * @returns {String} The display name for the alignment option
       */
      $scope.getAlignmentName = function (option) {
        return ConstantsSvc.getAlignmentName(option);
      };

      $scope.setCuratedDropdown = function (name) {
        $scope.changeSelectedObject({
          dropdownItems: ConstantsSvc.curatedDropdowns[name],
        });
      };

      /**
       * @ngdoc function
       * @name getWeightName
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Gets the font weight name of a given option
       * @param {Integer} option Font Weight Option
       * @returns {String} The display name for the weight option
       */
      $scope.getWeightName = function (option) {
        return ConstantsSvc.getWeightName(option);
      };

      /**
       * @ngdoc function
       * @name getStyleName
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Gets the font style name of a given option
       * @param {Integer} option Font Style Option
       * @returns {String} The display name for the style option
       */
      $scope.getStyleName = function (option) {
        return ConstantsSvc.getStyleName(option);
      };

      /**
       * @ngdoc function
       * @name getCaseName
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Gets the font case name of a given option
       * @param {Integer} option Font Case Option
       * @returns {String} The display name for the font case option
       */
      $scope.getCaseName = function (option) {
        return ConstantsSvc.getCaseName(option);
      };

      /**
       * @ngdoc object
       * @name keyDown
       * @description
       * Picks up keyboard presses
       * @methodOf npgc.controller:DataEntryCtrl
       */
      /*
		██   ██ ███████ ██    ██     ██████  ██████  ███████ ███████ ███████
		██  ██  ██       ██  ██      ██   ██ ██   ██ ██      ██      ██
		█████   █████     ████       ██████  ██████  █████   ███████ ███████
		██  ██  ██         ██        ██      ██   ██ ██           ██      ██
		██   ██ ███████    ██        ██      ██   ██ ███████ ███████ ███████
		*/
      var shifted, controlled;
      $(document).bind('keyup', function (e) {
        shifted = e.shiftKey;
        controlled = e.ctrlKey;
        $interval.cancel($scope.promise);
        $scope.promise = null;
      });
      $(document).bind('keydown', ({ shiftKey, ctrlKey, keyCode }) => {
        shifted = shiftKey;
        controlled = ctrlKey;
        if ($scope.editing && !$scope.promise) {
          if ($scope.selectedObjectType === 'tbx' || $scope.selectedObjectType === 'img') {
            //Only do this when not focused inside a textbox (as the user might be typing in the name of the textbox)
            if (document.activeElement.tagName !== 'INPUT') {
              if (keyCode === 37) {
                //Left Arrow
                $scope.moveSelectedObject('left');
              } else if (keyCode === 38) {
                //Up Arrow
                $scope.moveSelectedObject('up');
              } else if (keyCode === 39) {
                //Right Arrow
                $scope.moveSelectedObject('right');
              } else if (keyCode === 40) {
                //Down Arrow
                $scope.moveSelectedObject('down');
              } else if (keyCode === 46 || keyCode === 8) {
                //Delete or Backspace Key
                $scope.deleteObject($scope.selectedObject());
              } else if (keyCode === 88 && controlled) {
                //Ctrl + X
                $scope.copiedObject = {
                  type: $scope.selectedObjectType,
                  object: JSON.parse(JSON.stringify($scope.selectedObject())),
                };
                $scope.deleteObject($scope.selectedObject);
              } else if (keyCode === 67 && controlled) {
                //Ctrl + C
                $scope.do();
                $scope.copiedObject = {
                  type: $scope.selectedObjectType,
                  object: JSON.parse(JSON.stringify($scope.selectedObject())),
                };
                $scope.copiedObject.object.leftOffset += 0.05;
                $scope.copiedObject.object.topOffset += 0.05;
              }
            }
            if (keyCode === 86 && controlled) {
              //Ctrl + V
              if (!$(':focus').is('input') && $scope.copiedObject) {
                //Only do this if the focus is NOT in an input and if there is a copiedObject
                //This makes sure you can still paste values in width, height, etc.
                $scope.copiedObject.object._id = ObjectId().toString();
                $scope.copiedObject.object.index = getNewLayerIndex();
                if ($scope.copiedObject.type === 'img') {
                  $scope.selectedTemplate.images.push(
                    JSON.parse(JSON.stringify($scope.copiedObject.object))
                  );
                  $scope.selectObject(
                    'img',
                    $scope.selectedTemplate.images[$scope.selectedTemplate.images.length - 1]
                  );
                } else if ($scope.copiedObject.type === 'tbx') {
                  $scope.selectedTemplate.textboxes.push(
                    JSON.parse(JSON.stringify($scope.copiedObject.object))
                  );
                  $scope.selectObject(
                    'tbx',
                    $scope.selectedTemplate.textboxes[$scope.selectedTemplate.textboxes.length - 1]
                  );
                }
                $scope.copiedObject.object.leftOffset += 0.05;
                $scope.copiedObject.object.topOffset += 0.05;
                refresh();
              }
            }
          } else {
            if (keyCode === 37) {
              //Left Arrow
              $scope.moveAllObjects('left');
            } else if (keyCode === 38) {
              //Up Arrow
              $scope.moveAllObjects('up');
            } else if (keyCode === 39) {
              //Right Arrow
              $scope.moveAllObjects('right');
            } else if (keyCode === 40) {
              //Down Arrow
              $scope.moveAllObjects('down');
            }
          }
          if (keyCode === 90 && controlled) {
            //Ctrl + Z
            $scope.undo();
          } else if (keyCode === 89 && controlled) {
            //Ctrl + Y
            $scope.redo();
          }
        }
      });

      $('#dataentry-page').bind('keydown', function (e) {
        if (!$scope.editing) {
          if (e.keyCode === 13 && !shifted) {
            //Enter Key
            $scope.enterData($scope.selectedTemplate);
          }
        }
      });

      /**
       * @ngdoc function
       * @name moveSelectedObject
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Function that is triggered when using the arrow keys
       * @param {String} direction Direction of the arrow
       */
      $scope.moveSelectedObject = (direction) => {
        $scope.promise = $interval(() => {
          let object = $scope.selectedObject();
          if (direction === 'left') {
            //Left Arrow
            if (controlled) {
              object.width =
                Math.round((object.leftOffset <= 0 ? object.width : object.width - 0.01) * 10000) /
                10000;
            } else {
              object.leftOffset =
                Math.round((object.leftOffset <= 0 ? 0 : object.leftOffset - 0.01) * 10000) / 10000;
              if (shifted) {
                object.width =
                  Math.round((object.leftOffset <= 0 ? object.width : object.width + 0.01) * 10000) /
                  10000;
              }
            }
          } else if (direction === 'up') {
            //Up Arrow
            if (controlled) {
              object.height =
                Math.round((object.topOffset <= 0 ? object.height : object.height - 0.01) * 10000) /
                10000;
            } else {
              object.topOffset =
                Math.round((object.topOffset <= 0 ? 0 : object.topOffset - 0.01) * 10000) / 10000;
              if (shifted) {
                object.height =
                  Math.round((object.topOffset <= 0 ? object.height : object.height + 0.01) * 10000) /
                  10000;
              }
            }
          } else if (direction === 'right') {
            //Right Arrow
            if (controlled) {
              object.width =
                Math.round(
                  (object.leftOffset + object.width >= $scope.selectedTemplate.badge.width
                    ? object.width
                    : object.width + 0.01) * 10000
                ) / 10000;
            } else {
              object.leftOffset =
                Math.round(
                  (object.leftOffset + object.width >= $scope.selectedTemplate.badge.width
                    ? $scope.selectedTemplate.badge.width - object.width
                    : object.leftOffset + 0.01) * 10000
                ) / 10000;
              if (shifted) {
                object.width =
                  Math.round(
                    (object.leftOffset + object.width >= $scope.selectedTemplate.badge.width
                      ? object.width
                      : object.width - 0.01) * 10000
                  ) / 10000;
              }
            }
          } else if (direction === 'down') {
            //Down Arrow
            if (controlled) {
              object.height =
                Math.round(
                  (object.topOffset + object.height >= $scope.selectedTemplate.badge.height
                    ? object.height
                    : object.height + 0.01) * 10000
                ) / 10000;
            } else {
              object.topOffset =
                Math.round(
                  (object.topOffset + object.height >= $scope.selectedTemplate.badge.height
                    ? $scope.selectedTemplate.badge.height - object.height
                    : object.topOffset + 0.01) * 10000
                ) / 10000;
              if (shifted) {
                object.height =
                  Math.round(
                    (object.topOffset + object.height >= $scope.selectedTemplate.badge.height
                      ? object.height
                      : object.height - 0.01) * 10000
                  ) / 10000;
              }
            }
          }
          refresh();
        }, 100);
      };

      /**
       * @ngdoc function
       * @name moveAllObjects
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Function that is triggered when using the arrow keysand the templates is selected
       * @param {String} direction Direction of the arrow
       */
      $scope.moveAllObjects = (direction) => {
        $scope.promise = $interval(() => {
          $scope.getObjects().forEach((object) => {
            if (direction === 'left') {
              //Left Arrow
              if (controlled) {
                object.width =
                  Math.round((object.leftOffset <= 0 ? object.width : object.width - 0.01) * 100) /
                  100;
              } else {
                object.leftOffset =
                  Math.round((object.leftOffset <= 0 ? 0 : object.leftOffset - 0.01) * 100) / 100;
                if (shifted) {
                  object.width =
                    Math.round(
                      (object.leftOffset <= 0 ? object.width : object.width + 0.01) * 100
                    ) / 100;
                }
              }
            } else if (direction === 'up') {
              //Up Arrow
              if (controlled) {
                object.height =
                  Math.round((object.topOffset <= 0 ? object.height : object.height - 0.01) * 100) /
                  100;
              } else {
                object.topOffset =
                  Math.round((object.topOffset <= 0 ? 0 : object.topOffset - 0.01) * 100) / 100;
                if (shifted) {
                  object.height =
                    Math.round(
                      (object.topOffset <= 0 ? object.height : object.height + 0.01) * 100
                    ) / 100;
                }
              }
            } else if (direction === 'right') {
              //Right Arrow
              if (controlled) {
                object.width =
                  Math.round(
                    (object.leftOffset + object.width >= $scope.selectedTemplate.badge.width
                      ? object.width
                      : object.width + 0.01) * 100
                  ) / 100;
              } else {
                object.leftOffset =
                  Math.round(
                    (object.leftOffset + object.width >= $scope.selectedTemplate.badge.width
                      ? $scope.selectedTemplate.badge.width - object.width
                      : object.leftOffset + 0.01) * 100
                  ) / 100;
                if (shifted) {
                  object.width =
                    Math.round(
                      (object.leftOffset + object.width >= $scope.selectedTemplate.badge.width
                        ? object.width
                        : object.width - 0.01) * 100
                    ) / 100;
                }
              }
            } else if (direction === 'down') {
              //Down Arrow
              if (controlled) {
                object.height =
                  Math.round(
                    (object.topOffset + object.height >= $scope.selectedTemplate.badge.height
                      ? object.height
                      : object.height + 0.01) * 100
                  ) / 100;
              } else {
                object.topOffset =
                  Math.round(
                    (object.topOffset + object.height >= $scope.selectedTemplate.badge.height
                      ? $scope.selectedTemplate.badge.height - object.height
                      : object.topOffset + 0.01) * 100
                  ) / 100;
                if (shifted) {
                  object.height =
                    Math.round(
                      (object.topOffset + object.height >= $scope.selectedTemplate.badge.height
                        ? object.height
                        : object.height - 0.01) * 100
                    ) / 100;
                }
              }
            }
          });
          refresh();
        }, 100);
      };

      /**
       * @ngdoc function
       * @name refresh
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Emits a global refresh
       */
      function refresh() {
        $scope.refresh();
      }

      //This makes sure everything resizes accurately when the window resizes
      var w = angular.element($window);

      //This makes sure that when the window is resized, the dropdowns will resize accordingly
      w.bind('resize', function () {
        resizeTemplates();
        refresh();
      });

      /**
       * @ngdoc function
       * @name triggerUpload
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Trigger the upload programmatically, not used anywhere currently
       */
      $scope.triggerUpload = function () {
        var fileuploader = angular.element('#fileInput');
        fileuploader.on('click', function () {
          console.log('File upload triggered programmatically');
        });
        fileuploader.trigger('click');
      };

      //Helper function to stop propogation (clicking an object will not click through to clicking the underlying template)
      $scope.preventDefault = function (e) {
        e.preventDefault();
      };

      $scope.print = function () {
        $location.path('/print');
      };

      $scope.alterFontSize = (object, value) => {
        object.fontSize = parseInt(object.fontSize);
        if (value < 0) {
          object.fontSize = parseInt(
            object.fontSize > 1 ? object.fontSize + value : object.fontSize
          );
        } else {
          object.fontSize = parseInt(
            object.fontSize < $scope.maxFontSize ? object.fontSize + value : object.fontSize
          );
        }
      };

      /**
       * @ngdoc function
       * @name selectBadge
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Triggered when the user selects a badgee size
       * @param {Object} Badge Badge Object
       */
      $scope.selectBadge = function (badge) {
        ConstantsSvc.getBackgrounds().then((response) => {
          $scope.backgrounds = response.data
            .map((bg) => bg.name)
            .filter((bg) => badge.backgrounds.indexOf(bg) >= 0);
          $scope.maxFontSize = Math.round(badge.height * 72 * 1.2);
          var templates = TemplateSvc.enteredData
            .filter(function (tag) {
              return tag.badge.code === badge.code;
            })
            .map(function (tag) {
              return tag._id;
            });
          /*
										setSelectedTemplate(_.find($scope.templates, function(template) {
											return template._id === templates[templates.length - 1];
										}));*/
        });
      };

      /**
       * @ngdoc function
       * @name getAddStyle
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Function that adjusts the add button size based on the screen width
       * @returns {Object} Height of the add button div ("The Olive")
       */
      $scope.getAddStyle = function () {
        return {
          height: $scope.getWidth() / 5 + 'px',
        };
      };

      /**
       * @ngdoc function
       * @name getAddButtonStyle
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Function that adjusts the add button position based on the screen width
       * @returns {Object} The CSS style of the add button ("The Olive")
       */
      $scope.getAddButtonStyle = function () {
        if ($scope.selectedTemplate) {
          var canvasWidth = $scope.getWidth(
            $scope.selectedTemplate.width,
            $scope.selectedTemplate.height
          );
          var height =
            (canvasWidth / $scope.selectedTemplate.width) * $scope.selectedTemplate.height;
          var style = {
            width: $scope.getWidth() / 5 + 'px',
            marginTop:
              $scope.selectedTemplate.badge.shape.indexOf('Half') >= 0
                ? height / 2
                : height / 2 - canvasWidth / 10 + 'px',
            marginLeft: '-' + $scope.getWidth() / 10 + 'px',
          };
          return style;
        }
      };

      /**
       * @ngdoc function
       * @name getCanvasStyle
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Function that adjusts the add button position based on the screen width
       * @deprecated Canvas styling now happens in other functions based on user actions
       * @returns {Object} The CSS style of canvas (area surrounding the template)
       */
      $scope.getCanvasStyle = function (template, modifier) {
        if (template) {
          var canvasWidth = $scope.getWidth(
            $scope.selectedTemplate.width,
            $scope.selectedTemplate.height
          );
          return TemplateSvc.getCanvasStyle(template, canvasWidth, modifier, $scope.editing);
        }
      };

      /**
       * @ngdoc function
       * @name getTemplateStyle
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Function to adjust the template style based on the screen width
       * @param {Object} template Template
       * @param {Float} modifier Percentage of the screen adjustment
       * @returns {Object} The CSS style of the template
       */
      $scope.getTemplateStyle = function (template, modifier, overflow) {
        if (template) {
          var canvasWidth = $scope.getWidth(
            $scope.selectedTemplate.width,
            $scope.selectedTemplate.height
          );
          return TemplateSvc.getTemplateStyle(template, canvasWidth, modifier, overflow);
        }
      };

      $scope.getShapeStyle = (template) =>
        template ? BadgeSvc.getShapeStyle(template.badge, template.background) : {};
      /**
       * @ngdoc function
       * @name getObjects
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Return all the objects on the template
       * @returns {Object} The CSS style of the template
       */
      $scope.getObjects = function () {
        if (!$scope.selectedTemplate) return [];
        let objects = $scope.selectedTemplate.textboxes
          .concat($scope.selectedTemplate.images)
          .sort(function (a, b) {
            return a.index < b.index ? 1 : -1;
          });
        // for (let i = objects.length - 1; i >= 0; i--) {
        //   if (objects[i].index != i + 1) {
        //     objects[i].index = i + 1;
        //   }
        // }
        // console.log(objects);
        return objects;
      };

      /**
       * @ngdoc function
       * @name enterData
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Executed when Enter is typed or add button is clicked
       * @param {Object} template Template
       */
      $scope.enterData = function (template) {
        var textbox;
        if (!template) {
          return;
        }
        var validated = true;
        var i, j;
        template.isEnteredData = true;
        for (i in template.textboxes) {
          textbox = template.textboxes[i];
          //If some mandatory fields on the badge are empty, throw an error
          if (
            !textbox.isEmptyAllowed &&
            (!textbox.value || (textbox.value === textbox.placeholder && textbox.isDropdown))
          ) {
            validated = false;
            var originalBg = $('#' + textbox._id + '_input').css('backgroundColor');
            $('#' + textbox._id + '_input')
              .animate({ backgroundColor: '#FFB6C1' }, 500)
              .animate({ backgroundColor: originalBg }, 500);
            $scope.$emit('popup', {
              message: $scope.getCopy('messageCompleteFields') + ' -> ' + textbox.placeholder,
              type: 'alert-danger',
            });
            break;
          } else {
            //Dropdown
            if (textbox.isDropdown) {
              for (j in textbox.dropdownItems) {
                //Autosize the text in the Dropdown
                if (textbox.value === textbox.dropdownItems[j].value) {
                  $scope.dropdownAutosize(template, 0, textbox._id, textbox.dropdownItems[j]);
                  break;
                }
              }
              //If the default value is still selected and the dropdown isn't mandatory, then it will show up blank
              if (textbox.isEmptyAllowed && textbox.value === textbox.placeholder) {
                textbox.value = '';
              }
              //Textbox
            } else {
              //if not editable => Static Textbox
              //The assumption is that Static Textboxes don't need to autosize
              if (!textbox.editable) {
                textbox.value = textbox.placeholder;
                textbox.adjustedFontSizePct = 1;
              } else {
                //Autosize the text in the textbox
                //template.textboxes[i].adjustedFontSizePct = template.textboxes[i].fontSize / template.textboxes[i].defaultFontSize;
              }
            }
          }
        }

        if (validated) {
          for (i in template.images) {
            var image = template.images[i];
            image.aspectRatio = TemplateSvc.getImageAspectRatio(
              image,
              (image.cid ? image.cid : image._id) + '_dataentry'
            );
          }
          //The JSON parsing is to clone the template provided, this prevents referencing.
          TemplateSvc.enteredData.push(JSON.parse(JSON.stringify(template)));
          resetTemplate(template);
          //Emit to the app controller that there is data entered so the Print button shows up
          if (TemplateSvc.enteredData.length > 0) {
            $scope.$emit('data-entered', true);
          }
          setFocusOnFirstTextbox(template);
        } else {
          for (i in template.textboxes) {
            textbox = template.textboxes[i];
            if (textbox.isDropdown && textbox.value === '') {
              textbox.value = template.textboxes[i].placeholder;
            }
          }
        }
      };

      function resetTemplate(template) {
        //This section resets the template to its original settings (Autosizes back to default font and font size)
        for (var i in TemplateSvc.selectedTemplate.textboxes) {
          var textbox = template.textboxes[i];
          textbox.fontName = TemplateSvc.selectedTemplate.textboxes[i].originalFontName;
          delete textbox.fontType;
          textbox.fontLocation = TemplateSvc.selectedTemplate.textboxes[i].originalFontLocation;
          //Reset Dropdown
          if (TemplateSvc.selectedTemplate.textboxes[i].isDropdown) {
            if (textbox.value === '') {
              textbox.value = TemplateSvc.selectedTemplate.textboxes[i].placeholder;
              $scope.dropdownAutosize(TemplateSvc.selectedTemplate, 0, textbox._id, {});
            } else {
              for (var j in TemplateSvc.selectedTemplate.textboxes[i].dropdownItems) {
                if (
                  textbox.value === TemplateSvc.selectedTemplate.textboxes[i].dropdownItems[j].value
                ) {
                  var dropdownItem = TemplateSvc.selectedTemplate.textboxes[i].dropdownItems[j];
                  if (dropdownItem.altFontName) {
                    textbox.fontName = dropdownItem.altFontName;
                    textbox.fontType = dropdownItem.altFontType;
                    textbox.fontLocation = 'global';
                  }
                  $scope.dropdownAutosize(
                    TemplateSvc.selectedTemplate,
                    0,
                    textbox._id,
                    dropdownItem
                  );
                  break;
                }
              }
            }
            //Reset Textbox
          } else {
            textbox.fontName = TemplateSvc.selectedTemplate.textboxes[i].fontName;
            delete textbox.fontType;
            textbox.fontLocation = TemplateSvc.selectedTemplate.textboxes[i].fontLocation;
            textbox.value = '';
            textbox.adjustedFontSizePct = 1;
            //$scope.textAutoSize(template.textboxes[i]);
          }
        }
      }

      /**
       * @ngdoc function
       * @name deleteTag
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Executed when delete on a entered badge is clicked
       * @param {Object} template Template (with entered data)
       */
      $scope.deleteTag = function (template) {
        //Removes the clicked badge from the enteredData
        var index = TemplateSvc.enteredData.indexOf(template);
        TemplateSvc.enteredData.splice(index, 1);
        checkData();
      };

      /**
       * @ngdoc function
       * @name editTag
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Executed when edit on a entered badge is clicked
       * @param {Object} template Template (with entered data)
       */
      $scope.editTag = function (template) {
        var i, j;
        //Removes the clicked badge from the enteredData
        var index = TemplateSvc.enteredData.indexOf(template);
        TemplateSvc.enteredData.splice(index, 1);

        setSelectedTemplate(
          $scope.templates.filter(function (tmplt) {
            return tmplt._id === template._id;
          })[0]
        );
        $scope.selectedTemplate.printDirection = template.printDirection;
        /*
			    var canvasWidth = $scope.getWidth($scope.selectedTemplate.width, $scope.selectedTemplate.height);
			    var canvas = {
			      width: ($scope.selectedTemplate.width + ($scope.selectedTemplate.printDirection ? 0.5 : 0)) * canvasWidth / $scope.selectedTemplate.width,
			      height: canvasWidth / $scope.selectedTemplate.width * ($scope.selectedTemplate.height+ ($scope.selectedTemplate.printDirection ? 0 : 0.5)),
			      paddingTop: $scope.selectedTemplate.printDirection ? 0 : 0.25 * canvasWidth / $scope.selectedTemplate.width
			    };
			    $('#canvas').animate(canvas, 1000);*/
        //TODO: This can be much less code than it is
        for (i in $scope.selectedTemplate.textboxes) {
          for (j in template.textboxes) {
            if ($scope.selectedTemplate.textboxes[i]._id === template.textboxes[j]._id) {
              if ($scope.selectedTemplate.textboxes[i].isDropdown) {
                if (template.textboxes[j].value === '') {
                  $scope.selectedTemplate.textboxes[i].value =
                    $scope.selectedTemplate.textboxes[i].placeholder;
                } else {
                  $scope.selectedTemplate.textboxes[i].value = template.textboxes[j].value;
                  $scope.selectedTemplate.textboxes[i].adjustedFontSizePct =
                    template.textboxes[j].adjustedFontSizePct;
                  /*
								for (var k in template.textboxes[j].dropdownItems) {
								  if (template.textboxes[j].value === template.textboxes[j].dropdownItems[k].value) {
								    $scope.selectedTemplate.textboxes[i].value = template.textboxes[j].value;
								    //$scope.dropdownAutosize($scope.selectedTemplate.textboxes[i], template.textboxes[j].dropdownItems[k]);
								    break;
								  }
								}
								*/
                }
              } else {
                $scope.selectedTemplate.textboxes[i].value = template.textboxes[j].value;
                $scope.selectedTemplate.textboxes[i].adjustedFontSizePct =
                  template.textboxes[j].adjustedFontSizePct;
                $scope.selectedTemplate.textboxes[i].previousValue = $scope.selectedTemplate.textboxes[i].value;
                //$scope.textAutoSize($scope.selectedTemplate.textboxes[i]);
              }
              break;
            }
          }
        }

        $scope.selectedTemplate.images = template.images;
        for (i in $scope.selectedTemplate.images) {
          for (j in template.images) {
            if ($scope.selectedTemplate.images[i]._id === template.images[j]._id) {
              if (template.images[j].isDropdown) {
                $scope.selectedTemplate.images[i].value = template.images[j].value;
              }
              break;
            }
          }
        }
        //Bring attention to the top badge
        $('.dataentry').animate({ opacity: 0.2 }, 200).animate({ opacity: 1 }, 500);
        setFocusOnFirstTextbox(template);
        checkData();
      };

      /**
       * @ngdoc function
       * @name setFocusOnFirstTextbox
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Focuses the cursor on a textbox so the user doesn't have to click it
       * @param {Object} template Template
       */
      function setFocusOnFirstTextbox(template) {
        //Set focus to the first editable textbox on the template (based on layers)
        var highestTextbox = _.max(
          template.textboxes.filter(function (textbox) {
            return !textbox.isDropdown && textbox.editable;
          }),
          function (textbox) {
            return textbox.index;
          }
        );
        if (highestTextbox) {
          $('#' + highestTextbox._id + '_input').focus();
        }
      }

      /**
       * @ngdoc function
       * @name checkData
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Emit that data is entered (to bring attention to the print button)
       */
      function checkData() {
        if (TemplateSvc.enteredData.length === 0) {
          $scope.$emit('data-entered', false);
        }
      }

      /**
       * @ngdoc function
       * @name deleteData
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Delete all data of a certain badge
       * @param {Object} template Template
       */
      $scope.deleteData = function (badge) {
        if (!badge) {
          TemplateSvc.enteredData = [];
          checkData();
        } else {
          TemplateSvc.enteredData = TemplateSvc.enteredData.filter(function (tag) {
            checkData();
            return tag.badge.code !== badge.code;
          });
        }
      };

      /**
       * @ngdoc function
       * @name enteredData
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Returns all the data already entered by the user
       * @returns {Object[]} Array of entered Data
       */
      $scope.enteredData = function () {
        return TemplateSvc.enteredData;
      };

      /**
       * @ngdoc function
       * @name enteredBadges
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Returns all badge sizes of the entered data
       * @returns {Object[]} Array of badges
       */
      $scope.enteredBadges = function () {
        var badges = {};
        TemplateSvc.enteredData.forEach(function (tag) {
          if (!badges[tag.badge.code]) {
            badges[tag.badge.code] = tag.badge;
            badges[tag.badge.code].count = 1;
          } else {
            badges[tag.badge.code].count++;
          }
        });
        return badges;
      };

      /**
       * @ngdoc function
       * @name getBadgeCount
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Counts the amount of badges that have entered data
       * @returns {Integer} Count of badge sizes
       */
      $scope.getBadgeCount = function () {
        return _.uniq(
          TemplateSvc.enteredData.map(function (tag) {
            return tag.badge.code;
          }),
          function (code) {
            return code;
          }
        ).length;
      };

      /**
       * @ngdoc function
       * @name getBadgeName
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Get badge specific name (translate appropriately)
       * @param {Object} badge Badge
       * @returns {String} The name of the badge in the correct language
       * @todo Metric VS Imperial
       */
      $scope.getBadgeName = function (badge) {
        if (!badge) return '';
        var index = _.findIndex(badge.copy, {
          language: $scope.selectedLanguage,
        });
        if (index < 0) {
          index = _.findIndex(badge.copy, { language: 'EN' });
        }
        return (
          badge.code +
          ' - ' +
          badge.copy[index].name +
          ' (' +
          Math.round(badge.width * 10) / 10 +
          '" x ' +
          Math.round(badge.height * 10) / 10 +
          '")'
        );
      };

      /**
       * @ngdoc function
       * @name toggleDeleteDropdown
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Toggles the delete dropdown
       */
      $scope.toggleDeleteDropdown = function () {
        $('#delete-dropdown').toggle();
      };

      /**
       * @ngdoc function
       * @name setBadge
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Triggered when another badge size is chosen in design. It will recaluclate all dimensions so the objects fir on the badge
       * @param {Object} badge Badge
       */
      $scope.setBadge = function (badge) {
        $scope.do();
        var ratioWidth, ratioHeight;
        if (badge.shape.indexOf('Half') >= 0) {
          $scope.selectedTemplate.printDirection = true;
        }
        if ($scope.selectedTemplate.printDirection) {
          $scope.selectedTemplate.width = badge.width;
          $scope.selectedTemplate.height = badge.height;
          ratioWidth = badge.width / $scope.selectedTemplate.badge.width;
          ratioHeight = badge.height / $scope.selectedTemplate.badge.height;
        } else {
          $scope.selectedTemplate.width = badge.height;
          $scope.selectedTemplate.height = badge.width;
          ratioWidth = badge.height / $scope.selectedTemplate.badge.height;
          ratioHeight = badge.width / $scope.selectedTemplate.badge.width;
        }
        $scope.selectedTemplate.textboxes.forEach(function (textbox) {
          textbox.width = Math.round(textbox.width * ratioWidth * 100) / 100;
          textbox.height = Math.round(textbox.height * ratioHeight * 100) / 100;
          textbox.leftOffset = Math.round(textbox.leftOffset * ratioWidth * 100) / 100;
          textbox.topOffset = Math.round(textbox.topOffset * ratioHeight * 100) / 100;
          textbox.value = '';
          //This makes the font size appropriately
          textbox.fontSize = Math.round(textbox.fontSize * ratioHeight);
          var defaultFontSize =
            (AppSvc.getWidth($scope.selectedTemplate.width, $scope.selectedTemplate.height) /
              $scope.dpi /
              $scope.selectedTemplate.width) *
            textbox.fontSize;
          textbox.defaultFontSize = defaultFontSize;
          $scope.textAutoSize(textbox);
        });

        $scope.selectedTemplate.images.forEach(function (image) {
          image.width = Math.round(image.width * ratioWidth * 100) / 100;
          image.height = Math.round(image.height * ratioHeight * 100) / 100;
          image.leftOffset = Math.round(image.leftOffset * ratioWidth * 100) / 100;
          image.topOffset = Math.round(image.topOffset * ratioHeight * 100) / 100;
        });
        $scope.selectedTemplate.badge = badge;
        var canvasWidth = $scope.getWidth(
          $scope.selectedTemplate.width,
          $scope.selectedTemplate.height
        );
        $('#canvas').animate({
          width:
            (($scope.selectedTemplate.width + ($scope.selectedTemplate.printDirection ? 0.5 : 0)) *
              canvasWidth) /
            $scope.selectedTemplate.width,
          height:
            (canvasWidth / $scope.selectedTemplate.width) *
            ($scope.selectedTemplate.height + ($scope.selectedTemplate.printDirection ? 0 : 0.5)),
          paddingTop: $scope.selectedTemplate.printDirection
            ? 0
            : (0.25 * canvasWidth) / $scope.selectedTemplate.width,
        });
      };

      /**
       * @ngdoc function
       * @name textWidth
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Creates a piece of HTML that copies the entered text. This code works in all browsers to calculate the length of text within a textbox
       * @param {Object} textbox Textbox to adjust the size of
       * @param {Integer} fontSize Point size font size
       * @returns {Integer} The width of the text
       * @todo This might do well as a global function
       */
      $.fn.textWidth = function (textbox, fontSize) {
        if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $('<span>').appendTo(document.body);
        if (!textbox.value) textbox.value = '';
        var htmlText = textbox.case === 1 ? textbox.value.toUpperCase() : textbox.value;
        htmlText = $.fn.textWidth.fakeEl.text(htmlText).html(); //encode to Html
        htmlText = htmlText.replace(/\s/g, '&nbsp;'); //replace trailing and leading spaces
        $.fn.textWidth.fakeEl.html(htmlText).css('display', 'none');
        $.fn.textWidth.fakeEl.html(htmlText).css('font-family', textbox.fontName);
        $.fn.textWidth.fakeEl.html(htmlText).css('font-size', fontSize + 'pt');
        $.fn.textWidth.fakeEl
          .html(htmlText)
          .css(
            'font-weight',
            textbox.fontWeight
              ? textbox.fontWeight === 0
                ? 'normal'
                : 'bold'
              : this.css('font-weight')
          );
        $.fn.textWidth.fakeEl
          .html(htmlText)
          .css(
            'font-style',
            textbox.fontStyle
              ? textbox.fontStyle === 0
                ? 'normal'
                : textbox.fontStyle === 1
                ? 'italic'
                : 'oblique'
              : this.css('font-style')
          );
        //Kerning - More aggressive autosizing if letter spacing is bigger than 0
        $.fn.textWidth.fakeEl
          .html(htmlText)
          .css(
            'letter-spacing',
            textbox.letterSpacing * (textbox.letterSpacing > 0 ? 1.3 : 1) + 'in'
          );
        var width = $.fn.textWidth.fakeEl.width();
        return width;
      };

      /**
       * @ngdoc function
       * @name textAutoSize
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Autosizes text in textboxes so it fits - Note that it adjusts the hidden text from the $.fn.textWidth() function
       * First it shrinks til the text fits, then it expands til its maximum size.
       * They both need to happen as when you delete text, the size should increase again.
       * @param {Object} textbox Textbox to adjust the size of
       * @param _
       * @param element
       */
      $scope.textAutoSize = function (textbox) {
        var width, input, fontSize, setFontSize;
        let widthMutiplier = (!textbox.isLengthFixed) ? 0.9 : 1;
        if (!textbox.isDropdown) {
          //Variables for the Textbox
          input = $('#' + textbox._id + '_input')[0];
          if (!input) return textbox.fontSize;
          //fontSize = parseFloat(input.style.fontSize.substring(0, input.style.fontSize.length - 2));
          fontSize = parseInt(
            input.style.fontSize.substring(0, input.style.fontSize.indexOf('pt'))
          );
          setFontSize = function (newFontSize) {
            input.style.fontSize = newFontSize + 'pt';
          };
          width = $('#' + textbox._id + '_input').innerWidth() * (textbox.editable ? widthMutiplier : 0);
        } else {
          //Variables for the Dropdown
          input = $('#' + textbox._id + '_input');
          if (!input) return textbox.fontSize;
          //fontSize = parseFloat(input.css("font-size")) * 72.0 / $scope.dpi;
          fontSize = parseInt(textbox.defaultFontSize ? textbox.defaultFontSize : textbox.fontSize);
          setFontSize = function (newFontSize) {
            input.css('font-size', newFontSize + 'pt');
          };
          width =
            (($scope.getWidth() * $scope.displayRatio) / $scope.selectedTemplate.width) *
            (textbox.width * 0.9);
        }

        if (textbox.previousValue === input.value && !textbox.isDropdown) {
          return;
        }

        var originalFontSize =
          textbox.fontSize *
          (AppSvc.getWidth($scope.selectedTemplate.width, $scope.selectedTemplate.height) /
            $scope.dpi /
            $scope.selectedTemplate.width);
        //Shrink Text until the text fits
        function shrinkText() {
          if ($('#' + textbox._id).textWidth(textbox, fontSize) > width) {
            fontSize--;
            setFontSize(fontSize);
            shrinkText();
          } else {
            //Start growing text again afterwards (this is necessary flow especially when switching between scripts i.e. latin and chinese)
            growText();
          }
        }

        //Grow Text until it's the original size again or it reaches its maximum fit
        function growText() {
          if (
            $('#' + textbox._id).textWidth(textbox, fontSize) < width &&
            fontSize < originalFontSize
          ) {
            fontSize++;
            setFontSize(fontSize);
            growText();
          } else {
            sizingFinished();
          }
        }

        function sizingFinished() {
          textbox.adjustedFontSizePct = fontSize / originalFontSize;
          if (textbox.adjustedFontSizePct > 1) {
            textbox.adjustedFontSizePct = 1;
          }
          textbox.previousValue = textbox.value;
          $scope.refresh();
        }

        function truncateOverLengthValue() {
          let textDiffLength = textbox.value.length - (textbox.previousValue ? textbox.previousValue.length : 0);
          let selectionStart = input.selectionStart - textDiffLength;

          // Delete one character in comparison in order to take one more character in textbox
          // let tempTextbox = Object.assign({}, textbox);
          // tempTextbox.value = textbox.value.substring(0, textbox.value.length - 1);

          if ($('#' + textbox._id).textWidth(textbox, fontSize) > width) {
            if (textbox.previousValue == undefined
                || textbox.previousValue === ''
                || textDiffLength > 1) {
              textbox.value = textbox.value.substring(0, textbox.value.length - 1);
              truncateOverLengthValue();
            } else {
              textbox.value = textbox.previousValue;
            }
            input.value = textbox.value;
            input.setSelectionRange(selectionStart, selectionStart);
            // truncateOverLengthValue();
          } else {
            textbox.previousValue = textbox.value;
          }
        }

        if (!textbox.isLengthFixed || textbox.isDropdown) {
          shrinkText();
        } else {
          truncateOverLengthValue(textbox.previousValue);
        }

      };

      /**
       * @ngdoc function
       * @name dropdownAutosize
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Textbox dropdowns need more calculation to resize the text
       * @param {Object} dropdown Textbox dropdown to adjust the size of
       * @param {Object} dropdownItem The chosen item in the dropdown
       */
      $scope.dropdownAutosize = (
        { textboxes },
        index,
        dropdownId,
        { value, altFontName, altFontType }
      ) => {
        $('#dropdown').hide();
        const dropdown = _.find(textboxes, ({ _id }) => _id === dropdownId);
        dropdown.value = value !== undefined ? value : dropdown.placeholder;
        dropdown.value =
          dropdown.case === 1
            ? dropdown.value.toUpperCase()
            : dropdown.case === 2
            ? dropdown.value.toLowerCase()
            : dropdown.value;
        if (!altFontName) {
          dropdown.fontName = dropdown.originalFontName;
          dropdown.fontLocation = dropdown.originalFontLocation;
          delete dropdown.fontType;
        } else {
          dropdown.fontName = altFontName;
          dropdown.fontType = altFontType;
          dropdown.fontLocation = 'global';
        }
        $(`#${dropdown._id}`).html(`<span id="${dropdown._id}_value">${dropdown.value}</span>`);
        if (dropdown.alignment === 0) {
          $(`#${dropdown._id}_value`).addClass('pull-left');
        } else if (dropdown.alignment === 2) {
          $(`#${dropdown._id}_value`).addClass('pull-right');
        }
        $scope.textAutoSize(dropdown);
      };

      /**
       * @ngdoc function
       * @name showImageDropdownModal
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Opens the Image Dropdown Modal and gets all the files
       * @param {Object} image Image that was clicked
       */
      $scope.showImageDropdownModal = function (image) {
        //$scope.selectedObject = image;
        $scope.getFiles(image.dropdownFolder);
        $('#imageDropdownModal').modal();
      };

      $scope.fonts = TemplateSvc.loadedFonts;

      /**
       * @ngdoc function
       * @name toggleDesignMode
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Entering and exiting Design mode (Monstly animations that have been timed)
       */
      $scope.toggleDesignMode = function () {
        var canvasWidth = $scope.getWidth(
          $scope.selectedTemplate.width,
          $scope.selectedTemplate.height
        );
        $('#left-design-menu').toggleClass('col-md-3 col-md-4');
        $('#data-entry').toggleClass('col-md-6 col-md-8');
        if (!$scope.editing) {
          TemplateSvc.resetHistory($scope.selectedTemplate);
          $scope.editing = true;
          $scope.selectedTemplate.textboxes.forEach(function (textbox) {
            textbox.value = '';
          });
          resetTemplate($scope.selectedTemplate);
          //Enter Design Mode
          $('#canvas').toggleClass('design');
          //$('#template').css({ overflow: 'visible' });
          $('#addButton').hide();
          var canvas = {
            width:
              (($scope.selectedTemplate.width +
                ($scope.selectedTemplate.printDirection ? 0.5 : 0)) *
                canvasWidth) /
              $scope.selectedTemplate.width,
            height:
              (canvasWidth / $scope.selectedTemplate.width) *
              ($scope.selectedTemplate.height + ($scope.selectedTemplate.printDirection ? 0 : 0.5)),
            paddingTop: $scope.selectedTemplate.printDirection
              ? 0
              : (0.25 * canvasWidth) / $scope.selectedTemplate.width,
          };
          $('#canvas').animate(canvas, 1000);
          $('#editButton').slideUp();
          $('.choice').slideUp();
          $('.template-name').slideUp(function () {
            $('.template-edit').slideDown();
            $('.right-dataentry-menu').fadeOut();
            $('.right-design-menu').fadeIn();
            $('left-design-menu').fadeIn();
            $('.data-section').slideUp();
            $scope.selectObject('tmplt', $scope.selectedTemplate);
          });
          //$('.dataentry-design').animate({ marginLeft: '0px'}, { duration: 500, queue: false });
        } else {
          $scope.editing = false;
          //Exit Design Mode
          $('.right-design-menu').fadeOut();
          $('.right-dataentry-menu').fadeIn();
          $('left-design-menu').fadeOut();
          $('.template-edit-buttons').slideUp();
          var width, height;
          width = canvasWidth;
          height = (canvasWidth / $scope.selectedTemplate.width) * $scope.selectedTemplate.height;
          $('#canvas').animate(
            {
              width: width,
              height: height,
              paddingTop: 0,
            },
            300,
            function () {
              $('#canvas').toggleClass('design');
              //$('#template').css({ overflow: 'hidden' });
            }
          );
          $('.template-edit-objects').slideUp(function () {
            $('.template-edit').slideUp(function () {
              $('.choice').slideDown();
              $('.template-name').slideDown();
              $('#editButton').slideDown();
              setTimeout(function () {
                $('#addButton').show(200, function () {
                  $('.data-section').slideDown();
                });
                $('right-dataentry-menu').fadeIn();
              }, 300);
              $scope.selectObject('tmplt', $scope.selectedTemplate);
            });
          });
          //$('.dataentry-design').animate({ marginLeft: '-' + Math.round(55 / 72 * $('#addButton').width()) + 'px'}, { duration: 500, queue: false });
        }
        $scope.$emit('editing', { edit: $scope.editing });
        refresh();
      };

      BadgeSvc.getBadges().then(function (response) {
        $scope.badges = response.data.filter(function (badge) {
          if (AppSvc.user && AppSvc.user.company.sizes) {
            return _.some(AppSvc.user.company.sizes, function (companySize) {
              return companySize === badge.code;
            });
          } else {
            return true;
          }
        });
      });

      /**
       * @ngdoc function
       * @name initTemplates
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Initializes all categories, templates and options
       */
      var initTemplates = new Promise(function (resolve, reject) {
        if (!AppSvc.user) return reject();
        //Get the templates for the user's company
        return TemplateSvc.getTemplatesByCompanyAndUser(
          AppSvc.user.company.number,
          AppSvc.user._id
        ).then(function (templates) {
          TemplateSvc.enteredData = TemplateSvc.enteredData.reverse();
          if (templates) {
            $scope.templates = TemplateSvc.templates;
            //This ensures that when you log in as another company, the template displayed in Data Entry will refresh
            var found = false;
            for (var i in $scope.templates) {
              if (
                TemplateSvc.selectedTemplate &&
                $scope.templates[i]._id === TemplateSvc.selectedTemplate._id
              ) {
                found = true;
              }
            }
            //if there is no saved selected templated in the TemplateSvc, select a specific one
            if (!TemplateSvc.selectedTemplate || (!found && TemplateSvc.selectedTemplate)) {
              var defaultTemplateUser = _.find($scope.templates, function (template) {
                return template._id === AppSvc.user.templateDefault;
              });
              var defaultTemplateCompany = _.find($scope.templates, function (template) {
                return template._id === AppSvc.user.company.templateDefault;
              });
              //if there's no default template, select the first one
              setSelectedTemplate(
                defaultTemplateUser || defaultTemplateCompany || $scope.templates[0]
              );
            } else {
              //If there is already a template that was selected previously, selected that one
              setSelectedTemplate(TemplateSvc.selectedTemplate);
            }
            refresh();
            resolve(templates);
          } else {
            //Go to home page if no templates are found
            //TODO: Some smarter handling to deal with this
            //Note: this has never been an issue as of June 2017
            $scope.$emit('popup', {
              message: 'Unable to find templates for ' + AppSvc.user.company.name,
              type: 'alert-danger',
            });
            $location.path('/');
          }
        });
      });

      $scope.$emit('editing', { edit: $scope.editing });
      initTemplates.then(
        function (templates) {
          if ($stateParams.template) {
            setSelectedTemplate($stateParams.template);
          } else if (TemplateSvc.selectedTemplate) {
            setSelectedTemplate(TemplateSvc.selectedTemplate);
          } else if (TemplateSvc.recentTemplates) {
            setSelectedTemplate(
              _.find(templates, function (template) {
                return template._id === TemplateSvc.recentTemplates[0];
              })
            );
          } else if (AppSvc.user.templateDefault) {
            setSelectedTemplate(
              _.find(templates, function (template) {
                return template._id === AppSvc.user.templateDefault;
              })
            );
          } else {
            setSelectedTemplate(templates[0]);
          }
          if ($stateParams.edit) {
            //Time-out necessary since the add button does not style properly when coming from user templates
            $scope.toggleDesignMode();
          }
        },
        function () {
          console.error('User not loaded');
          $location.path('/');
        }
      );

      $scope.selectTemplate = function (template) {
        $scope.selectedTemplate.images.forEach((image) => {
          if (image.blobURL) {
            URL.revokeObjectURL(image.blobURL);
          }
        });

        $('#templatesModal').modal('hide');
        setSelectedTemplate(template);
        $('.dataentry').animate({ opacity: 0.2 }, 200).animate({ opacity: 1 }, 500);
      };
      /**
       * @ngdoc function
       * @name setSelectedTemplate
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Triggered when the user or app selects a new template
       * @param {Object} Template Template object
       */
      function setSelectedTemplate(template) {
        if (!template) return;

        let _previousSelectedTemplate = TemplateSvc.selectedTemplate;

        //TemplateSvc.createTextbox(template)
        $scope.templates = TemplateSvc.templates;
        TemplateSvc.setSelectedTemplate(template);
        $scope.selectedTemplate = JSON.parse(JSON.stringify(TemplateSvc.selectedTemplate));
        for (var i in $scope.selectedTemplate.textboxes) {
          var defaultFontSize =
            ($scope.getWidth($scope.selectedTemplate.width, $scope.selectedTemplate.height) /
              $scope.dpi /
              $scope.selectedTemplate.badge.width) *
            $scope.selectedTemplate.textboxes[i].fontSize;
          $scope.selectedTemplate.textboxes[i].defaultFontSize = defaultFontSize;
          if ($scope.selectedTemplate.textboxes[i].isDropdown) {
            $scope.selectedTemplate.textboxes[i].value =
              $scope.selectedTemplate.textboxes[i].placeholder;
          }
        }
        //$scope.selectedObject = $scope.selectedTemplate;
        $scope.selectedObjectType = 'tmplt';

        // prevent duplicate broadcast
        if (!_previousSelectedTemplate || JSON.stringify(_previousSelectedTemplate) != JSON.stringify(template)) {
          $rootScope.$broadcast('template-selected');
        }

        $scope.selectBadge($scope.selectedTemplate.badge);
        setFocusOnFirstTextbox(template);
      }

      /**
       * @ngdoc function
       * @name canceltoggleDesignMode
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Canceling design mode will reset all templates (pull from server)
       */
      $scope.cancelDesignMode = function () {
        $scope.toggleDesignMode();
        initTemplates.then(function () {
          console.log('Edit Cancelled');
        });
      };

      /**
       * @ngdoc function
       * @name restoreTemplate
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Resets the template by pulling all templates (pull from server)
       */
      $scope.restoreTemplate = function () {
        initTemplates.then(function () {
          console.log('Restored Template');
          refresh();
        });
      };

      /**
       * @ngdoc function
       * @name undo
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Resets the template to the previous state
       */
      $scope.undo = function () {
        setSelectedTemplate(TemplateSvc.undo());
        refresh();
      };

      /**
       * @ngdoc function
       * @name undo
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Resets the template to the next state (only works with a previous redo)
       */
      $scope.redo = function () {
        setSelectedTemplate(TemplateSvc.redo());
        refresh();
      };

      /**
       * @ngdoc function
       * @name do
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Adds a redoable step
       * @param {Object} template Template
       */
      $scope.do = function () {
        TemplateSvc.do($scope.selectedTemplate);
      };

      /**
       * @ngdoc function
       * @name saveTemplate
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Save the template.
       * @param {boolean} isNew - Signifies whether Save As, or Save was clicked
       */
      $scope.saveTemplate = function (isNew) {
        /*
			if (!$scope.selectedTemplate.type || $scope.selectedTemplate.type === 'company') {
			  isNew = true;
			}
			*/
        $scope.saving = true;
        var originalBg;
        if (isNew && !$scope.newTemplateName) {
          originalBg = $('#newTemplateName').css('backgroundColor');
          $('#newTemplateName')
            .animate({ backgroundColor: '#FFB6C1' }, 200)
            .animate({ backgroundColor: originalBg }, 200);
          return;
        }
        var template;
        $scope.selectedTemplate.textboxes.forEach(function (textbox) {
          textbox.originalFontName = textbox.fontName;
          textbox.originalFontLocation = textbox.fontLocation;
          TemplateSvc.moveObject($scope.selectedTemplate, 'tbx', textbox);
        });
        $scope.selectedTemplate.images.forEach(function (image) {
          TemplateSvc.moveObject($scope.selectedTemplate, 'img', image);
          image.originalSize = {
            width: image.width,
            height: image.height,
          };
        });
        if (isNew) {
          if (!$scope.newTemplateName) {
            originalBg = $('#newTemplateName').css('backgroundColor');
            $('#newTemplateName')
              .animate({ backgroundColor: '#FFB6C1' }, 200)
              .animate({ backgroundColor: originalBg }, 200);
            return;
          }
          //If it's a "save as new" template then parse it
          template = JSON.parse(JSON.stringify($scope.selectedTemplate));
          if (AppSvc.user.order) {
            template.orders.push(user.order);
          }
          template.category = '';
          template.options = [];
          template.name = $scope.newTemplateName;
        } else {
          //Otherwise overwrite the existing template
          template = $scope.selectedTemplate;
        }
        if (template.type === 'company' || isNew) convertTemplateToUser(template);
        TemplateSvc.saveTemplate(template, true).then(
          function (savedTemplate) {
            if (isNew) {
              $scope.toggleModal('saveModal');
            }
            if (savedTemplate._id) {
              initTemplates.then(function () {
                console.log('Saved template');
              });
              $scope.newTemplateName = '';
              if (isNew) {
                if ($scope.isDefaultTemplate) {
                  $scope.isDefaultTemplate = false;
                  AppSvc.user.templateDefault = savedTemplate._id;
                  UserSvc.updateUser(AppSvc.user);
                }
                setSelectedTemplate(savedTemplate);
              } else {
                setSelectedTemplate(template);
              }
              $scope.toggleDesignMode();
              $scope.$emit('popup', {
                message: $scope.getCopy('saved'),
                type: 'alert-success',
              });
              $scope.saving = false;
            }
          },
          function (err) {
            $scope.$emit('popup', {
              message: 'Error: ' + err.message,
              type: 'alert-danger',
            });
          }
        );
      };

      const convertTemplateToUser = (template) => {
        template.type = 'user';
        template.user = AppSvc.user._id;
        template.creator = AppSvc.user._id;
        template.createDate = new Date();
        template.original = template._id;
        if (AppSvc.user.order) template.orders = [AppSvc.user.order.quoteid];
        delete template._id;
      };
      /**
       * @ngdoc function
       * @name deleteTemplate
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Delete the template.
       * @param {Object} template The template to delete
       * @todo not delete myself
       */
      $scope.deleteTemplate = function (template) {
        var question = {
          title: $scope.getCopy('delete') + ' - ' + template.name,
          question:
            $scope.getCopy('deleteTemplate') + ' ' + $scope.getCopy('messageActionIrreversible'),
          model: 'template',
          api: 'templates',
          action: 'delete',
          data: template,
          exec: function () {
            TemplateSvc.deleteTemplate(question.data).then(function (response) {
              $scope.$emit('questionExecuted', response, question);
              if (AppSvc.user.templateDefault === template._id) {
                AppSvc.user.templateDefault = '';
                UserSvc.updateUser(AppSvc.user);
              }
              initTemplates.then(function () {
                console.log('Deleted Template');
              });
              $scope.toggleDesignMode();
            });
          },
        };
        $scope.askQueryQuestion(question);
      };

      /**
       * @ngdoc function
       * @name selectTmplt
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Select the template in design mode (display template properties)
       * Not to be confused with selectTemplate as that function is used to display a different template
       */
      $scope.selectTmplt = function () {
        if (!$scope.selectingObject && !$scope.resizingObject && $scope.editing) {
          $scope.selectedObjectType = 'tmplt';
          //$scope.selectedObject = $scope.selectedTemplate;
        } else if ($scope.selectingObject && !$scope.resizingObject) {
          $scope.selectingObject = false;
        }
      };

      /**
       * @ngdoc function
       * @name selectObject
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Select an object on the template (image, textbox or resizer)
       * @param {String} type The type of the clicked object
       * @param {Object} object The actual object selected
       */
      $scope.selectObject = (type, object) => {
        if (type !== 'resizer') {
          $scope.selectingObject = true;
          $scope.selectedObjectType = type;
          $scope.selectedObjectIndex = object
            ? _.findIndex(
                type === 'tbx' ? $scope.selectedTemplate.textboxes : $scope.selectedTemplate.images,
                ({ _id }) => object._id === _id
              )
            : -1;
          if ($scope.selectedObjectIndex >= 0 && type !== 'tmplt') {
            $('.rotation').bootstrapSlider({
              tooltip: 'hide',
              ticks:
                AppSvc.user.company.mode === 'pdfkit'
                  ? [-180, -135, -90, -45, 0, 45, 90, 135, 180]
                  : [-180, -90, 0, 90, 180],
              ticks_snap_bounds: AppSvc.user.company.mode === 'pdfkit' ? 3 : 90,
              step: AppSvc.user.company.mode === 'pdfkit' ? 1 : 90,
            });
            $('.rotation').bootstrapSlider(
              'setValue',
              $scope.selectedObject().rotation ? $scope.selectedObject().rotation : 0
            );
            $('.rotation').on('slideStart', (ev) => {
              $scope.do();
            });
            $('.rotation').on('slideStop', ({ value }) => {
              $scope.changeSelectedObject({
                rotation: value,
              });
            });
            //Add listeners to the slide event so the object rotates on slide
            $('.rotation').on('slide', ({ value }) => {
              $scope.changeSelectedObject({
                rotation: value,
              });
            });
            //Add listeners to the change event so the object rotates on click
            $('.rotation').on('change', ({ value }) => {
              $scope.changeSelectedObject({
                rotation: value.newValue,
              });
              refresh();
            });
            if (type === 'tbx') {
              $('.letterSpacing').bootstrapSlider({
                tooltip: 'hide',
                ticks: [-0.05, 0, 0.05],
                step: 0.01,
              });
              $('.letterSpacing').bootstrapSlider(
                'setValue',
                $scope.selectedObject().letterSpacing ? $scope.selectedObject().letterSpacing : 0
              );
              $('.letterSpacing').on('slideStart', (ev) => {
                $scope.do();
              });
              //Add listeners to the slide event so letter spacing adjusts on slide
              $('.letterSpacing').on('slide', ({ value }) => {
                $scope.changeSelectedObject({
                  letterSpacing: value,
                });
                refresh();
              });
              //Add listeners to the change event so the object rotates on click
              $('.letterSpacing').on('change', ({ value }) => {
                $scope.changeSelectedObject({
                  letterSpacing: value.newValue,
                });
                refresh();
              });
            }
          }
          refresh();
        }
      };

      /**
       * @ngdoc function
       * @name selectFont
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Select a font for a textbox
       * @param {Object} font Font
       */
      $scope.selectFont = function (font) {
        $scope.do();
        $scope.changeSelectedObject({
          fontName: font.fontName,
          fontLocation: font.fontLocation,
        });
        $scope.toggleModal('font-modal');
      };

      /**
       * @ngdoc function
       * @name startResizeObject
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Resize function, works in conjunction with ng/controllers/components/objectresizers.ctrl.js
       * I had to put these functions here because using a resizer automatically selected the template after resizing -> very annoying
       */
      $scope.startResizeObject = function () {
        $scope.do();
        $scope.resizingObject = true;
        //objectBeingResized = $scope.selectedObject;
        //objectTypeBeingResized = $scope.selectedObjectType;
        $scope.changeSelectedObject({
          originalDragDimensions: {
            top: $(
              '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
            ).position().top,
            left: $(
              '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
            ).position().left,
            width: $(
              '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
            ).width(),
            height: $(
              '#' + $scope.selectedObject()._id + '_' + $scope.selectedObjectType + 'div_edit'
            ).height(),
          },
        });
      };

      /**
       * @ngdoc function
       * @name stopResizeObject
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Executes after resizing is over
       */
      $scope.stopResizeObject = function () {
        //$scope.selectObject(objectTypeBeingResized, objectBeingResized);
        $scope.resizingObject = false;
        refresh();
      };

      $scope.changeSelectedObject = function (attributes) {
        if (!$scope.selectedTemplate || $scope.selectedObjectType === 'tmplt') return;
        if ($scope.selectedObjectType === 'tbx')
          $scope.selectedTemplate.textboxes[$scope.selectedObjectIndex] = {
            ...$scope.selectedTemplate.textboxes[$scope.selectedObjectIndex],
            ...attributes,
          };
        else
          $scope.selectedTemplate.images[$scope.selectedObjectIndex] = {
            ...$scope.selectedTemplate.images[$scope.selectedObjectIndex],
            ...attributes,
          };
      };

      $scope.selectedObject = function () {
        if (!$scope.selectedTemplate) return {};
        if ($scope.selectedObjectType !== 'tmplt') {
          return $scope.selectedObjectType === 'tbx'
            ? $scope.selectedTemplate.textboxes[$scope.selectedObjectIndex]
            : $scope.selectedTemplate.images[$scope.selectedObjectIndex];
        } else {
          return $scope.selectedTemplate;
        }
      };

      /**
       * @ngdoc function
       * @name toggleImageModal
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Toggles the Image select modal and gets the files to display in the process
       */
      $scope.toggleImageModal = function () {
        $scope
          .getFiles(
            $scope.selectedObject().isDropdown ? $scope.selectedObject().dropdownFolder : ''
          )
          .then(function () {
            if ($scope.selectedObject().isDropdown) {
              $scope.selectedDropdownFolder = $scope.selectedObject().dropdownFolder;
            }

            $scope.getImageFolders().then(function () {
              $scope.imageSelector = $scope.selectedObject;
              $scope.toggleModal('image-select-modal');
            });
          });
      };

      /**
       * @ngdoc function
       * @name toggleTextboxDropdown
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Toggles the textbox dropdown options modal
       * @param {Object} textbox Server no purpose at this Point
       * @todo remove parameter from this function
       */
      $scope.toggleTextboxDropdown = function (textbox) {
        $('#dropdown').show();
      };

      $scope.checkFontColor = function (color) {
        if (!color) return '';
        var r = parseInt(color.substring(1, 3), 16);
        var g = parseInt(color.substring(3, 5), 16);
        var b = parseInt(color.substring(5), 16);
        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        var hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
        if (hsp > 200) {
          return 'Please be aware that light colors may not be visible when printing';
        } else {
          return '';
        }
      };

      /**
       * @ngdoc function
       * @name getFiles
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Get all the files for the user
       * @param {String} dir If there is a dropdown folder chosen, a directory is passed
       */
      $scope.getFiles = function (dir) {
        return FileSvc.getUserImages(AppSvc.user, dir).then(function (response) {
          $scope.files = response.data;
        });
      };

      /**
       * @ngdoc function
       * @name getImageFolders
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Get all the folders (for image dropdowns) for the company
       */
      $scope.getImageFolders = function () {
        return FileSvc.getImageFolders(AppSvc.user.company.number).then(function (response) {
          $scope.folders = response.data;
        });
      };

      //Get the source of the image (dropdown / uploaded image / static image)
      $scope.getImageSrc = function (image) {
        if (AppSvc.user) {
          return TemplateSvc.getImageSrc(
            $stateParams.company ? $stateParams.company : AppSvc.user.company.number,
            image,
            $stateParams.user ? $stateParams.user : AppSvc.user._id
          );
        }
      };

      /**
       * @ngdoc function
       * @name uploadImage
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Upload an image, 2MB max
       * @param {Object} file image file
       * @param {Object} image image object on the template
       */
      $scope.uploadImage = function (file, image) {
        if (!file) return;
        if (file.size / 1024 / 1024 > 2) {
          $scope.$apply(function () {
            return $scope.$emit('popup', {
              message: $scope.getCopy('messageFileSizeError2MB'),
              type: 'alert-danger',
            });
          });
        } else {
          image.loading = true;
          var reader = new FileReader();
          reader.onerror = function (e) {
            $scope.$apply(function () {
              return $scope.$emit('popup', {
                message: 'Error: ' + e,
                type: 'alert-danger',
              });
            });
            image.loading = false;
          };
          reader.onload = imageIsLoaded(image);
          var name =
            AppSvc.user._id +
            '_' +
            this.$id +
            '_' +
            this.$index +
            file.name.substring(file.name.lastIndexOf('.'));
          reader.readAsDataURL(file);
          //FileSvc.uploadFileToUrl(file, 'youwho', {name : name, overwrite : true})
        }
      };

      /**
       * @ngdoc function
       * @name imageIsLoaded
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * function that executes after an image is uploaded
       * @param {Object} image image object on the template
       */
      function imageIsLoaded(image) {
        return function (e) {
          image.file = e.target.result;
          image.loading = false;
          image.class = TemplateSvc.addImageCID(e.target.result);
          var width = Math.round($('#' + image._id + '_imgdiv_dataentry').width() + 4);
          var height = Math.round($('#' + image._id + '_imgdiv_dataentry').height() + 4);
          image.originalSize = {
            width: (width * $scope.selectedTemplate.badge.width) / $('#template').width(),
            height: (height * $scope.selectedTemplate.badge.height) / $('#template').height(),
          };
          refresh();
        };
      }

      /**
       * @ngdoc function
       * @name addTextbox
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Add a textbox to the template
       */
      $scope.addTextbox = function () {
        $scope.selectedTemplate.textboxes.push({
          _id: ObjectId().toString(),
          placeholder: 'Name',
          height: 0.3,
          width: 1.0,
          topOffset: 0.1,
          leftOffset: 0.1,
          case: 0,
          editable: true,
          fontName: 'Roboto',
          fontSize: 12,
          fontLocation: 'global',
          fontWeight: 0,
          fontStyle: 0,
          fontColor: '#000000',
          alignment: 0,
          rotation: 0,
          print: true,
          isEmptyAllowed: false,
          isDropdown: false,
          dropdownItems: [],
          index: getNewLayerIndex(),
        });
      };

      /**
       * @ngdoc function
       * @name addImage
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Add a image to the template
       */
      $scope.addImage = function () {
        $scope.selectedTemplate.images.push({
          _id: ObjectId().toString(),
          name: 'default.png',
          type: 'company',
          height: 0.5,
          width: 0.5,
          topOffset: 0.1,
          leftOffset: 0.1,
          rotation: 0,
          print: true,
          keepAspectRatio: true,
          editable: false,
          isBackground: false,
          isDropdown: false,
          dropdownFolder: {
            name: 'flags',
            dir: 'flags',
            isCurated: true,
          },
          index: getNewLayerIndex(),
        });
        console.log($scope.selectedTemplate.images[$scope.selectedTemplate.images.length - 1]);
        $scope.getFiles('').then(function () {
          //Open the image modal straight away
          $scope.selectObject(
            'img',
            $scope.selectedTemplate.images[$scope.selectedTemplate.images.length - 1]
          );
          $('#image-select-modal').modal();
        });
      };

      /**
       * @ngdoc function
       * @name templateBackground
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Gets the background image of the template (if any)
       * @returns {Object} the background image object
       */
      $scope.templateBackground = function () {
        if (!$scope.selectedTemplate) return undefined;
        var background = _.find($scope.selectedTemplate.images, (image) => image.isBackground);
        return background;
      };

      // /**
      //  * @ngdoc function
      //  * @name addLayer
      //  * @methodOf npgc.controller:DataEntryCtrl
      //  * @description
      //  * Adds a new layer to the layers and positions the other layers appropriately
      //  * Keeps a potential background image in mind -> it puts it as the lowest layer unless there is a backgroun image present
      //  * @returns {Integer} the layer number
      //  */
      //
      // function addLayer(isTextbox) {
      //   var background = $scope.templateBackground();
      //   var lowestIndex = 0;
      //   if (!isTextbox) {
      //     //An image will be put below the lowest textbox so text will initially always be on top of images
      //     var objects = $scope.getObjects();
      //     lowestIndex = objects.reduce((lowest, obj) => {
      //       return obj.hasOwnProperty('fontName') && obj.index < lowest ? obj.index : lowest;
      //     }, objects.length);
      //     $scope.getObjects().forEach(function (obj) {
      //       if (!background || background.index !== obj.index) {
      //         if (obj.index >= lowestIndex) {
      //           obj.index++;
      //         }
      //       }
      //     });
      //   }
      //   if (background) {
      //     return isTextbox ? $scope.getObjects().length + 1 : lowestIndex;
      //   } else {
      //     return isTextbox ? $scope.getObjects().length + 1 : lowestIndex - 1;
      //   }
      // }

      function getNewLayerIndex() {
        let objects = $scope.getObjects();
        if (objects.length == 0) {
          return 1;
        }
        let largestIndexComponent = objects.reduce((max, e) => max.index > e.index ? max : e);
        return largestIndexComponent.index + 1;
      }

      /**
       * @ngdoc function
       * @name removeLayer
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Removes a layer and positions the other layers appropriately
       * @param {Object} object Textbox or Image
       */
      function removeLayer(object) {
        var oldIndex = object.index;
        $scope.getObjects().forEach(function (obj) {
          if (obj.index > oldIndex) {
            obj.index--;
          }
        });
      }

      /**
       * @ngdoc function
       * @name deleteObject
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Delete an object from the template
       * @param {Object} object Textbox or Image
       */
      $scope.deleteObject = function (object) {
        $scope.selectObject('tmplt', $scope.selectedTemplate);
        if (object.placeholder) {
          $scope.selectedTemplate.textboxes.splice(
            _.findIndex($scope.selectedTemplate.textboxes, (textbox) => textbox._id === object._id),
            1
          );
        } else {
          $scope.selectedTemplate.images.splice(
            _.findIndex($scope.selectedTemplate.images, (image) => image._id === object._id),
            1
          );
        }
        removeLayer(object);
        refresh();
      };

      /**
       * @ngdoc function
       * @name setPrintDirection
       * @methodOf npgc.controller:DataEntryCtrl
       * @description
       * Changes the orientation of the badge (vertical or horizontally printed)
       * Animates this change
       */
      $scope.setPrintDirection = function () {
        if ($scope.editing && $scope.selectedObjectType === 'tmplt') {
          $scope.do();
          $scope.selectedTemplate.printDirection = !$scope.selectedTemplate.printDirection;
          /*if ($scope.selectedTemplate.printDirection) {
				  width = ($scope.selectedTemplate.badge.width + 0.5) * $scope.getWidth() / $scope.selectedTemplate.badge.width;
				  height = $scope.getWidth() / $scope.selectedTemplate.badge.width * $scope.selectedTemplate.badge.height;
				  paddingTop = 0;
				} else {
				  width = $scope.getWidth() / $scope.selectedTemplate.badge.width * $scope.selectedTemplate.badge.height;
				  height = ($scope.selectedTemplate.badge.width + 0.5) * $scope.getWidth() / $scope.selectedTemplate.badge.width;
				  paddingTop = 0.25 * $scope.getWidth() / $scope.selectedTemplate.badge.width;
				}*/
          var width, height;
          if ($scope.selectedTemplate.printDirection) {
            width = $scope.selectedTemplate.badge.width;
            height = $scope.selectedTemplate.badge.height;
          } else {
            width = $scope.selectedTemplate.badge.height;
            height = $scope.selectedTemplate.badge.width;
          }
          var canvasWidth = $scope.getWidth(width, height);
          $('#canvas').animate(
            {
              width:
                ((width + ($scope.selectedTemplate.printDirection ? 0.5 : 0)) * canvasWidth) /
                width,
              height:
                (canvasWidth / width) *
                (height + ($scope.selectedTemplate.printDirection ? 0 : 0.5)),
              paddingTop: $scope.selectedTemplate.printDirection ? 0 : (0.25 * canvasWidth) / width,
            },
            300
          );
          $('.template').animate(
            {
              width: (width * canvasWidth) / width,
              height: (canvasWidth / width) * height,
            },
            300,
            function () {
              $scope.selectedTemplate.textboxes.forEach((textbox) => {
                textbox.width =
                  Math.round(
                    ((textbox.width * $scope.selectedTemplate.height) /
                      $scope.selectedTemplate.width) *
                      100
                  ) / 100;
                textbox.height =
                  Math.round(
                    ((textbox.height * $scope.selectedTemplate.width) /
                      $scope.selectedTemplate.height) *
                      100
                  ) / 100;
                textbox.leftOffset =
                  Math.round(
                    ((textbox.leftOffset * $scope.selectedTemplate.height) /
                      $scope.selectedTemplate.width) *
                      100
                  ) / 100;
                textbox.topOffset =
                  Math.round(
                    ((textbox.topOffset * $scope.selectedTemplate.width) /
                      $scope.selectedTemplate.height) *
                      100
                  ) / 100;
                textbox.fontSize = Math.round(
                  (textbox.fontSize * $scope.selectedTemplate.height) /
                    $scope.selectedTemplate.width
                );
                textbox.defaultFontSize =
                  (AppSvc.getWidth($scope.selectedTemplate.width, $scope.selectedTemplate.height) /
                    $scope.dpi /
                    $scope.selectedTemplate.width) *
                  textbox.fontSize;
              });
              $scope.selectedTemplate.images.forEach((image) => {
                image.width =
                  Math.round(
                    ((image.width * $scope.selectedTemplate.height) /
                      $scope.selectedTemplate.width) *
                      100
                  ) / 100;
                image.height =
                  Math.round(
                    ((image.height * $scope.selectedTemplate.width) /
                      $scope.selectedTemplate.height) *
                      100
                  ) / 100;
                image.leftOffset =
                  Math.round(
                    ((image.leftOffset * $scope.selectedTemplate.height) /
                      $scope.selectedTemplate.width) *
                      100
                  ) / 100;
                image.topOffset =
                  Math.round(
                    ((image.topOffset * $scope.selectedTemplate.width) /
                      $scope.selectedTemplate.height) *
                      100
                  ) / 100;
              });
              $scope.selectedTemplate.width = width;
              $scope.selectedTemplate.height = height;
              refresh();
            }
          );
        }
      };

      $scope.getTextboxValues = (name) =>
        _.uniq(
          $scope
            .enteredData()
            .reduce(
              (values, tag) =>
                values.concat(
                  tag.textboxes
                    .filter((textbox) => textbox.name === name)
                    .map((textbox) => textbox.value)
                ),
              []
            ),
          (value) => value
        );

      $scope.toggle = (attribute) => {
        $scope.do();
        let object =
          $scope.selectedObjectType === 'tbx'
            ? $scope.selectedTemplate.textboxes[$scope.selectedObjectIndex]
            : $scope.selectedTemplate.images[$scope.selectedObjectIndex];
        object[attribute] = !object[attribute];
      };

      $scope.makeTemplateProof = () => {
        $scope.loading = true;
        PrintSvc.makeTemplateProof($scope.selectedTemplate).then(
          (response) => {
            var blob = new Blob([response.data], {
              type: 'application/pdf;charset=utf-8',
            });
            var fileName = `${AppSvc.user.username}_${
              $scope.selectedTemplate.name ? $scope.selectedTemplate.name : 'Untitled'
            }.pdf`;
            FileSaver.saveAs(blob, fileName);
            $scope.loading = false;
          },
          (err) => console.error
        );
      };
    }
  );
