angular.module('app')
.service('SheetSvc', function($http) {
  var svc = this;

  svc.getSheets = function () {
      return $http.get('/api/sheets');
  };

  svc.saveSheet = function (sheet) {
    return $http.post('/api/sheets/' + sheet.name, { sheet: sheet });
  };

  svc.deleteSheet = function (name) {
    return $http.delete('/api/sheets/' + name);
  };

  svc.getTemplateCountBySheet = function (name) {
    return $http.get('/api/sheets/' + name + '/count/template');
  };
});
