angular
	.module('app')
	.controller(
		'PrintCtrl',
		function (
			$scope,
			$location,
			$sce,
			AppSvc,
			UserSvc,
			TemplateSvc,
			BadgeSvc,
			SheetSvc,
			PrintSvc,
			FileSaver
		) {
			$scope.isPrinting = false;
			$scope.isRefreshing = true;
			$scope.displayBadgeNumbers = false;
			$scope.previousPage = 1;
			$scope.aligmentModalOpens = 0;
			$scope.id = 'print';
			$scope.showSpeedwayWarning = false;

			var ua = window.navigator.userAgent;
			if (ua.indexOf('MSIE ') > 0) {
				$scope.browser = 'IE';
			} else if (ua.indexOf(' rv:11.0') > 0 || ua.indexOf(' Edge') > 0) {
				$scope.browser = 'EDGE';
			} else if (ua.indexOf(' Firefox/') > 0) {
				$scope.browser = 'FIREFOX';
			} else {
				$scope.browser = 'OTHER';
			}

			//Below makes the PDF frame resizable. It is obsolete in production since the frame is hidden
			/*
		$( "#pdf-frame" ).resizable({
		  grid: 50,
		  minHeight: 350,
		  minWidth: 800,
		});
		*/

			//Below picks up CTRL + P and executes the built in print command instead
			$(document).bind('keyup keydown', function (e) {
				if (e.ctrlKey && e.keyCode === 80) {
					$scope.print();
					return false;
				}
			});

			//Print Control INIT function
			if (!AppSvc.user) {
				$location.path('/');
			} else if (TemplateSvc.enteredData.length === 0) {
				$location.path('/dataentry');
				$scope.$emit('popup', {
					message: $scope.getCopy('messageEnterData'),
					type: 'alert-danger',
				});
			} else {
				//This reverse the display order, not sure why this was necessary but it works
				$scope.enteredData = TemplateSvc.enteredData.reverse();
				TemplateSvc.isDataReversing = true;
				$scope.selectedBadge = BadgeSvc.selectedBadge;
				$scope.printOrientation = AppSvc.user.company.isSheetBlank;
				SheetSvc.getSheets().then(function (response) {
					$scope.sheets = response.data;
					//This makes the dropdown lists in the print window based on the data the user entered and what sheets are available in the DB
					//It uses underscore.js to filter the array to unique values
					$scope.papers = _.uniq(
						$scope.sheets.map(function (sheet) {
							return sheet.paper;
						}),
						function (paper) {
							return paper._id;
						}
					);
					$scope.badges = _.uniq(
						$scope.enteredData.map(function (data) {
							return data.badge;
						}),
						function (badge) {
							return badge._id;
						}
					);

					//Select the company default sheet
					// if login type is not Single Login, pick the setting of user, else company.
					let defaultPaper = (AppSvc.user.company.loginType !== 0) ? AppSvc.user.paperDefault : AppSvc.user.company.paperDefault;

					$scope.selectPaper(
						_.find($scope.papers, function (paper) {
							return paper.name.toLowerCase() === defaultPaper.toLowerCase();
						})
					);
				});
			}

			//Function for when the starting badge is changed
			$scope.selectStartingBadge = function (badge, number) {
				BadgeSvc.startingBadge[badge.code] = number;
				$scope.refreshPdf();
			};

			$scope.startingBadge = function () {
				return BadgeSvc.startingBadge;
			};
			//Function that checks if the paper choice buttion should display
			//i.e. if A4 does not exists for a specific badge size, you should not be able to select the paper
			$scope.paperChoiceExists = function () {
				return !$scope.sheets
					? false
					: $scope.sheets.filter(function (sheet) {
							return sheet.badge._id === $scope.selectedBadge._id;
					  }).length > 1;
			};

			//Function for when the paper size is changed
			$scope.selectPaper = function (paper) {
				if (!$scope.paperChoiceExists()) {
					paper = $scope.sheets.filter(function (sheet) {
						return sheet.badge._id === $scope.selectedBadge._id;
					})[0].paper;
				}
				/*
			//This injects the page size into css. Since we have multiple styles of sheet this is needed to switch between A4 and letter
			//This is only need when using CSS3 media print. Currently obsolete since we're using a PDF instead
			var cssPagedMedia = (function () {
			  var style = document.createElement('style');
			  document.head.appendChild(style);
			  return function (rule) {
			      style.innerHTML = rule;
			  };
			}());
			cssPagedMedia.size = function (size) {
			    cssPagedMedia('@page {size: ' + size + '}');
			};
			cssPagedMedia.size(paper.width + 'in ' + paper.height + 'in');
			*/
				$scope.selectedPaper = paper;
				$scope.selectSheet();
			};

			//Function for when the badge size is changed
			$scope.selectBadge = function (badge) {
				$scope.badgeStyle = {
					width: badge.width + 'in',
					height: badge.height + 'in',
					margin: 0,
				};
				//Set the global badge selected
				BadgeSvc.setSelectedBadge(badge);
				$scope.selectedBadge = badge;
				if (!$scope.paperChoiceExists()) {
					$scope.selectPaper($scope.selectedPaper);
				} else {
					$scope.selectSheet();
				}
			};

			$scope.getColumns = function () {
				return $scope.columns;
			};
			$scope.getRows = function () {
				return $scope.rows;
			};

			$scope.getPaperName = function (paper) {
				if (!paper) return '';
				var index = _.findIndex(paper.copy, {
					language: $scope.selectedLanguage,
				});
				if (index < 0) {
					index = _.findIndex(paper.copy, { language: 'EN' });
				}
				return !paper || index < 0 ? 'Custom' : paper.copy[index].name;
			};

			$scope.getBadgeName = function (badge) {
				if (!badge) return '';
				var index = _.findIndex(badge.copy, {
					language: $scope.selectedLanguage,
				});
				if (index < 0) {
					index = _.findIndex(badge.copy, { language: 'EN' });
				}
				return !badge ? '' : badge.copy[index].name;
			};

			//Function triggered when either the paper or badge size is changed
			$scope.selectSheet = function () {
				$scope.selectedSheet = $scope.sheets.filter(function (sheet) {
					return (
						sheet.paper._id === $scope.selectedPaper._id &&
						sheet.badge._id === BadgeSvc.selectedBadge._id
					);
				})[0];
				$scope.badgesPerSheet = $scope.selectedSheet.rows * $scope.selectedSheet.columns;
				$scope.totalBadges =
					BadgeSvc.getFilteredBadges(TemplateSvc.enteredData).length +
					BadgeSvc.startingBadge[$scope.selectedBadge.code] -
					1;
				$scope.currentPage = 1;

				$scope.paperStyle = {
					width:
						($scope.selectedSheet.orientation
							? $scope.selectedPaper.width
							: $scope.selectedPaper.height) + 'in',
					height:
						($scope.selectedSheet.orientation
							? $scope.selectedPaper.height
							: $scope.selectedPaper.width) + 'in',
					margin: 0 + ' !important',
					padding: 0 + ' !important',
				};

				var radius = getRadius();

				//Style the starting badge dropdown
				$scope.startingBadgeStyle = {
					margin: 0,
					padding: 0,
					'border-top-left-radius': radius,
					'border-top-right-radius': radius,
					'border-bottom-left-radius':
						$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
					'border-bottom-right-radius':
						$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
					width: 100 / $scope.selectedSheet.columns + '%',
					border: '1px solid #ccc',
				};
				//This is a bit of an ugly calculation, the reason it's here is because the modal collapses if you don't specify a specific height.
				//The calculation makes sure there is enough space depending on the badge you chose
				$('#printAlignmentModalBody').height(
					((BadgeSvc.selectedBadge.height + $scope.selectedSheet.topOffset * 2) /
						BadgeSvc.selectedBadge.width) *
						($scope.getWidth() * 0.6) +
						150 +
						'px'
				);

				$scope.columns = new Array($scope.selectedSheet.columns);
				$scope.rows = new Array($scope.selectedSheet.rows);

				$scope.horizontalRange = Math.round($scope.selectedSheet.leftOffset * 10000) / 10000;
				$scope.verticalRange = Math.round($scope.selectedSheet.topOffset * 10000) / 10000;

				$('#verticalAlignment').bootstrapSlider('setAttribute', 'ticks', [
					-$scope.verticalRange,
					0,
					$scope.verticalRange,
				]);
				$('#horizontalAlignment').bootstrapSlider('setAttribute', 'ticks', [
					-$scope.horizontalRange,
					0,
					$scope.horizontalRange,
				]);

				$('#verticalAlignment').bootstrapSlider('refresh');
				$('#horizontalAlignment').bootstrapSlider('refresh');

				$scope.refreshPdf();
			};

			//Static sheet style, needed so it overlays with the Badge correctly
			$scope.getSheetLocationStyle = function () {
				if (!BadgeSvc.selectedBadge || !$scope.selectedSheet) return {};
				var radius = getRadius();
				return {
					'margin-top':
						(($scope.selectedSheet.topOffset / BadgeSvc.selectedBadge.width) *
							($scope.getWidth() * 0.6)) /
							2 +
						'px',
					width: $scope.getWidth() * 0.6 + 'px',
					height:
						(BadgeSvc.selectedBadge.height / BadgeSvc.selectedBadge.width) *
							($scope.getWidth() * 0.6) +
						'px',
					'-webkit-transform': 'translate(50%, 50%)',
					transform: 'translate(50%, 50%)',
					'border-top-left-radius': radius,
					'border-top-right-radius': radius,
					'border-bottom-left-radius':
						$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
					'border-bottom-right-radius':
						$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
				};
			};

			//Dynamic badge style, gets updated when the sliders move
			$scope.getBadgeLocationStyle = function () {
				if (!BadgeSvc.selectedBadge || !$scope.selectedSheet) return {};
				var radius = getRadius();
				return {
					'margin-top':
						(($scope.selectedSheet.topOffset / BadgeSvc.selectedBadge.width) *
							($scope.getWidth() * 0.6)) /
							2 +
						'px',
					width: $scope.getWidth() * 0.6 + 'px',
					height:
						(BadgeSvc.selectedBadge.height / BadgeSvc.selectedBadge.width) *
							($scope.getWidth() * 0.6) +
						'px',
					'-webkit-transform':
						'translate(' +
						(50 + -$scope.horizontalMove * 100) +
						'%, ' +
						(50 + -$scope.verticalMove * 100) +
						'%)',
					transform:
						'translate(' +
						(50 + -$scope.horizontalMove * 100) +
						'%, ' +
						(50 + -$scope.verticalMove * 100) +
						'%)',
					opacity: 0.7,
					'border-top-left-radius': radius,
					'border-top-right-radius': radius,
					'border-bottom-left-radius':
						$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
					'border-bottom-right-radius':
						$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
				};
			};

			//Dynamic column style
			$scope.getColumnStyle = function (column) {
				return {
					float: 'left',
					width: $scope.selectedSheet.badge.width - 0.015 + 'in',
					'margin-left': (column !== 0 ? $scope.selectedSheet.horizontalGap : 0) + 'in',
					height: '100%',
				};
			};

			function getRadius() {
				switch ($scope.selectedSheet.badge.shape) {
					case 'Rectangle':
						return '3px';
					case 'Oval':
						return '50%';
					case 'Half Oval':
						return '50% 100%';
					default:
						return '3px';
				}
			}

			//Dynamic badge style, for some reason I had to use margin instead of padding in the badge
			$scope.getBadgeStyle = function (row, column) {
				var template = $scope.getBadge(row, column);
				var printDirection = true;
				if (template) {
					printDirection = template.printDirection;
				}
				var radius = getRadius();
				return {
					width: $scope.selectedSheet.badge.width + 'in',
					height: $scope.selectedSheet.badge.height + 'in',
					display: 'block',
					'margin-top': (row !== 0 ? $scope.selectedSheet.verticalGap : 0) + 'in',
				};
			};

			$scope.alignmentModal = function () {
				$scope.aligmentModalOpens++;
				$scope.alignmentBadge = BadgeSvc.getFilteredBadges(TemplateSvc.enteredData)[0];
				$scope.toggleModal('printAlignmentModal');
			};

			$scope.getPrintDirection = function (printDirection, element) {
				if (!$scope.selectedSheet) {
					return {};
				}
				var radius = getRadius();
				var width, height;
				if (!element) {
					width = $scope.selectedSheet.badge.width;
					height = $scope.selectedSheet.badge.height;
				} else {
					width = $('#' + element).width();
					height = $('#' + element).height();
				}
				if (printDirection) {
					return {
						width: '100%',
						height: '100%',
						'border-top-left-radius': radius,
						'border-top-right-radius': radius,
						'border-bottom-left-radius':
							$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
						'border-bottom-right-radius':
							$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
					};
				} else {
					return {
						'-webkit-transform':
							'rotate(90deg) translate(' +
							(height - width) / 2 +
							'px, ' +
							(height - width) / 2 +
							'px)',
						'-moz-transform':
							'rotate(90deg) translate(' +
							(height - width) / 2 +
							'px, ' +
							(height - width) / 2 +
							'px)',
						'-o-transform':
							'rotate(90deg) translate(' +
							(height - width) / 2 +
							'px, ' +
							(height - width) / 2 +
							'px)',
						'-ms-transform':
							'rotate(90deg) translate(' +
							(height - width) / 2 +
							'px, ' +
							(height - width) / 2 +
							'px)',
						transform:
							'rotate(90deg) translate(' +
							(height - width) / 2 +
							'px, ' +
							(height - width) / 2 +
							'px)',
						width: (100 * height) / width + '%',
						height: (100 * width) / height + '%',
						'border-top-left-radius': radius,
						'border-top-right-radius': radius,
						'border-bottom-left-radius':
							$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
						'border-bottom-right-radius':
							$scope.selectedSheet.badge.shape.indexOf('Half') >= 0 ? '0px 0px' : radius,
					};
				}
				/*
			    return printDirection ? {
			      'width': '100%',
			      'height': '100%',
			    } : {
			      '-webkit-transform': 'rotate(90deg) translate(' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in, ' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in)',
			      '-moz-transform': 'rotate(90deg) translate(' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in, ' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in)',
			      '-o-transform': 'rotate(90deg) translate(' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in, ' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in)',
			      '-ms-transform': 'rotate(90deg) translate(' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in, ' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in)',
			      'transform': 'rotate(90deg) translate(' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in, ' + ($scope.selectedSheet.badge.height - $scope.selectedSheet.badge.width) / 2 + 'in)',
			      'width': (100 * $scope.selectedSheet.badge.height / $scope.selectedSheet.badge.width) + '%',
			      'height': (100 * $scope.selectedSheet.badge.width / $scope.selectedSheet.badge.height) + '%'
			    };*/
			};
			/*
		$scope.getCanvasStyle = function(template, modifier) {
		  if (template) {
		    return TemplateSvc.getCanvasStyle(template, $scope.getWidth(), modifier, $scope.editing);
		  } else {

		  }
		};
		*/

			//Function to get the style of text within badges
			$scope.getTextStyle = function (template, element) {
				return TemplateSvc.getTextStyle(template, element, 1);
			};

			//Function to get the style of text within the alignment modal
			$scope.getAlignmentTextStyle = function (template, element) {
				return TemplateSvc.getTextStyle(
					template,
					element,
					($scope.getWidth() * 0.6) / $scope.dpi / template.badge.width
				);
			};

			//Function to get the style of images
			$scope.getImageStyle = function (template, element) {
				var imageStyle = TemplateSvc.getImageStyle(template, element);
				imageStyle.opacity = element.print ? (element.isBackground ? 0.8 : 1) : 0.2;
				imageStyle.borderRadius = 0;
				return imageStyle;
			};

			//Get the source of the image (dropdown / uploaded image / static image)
			$scope.getImageSrc = function (image) {
				if (AppSvc.user) {
					const src = TemplateSvc.getImageSrc(AppSvc.user.company.number, image, AppSvc.user._id);
					return src;
				}
			};

			//Returns the appropriate aspect ratio
			$scope.getImageAspectRatio = function (image, location) {
				return TemplateSvc.getImageAspectRatio(image, location);
			};

			$scope.printing = function () {
				return $scope.isPrinting;
			};

			$scope.refreshing = function () {
				return $scope.isRefreshing;
			};

			//Checks whether the print orientation slider is clicked
			$scope.setPrintOrientation = function (boolean) {
				$scope.printOrientation = boolean;
				//if statement prevents this from executing on load
				if ($scope.selectedSheet) {
					$scope.refreshPdf();
				}
			};

			var specialElementHandlers = {
				'#jspdf': function (element, renderer) {
					return true;
				},
			};
			/*
		$('#pdf').on('load', function(){
		  //your code (will be called once iframe is done loading)
		  $scope.isRefreshing = false;
		  $scope.refresh();
		  $('#pdf-frame').css('marginTop', '0px');
		});
		document.getElementById('pdf').onload = function() {
		  console.log('Frame element loaded');
		  $scope.isRefreshing = false;
		  $scope.refresh();
		  $('#pdf-frame').css('marginTop', '0px');
		};*/

			$scope.popUpAndPrint = function () {
				var container = $('#svgOutput');
				var width = parseFloat($scope.selectedSheet.paper.width);
				var height = parseFloat($scope.selectedSheet.paper.height);

				var printWindow = window.open(
					'',
					Math.floor(Math.random() * 1000000) + '.svg',
					'width="' + width + 'in",height="' + height + 'in"'
				);
				printWindow.document.body.style.margin = '0px 0px 0px 0px';
				printWindow.document.writeln(document.getElementById('svgOutput').innerHTML);
				$(printWindow.document.head).append(
					'<script>function beforePrint(){var e=document.getElementById("svg");document.body.style.visibility="hidden",e.style.visibility="visible",e.style.position="absolute",e.style.top="0px",e.style.left="0px",console.log("before print")}function afterPrint(){var e=document.getElementById("svg");document.body.style.visibility="",e.style.visibility="",e.style.position="",e.style.top="",e.style.left="",console.log("after print")}var ua=window.navigator.userAgent,msie=ua.indexOf("MSIE "),edge=ua.indexOf(" rv:11.0"),firefox=ua.indexOf(" Firefox/");if(msie>0||edge>0||firefox>0)window.onbeforeprint=beforePrint,window.onafterprint=afterPrint;else if(console.log("here"),window.matchMedia){var mediaQueryList=window.matchMedia("print");mediaQueryList.addListener(function(e){e.matches?beforePrint():afterPrint()})}</script>'
				);
				setTimeout(function () {
					printWindow.document.close();
					printWindow.focus();
					printWindow.print();
					//printWindow.close();
					/*
				var ua = window.navigator.userAgent;
				var msie = ua.indexOf ( "MSIE " );
				var edge = ua.indexOf ( " rv:11.0" );
				var firefox = ua.indexOf ( " Firefox/" );
				if ( msie > 0 || edge > 0) {

				  //If Internet Explorer, return version number
				  //If IE the print command needs to be handled differently (new window)

				  //var pdfPrinter = window.open(pdfFrame.src, '_blank', 'fullscreen=yes');
				  printWindow.document.close();
				  printWindow.focus();
				  printWindow.print();
				  printWindow.close();*/
					//pdfPrinter.close();
					/*
				$('#iePDFObject').html(
				    '<object id="iePdf" onreadystatechange="iePrintPDF()"'+
				        'width="300" height="400" type="application/pdf"' +
				        'data="' + pdfFrame.src + '?#view=Fit&scrollbar=0&toolbar=0&navpanes=0">' +
				        '<span>PDF plugin is not available.</span>'+
				    '</object>');*/
					/*  } else {
				    printWindow.print();
				    //printWindow.close();
				  }*/
				}, 500);
			};

			//$scope.PDFSource = $sce.trustAsResourceUrl('./companies/' + AppSvc.user.company.number + '/users/' + AppSvc.user._id + '/pdfs/sheet.pdf');

			//Function that triggers the PDF to refresh. This is triggered on mostly all options on the print page.
			$scope.refreshPdf = function () {
				if (!BadgeSvc.startingBadge[$scope.selectedBadge.code]) {
					BadgeSvc.startingBadge[$scope.selectedBadge.code] = 1;
				}
				$scope.isRefreshing = true;
				/*if($('#pdfFrame')) {
			  $('#pdfFrame').remove();
			}*/
				//All the settings and the entered data get sent to the API
				PrintSvc.getPDF(
					$scope.selectedSheet,
					BadgeSvc.selectedBadge,
					AppSvc.user,
					BadgeSvc.startingBadge[$scope.selectedBadge.code],
					$scope.alignment,
					$scope.printOrientation,
					AppSvc.user.pdfName,
					AppSvc.user.guest,
					undefined //This is used for Magento (order details)
				)
					.then(
						function ({ data }) {
							if (data.svg) {
								$('#svgOutput').html(data.svg);
							}
							$scope.PDFSource = `${
								data.pdf
							}?dummyVar=${new Date().getTime()}&token=${UserSvc.getToken()}`;
							$scope.refresh();
							document.getElementById('pdf').contentWindow.location.reload();
							//$scope.PDFSource = data.pdf;
							//document.getElementById('pdf').contentDocument.location.href = $scope.PDFSource;
							//document.getElementById('pdf').contentWindow.location.reload();
							//return $sce.trustAsResourceUrl(AppSvc.user.company.number + '/users/' + AppSvc.user._id + '/' + AppSvc.user.pdfName + '.pdf');

							//document.getElementById('pdf').src = AppSvc.user.company.number + '/users/' + AppSvc.user._id + '/' + AppSvc.user.pdfName + '.pdf';
							//$scope.trustedHtml = $sce.trustAsHtml('web/viewer.html?file=' + AppSvc.user.pdfName + '.pdf');
							//$scope.PDFSource = $sce.trustAsResourceUrl(AppSvc.user.company.number + '/users/' + AppSvc.user._id + '/' + AppSvc.user.pdfName + '.pdf');

							//Viewer Alternatives:

							//Added this in case we want to give the user a choice in the future.

							//Google pdf viewer, unfortunately at the time of coding, it had no built in print function
							//.attr('src', 'https://docs.google.com/gview?url=' + 'https://npgcloud.com/' + 'pdfs/' + response.data + '&embedded=true')

							//Office Apps Viewer. It currently doesn't support pdfs but perhaps in the future it can be used.
							//.attr('src', 'https://view.officeapps.live.com/op/embed.aspx?src=' + 'https://npgcloud.com/' + 'pdfs/' + response.data)
						},
						function (reason) {
							$scope.$emit('popup', {
								message: $scope.getCopy('messageDataProcessingError') + ': ' + reason,
								type: 'alert-danger',
							});
						}
					)
					.then(() => {
						$scope.isRefreshing = false;
						$('#pdf-frame').css('marginTop', '0px');
					});
			};

			$scope.getPDFDownloadURL = function () {
				return 'data:Application/octet-stream,' + $scope.PDFSource;
			};

			//Obsolete Printing for IE
			/*
		$scope.iePrint = function (url) {
			var iframe = this._printIframe;
			if (!this._printIframe) {
				iframe = this._printIframe = document.createElement('iframe');
				document.body.appendChild(iframe);

				iframe.style.display = 'none';
				iframe.onload = function () {
					setTimeout(function () {
						iframe.focus();
						iframe.contentWindow.print();
					}, 1);
				};
			}

			iframe.src = url;
		};
		*/
			$scope.printWarning = () => {
				$scope.showPrintWarning(() => {
					$scope.print();
				});
			};

			//Function that sends the print command to the PDF frame
			$scope.print = () => {
				//document.getElementById('jspdf').contentWindow.print();
				$scope.isPrinting = true;
				/*
			window.frames["pdf"].focus();
			window.frames["pdf"].print();
			*/

				const pdfFrame = document.getElementById('pdf');
				if ($scope.browser === 'IE' || $scope.browser === 'EDGE') {
					//If IE the print command needs to be handled differently (new window)

					const pdfPrinter = window.open(pdfFrame.src, '_blank', 'fullscreen=yes');
					pdfPrinter.focus();
					pdfPrinter.print();
					//pdfPrinter.close();
					/*
				$('#iePDFObject').html(
				    '<object id="iePdf" onreadystatechange="iePrintPDF()"'+
				        'width="300" height="400" type="application/pdf"' +
				        'data="' + pdfFrame.src + '?#view=Fit&scrollbar=0&toolbar=0&navpanes=0">' +
				        '<span>PDF plugin is not available.</span>'+
				    '</object>');*/
					/*
				      console.log('here');
				      console.log(AppSvc.user.pdfName);
				      $scope.iePrint(AppSvc.user.pdfName);*/
					//pdfFrame.contentWindow.document.execCommand("print", false, null);
					//pdfFrame.contentWindow.print();
				} else if ($scope.browser === 'FIREFOX') {
					window.open(pdfFrame.src, '_blank', 'fullscreen=yes');
				} else {
					pdfFrame.contentWindow.print();
				}
				$scope.isPrinting = false;
				PrintSvc.logPrint($scope.selectedSheet, AppSvc.user);
			};

			/* ALIGNMENT SECTION */
			$scope.horizontalMove = parseFloat(AppSvc.getOffset()[0]);
			$scope.verticalMove = parseFloat(AppSvc.getOffset()[1]);
			$scope.horizontalValue =
				-Math.round($scope.horizontalMove * BadgeSvc.selectedBadge.width * 100) / 100;
			$scope.verticalValue =
				-Math.round($scope.verticalMove * BadgeSvc.selectedBadge.height * 100) / 100;
			$scope.alignment = [$scope.horizontalMove, $scope.verticalMove];
			//These ranges should be based on the dimensions of the badge. Below values work well with MD
			$scope.horizontalRange = 20;
			$scope.verticalRange = 50;

			$scope.getVerticalAlignmentSliderHeight = function () {
				if (!BadgeSvc.selectedBadge || !$scope.selectedSheet) return {};
				return {
					'margin-top':
						(($scope.selectedSheet.topOffset / BadgeSvc.selectedBadge.width) *
							($scope.getWidth() * 0.6)) /
							2 +
						'px',
					height:
						(BadgeSvc.selectedBadge.height / BadgeSvc.selectedBadge.width) *
							($scope.getWidth() * 0.6) *
							2 +
						'px',
				};
			};

			//Create the alignment sliders
			var horizontalSlider = $('#horizontalAlignment').bootstrapSlider({
				value: $scope.horizontalValue,
				tooltip: 'hide',
				ticks: [-$scope.horizontalRange, 0, $scope.horizontalRange],
				ticks_snap_bounds: 0.01,
				step: 0.01,
			});

			var verticalSlider = $('#verticalAlignment').bootstrapSlider({
				value: $scope.verticalValue,
				orientation: 'vertical',
				tooltip: 'hide',
				ticks: [-$scope.verticalRange, 0, $scope.verticalRange],
				ticks_snap_bounds: 0.01,
				step: 0.01,
			});
			//Add listeners to the slide events so the div moves with the slide
			$('#horizontalAlignment').on('slide', function (slideEvt) {
				$scope.horizontalValue = slideEvt.value;
				$scope.horizontalMove =
					-Math.round(($scope.horizontalValue / BadgeSvc.selectedBadge.width) * 100) / 100;
				$scope.$apply();
			});

			$('#verticalAlignment').on('slide', function (slideEvt) {
				$scope.verticalValue = slideEvt.value;
				$scope.verticalMove =
					-Math.round(($scope.verticalValue / BadgeSvc.selectedBadge.height) * 100) / 100;
				$scope.$apply();
			});

			$scope.setHorizontalAlignment = function () {
				$scope.horizontalValue =
					Math.abs($scope.horizontalValue) > $scope.horizontalRange
						? $scope.horizontalValue < 0
							? -$scope.horizontalRange
							: $scope.horizontalRange
						: $scope.horizontalValue;
				$scope.horizontalMove =
					-Math.round(($scope.horizontalValue / BadgeSvc.selectedBadge.width) * 100) / 100;
				$('#horizontalAlignment').bootstrapSlider('setValue', $scope.horizontalValue);
			};

			$scope.setVerticalAlignment = function () {
				$scope.verticalValue =
					Math.abs($scope.verticalValue) > $scope.verticalRange
						? $scope.verticalValue < 0
							? -$scope.verticalRange
							: $scope.horizontalRange
						: $scope.verticalValue;
				$scope.verticalMove =
					-Math.round(($scope.verticalValue / BadgeSvc.selectedBadge.height) * 100) / 100;
				$('#verticalAlignment').bootstrapSlider('setValue', $scope.verticalValue);
			};

			//The style of the image of the sheet
			$scope.getSheetImageStyle = function () {
				return {
					position: 'absolute',
					'z-index': -1,
					height: '100%',
					width: '100%',
					left: 0,
					top: 0,
					'-webkit-transform': 'rotate(' + ($scope.printOrientation ? 0 : 180) + 'deg)',
					'-moz-transform': 'rotate(' + ($scope.printOrientation ? 0 : 180) + 'deg)',
					'-o-transform': 'rotate(' + ($scope.printOrientation ? 0 : 180) + 'deg)',
					'-ms-transform': 'rotate(' + ($scope.printOrientation ? 0 : 180) + 'deg)',
					transform: 'rotate(' + ($scope.printOrientation ? 0 : 180) + 'deg)',
				};
			};
			//Returns the sheet style as per the alignment entered in the alignment dialog
			$scope.getSheetStyle = function () {
				//Not the most graceful return but I needed it to work on short notice
				//TODO A much better solution would be to wait to return this until "selectedSheet" is initialized
				try {
					return {
						'padding-left':
							$scope.selectedSheet.leftOffset +
							$scope.selectedSheet.badge.width * $scope.horizontalMove +
							'in',
						'padding-right':
							$scope.selectedSheet.leftOffset -
							$scope.selectedSheet.badge.width * $scope.horizontalMove +
							'in',
						'padding-top':
							$scope.selectedSheet.topOffset +
							$scope.selectedSheet.badge.height * $scope.verticalMove +
							'in',
						'padding-bottom':
							$scope.selectedSheet.topOffset -
							$scope.selectedSheet.badge.height * $scope.verticalMove +
							'in',
						height:
							($scope.selectedSheet.orientation
								? $scope.selectedPaper.height
								: $scope.selectedPaper.width) + 'in',
						width:
							($scope.selectedSheet.orientation
								? $scope.selectedPaper.width
								: $scope.selectedPaper.height) + 'in',
					};
				} catch (err) {
					return {};
				}
			};

			/*
  //No longer needed since the pdf frame is now hidden

  //Needed because IE puts the frame above modals, which makes the print alignment modal appear under the frame -> solution, hide the frame when the modal is open
	$scope.moveElement = function(trigger, element) {
    var newTop = $('#' + trigger).height() + 20
    var ua = window.navigator.userAgent
    var msie = ua.indexOf ( "MSIE " )
    var edge = ua.indexOf ( " rv:11.0" )
    if ( msie > 0 || edge > 0) {     // If Internet Explorer, return version number
    	if ($('#' + element).css("margin-top") === '0px' || $('#' + element).css("margin-top") !== newTop + 'px') {
        $('#' + element).animate({
          'marginTop': newTop + 'px'
        }, 500)
    	}	else {
        $('#' + element).animate({
          'marginTop': '0px'
        }, 500)
    	}
    }
	}

  //Needed because IE puts the frame above modals, which makes the print alignment modal appear under the frame -> solution, hide the frame when the modal is open
	$scope.hideElement = function(element) {
    var ua = window.navigator.userAgent
    var msie = ua.indexOf ( "MSIE " )
    var edge = ua.indexOf ( " rv:11.0" )
    if ( msie > 0 || edge > 0) {    // If Internet Explorer, return version number
        $('#' + element).toggle()
        $('#' + element).css('margin-top', '0px')
    }
	}
  */

			$scope.cancelOffset = function () {
				$scope.horizontalMove = $scope.alignment[0];
				$scope.verticalMove = $scope.alignment[1];
			};

			$scope.resetOffset = function () {
				$scope.alignment = [0, 0];
				AppSvc.setOffset(0, 0);
				$('#horizontalAlignment').bootstrapSlider('setValue', 0);
				$('#verticalAlignment').bootstrapSlider('setValue', 0);
				$scope.horizontalValue = 0;
				$scope.verticalValue = 0;
				$scope.horizontalMove = 0;
				$scope.verticalMove = 0;
			};

			$scope.selectOffset = function (offset) {
				$scope.horizontalMove = offset.leftOffset;
				$scope.verticalMove = offset.topOffset;
				$scope.offsetName = offset.name;
			};

			$scope.saveOffset = function () {
				/*
			//Use this to save the offset server side
			if (AppSvc.user.company.loginType > 1) {
			  var originalBg;
			  if (!$scope.offsetName) {
			    originalBg = $("#offsetName").css("backgroundColor");
			    $("#offsetName").animate({ backgroundColor: "#FFB6C1" }, 200).animate({ backgroundColor: originalBg }, 200);
			    return;
			  }
			  UserSvc.saveOffset(AppSvc.user._id, $scope.horizontalMove, $scope.verticalMove, $scope.offsetName);
			}
			*/
				AppSvc.setOffset($scope.horizontalMove, $scope.verticalMove);
				$scope.alignment[0] = $scope.horizontalMove;
				$scope.alignment[1] = $scope.verticalMove;
				$scope.refreshPdf();
				$scope.toggleModal('printAlignmentModal');
			};

			//Below function is called by each visible badge in the window and returns what badge should be displayed
			$scope.getBadge = function (row, column) {
				var badges = BadgeSvc.getFilteredBadges(TemplateSvc.enteredData).length;
				var skip = BadgeSvc.startingBadge[$scope.selectedBadge.code] - 1;
				var badge =
					row * $scope.selectedSheet.columns +
					column +
					$scope.badgesPerSheet * ($scope.currentPage - 1);
				if (
					!$scope.printOrientation
						? $scope.selectedSheet.orientation
						: !$scope.selectedSheet.orientation
				) {
					badge =
						$scope.badgesPerSheet * $scope.currentPage -
						badge +
						$scope.badgesPerSheet * ($scope.currentPage - 1) -
						1;
				}
				badge = badge - skip;
				//if (printOrientation ? skip === 0 : sheets === 0 ? reverseSkip === 0 : sheets === maxSheets ? (sheet.rows * sheet.columns) - req.body.startingBadge + 1 - badge > 0 ? true : skip === 0 : true) {
				//printOrientation ? badges.length - 1 - badge : badge
				return BadgeSvc.getFilteredBadges(TemplateSvc.enteredData)[badges - 1 - badge];
				/*
			if (!BadgeSvc.getFilteredBadges(TemplateSvc.enteredData)[badges - 1 - badge]) {
			  return [{
			    badge: BadgeSvc.getFilteredBadges(TemplateSvc.enteredData)[0].badge,
			    images: [],
			    textboxes: [],
			    background: 'white'
			  }];
			} else {
			  return [BadgeSvc.getFilteredBadges(TemplateSvc.enteredData)[badges - 1 - badge]];
			}
			*/
			};

			$scope.getBadgeNo = function (row, column) {
				return (
					$scope.printOrientation
						? $scope.selectedSheet.orientation
						: !$scope.selectedSheet.orientation
				)
					? row * $scope.getColumns().length + column + 1
					: ($scope.getRows().length - row - 1) * $scope.getColumns().length +
							($scope.getColumns().length - column);
			};

			//Gets the number of sheets needed to print the badges
			$scope.getTotalSheets = function () {
				if (!$scope.selectedBadge) return 0;
				return (
					Math.floor(
						(BadgeSvc.startingBadge[$scope.selectedBadge.code] -
							1 +
							BadgeSvc.getFilteredBadges(TemplateSvc.enteredData).length -
							1) /
							$scope.badgesPerSheet
					) + 1
				);
			};

			//Gets the total number of spots that will be filled with badges
			$scope.getTotalBadges = function () {
				if (!$scope.selectedBadge) return 0;
				return (
					BadgeSvc.getFilteredBadges(TemplateSvc.enteredData).length +
					BadgeSvc.startingBadge[$scope.selectedBadge.code] -
					1
				);
			};

			//Toggles whether the numbers on the badges are displayed
			$scope.displayBadgeNumbersToggle = function () {
				$scope.displayBadgeNumbers = !$scope.displayBadgeNumbers;
			};

			//Animates the page changes
			$scope.pageChanged = function () {
				$('.sheet-data-section')
					.css({
						opacity: 0,
					})
					.animate(
						{
							opacity: 1,
						},
						400
					);
				$scope.previousPage = $scope.currentPage;
			};

			$scope.downloadPDF = () => {
				PrintSvc.getSheetPDF(
					AppSvc.user,
					AppSvc.user.magento ? 'sheet.pdf' : `${AppSvc.user.pdfName}.pdf`
				).then(response => {
					var blob = new Blob([response.data], {
						type: 'application/pdf;charset=utf-8',
					});
					var now = new Date().toISOString().substring(0, 19).replace('T', ' ').replace('_', '-');
					var fileName = AppSvc.user.magento
						? `${BadgeSvc.getFilteredBadges(TemplateSvc.enteredData).length} x ${
								$scope.selectedBadge.code
						  } - ${now}.pdf`
						: `${now}.pdf`;
					FileSaver.saveAs(blob, fileName);
				});
			};

			$scope.makePdfProof = () => {
				$scope.loading = true;
				PrintSvc.makePdfProof({
					badges: BadgeSvc.getFilteredBadges(TemplateSvc.enteredData),
					sheet: $scope.selectedSheet,
					user: AppSvc.user,
				}).then(
					response => {
						var blob = new Blob([response.data], {
							type: 'application/pdf;charset=utf-8',
						});
						var fileName = `${AppSvc.user.username}_proof.pdf`;
						FileSaver.saveAs(blob, fileName);
						$scope.loading = false;
					},
					err => console.error
				);
			};

			$scope.showSpeedwayWarning = () => {
				if (BadgeSvc.getFilteredBadges(TemplateSvc.enteredData).filter(e => e.name.toLowerCase().includes('speedway')).length > 0) {
					$scope.showSpeedwayWarning = true;
				} else {
					$scope.showSpeedwayWarning = false;
				}
			};

			$scope.showSpeedwayWarning();
		}
	);
