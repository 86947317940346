angular.module('app')
.directive('arrow', function ($interval) {
   return {
      restrict: 'A',
      link: function (scope, element, attrs) {

        var promise;

        scope.moveObject = function(object, template, direction) {
          promise = $interval(function () {
            if(direction === 'left') {
              //Left Arrow
              object.leftOffset = object.leftOffset <= 0 ? 0 : object.leftOffset - 0.02;
            } else if(direction === 'up') {
              //Up Arrow
              object.topOffset = object.topOffset <= 0 ? 0 : object.topOffset - 0.02;
            } else if(direction === 'right') {
              //Right Arrow
              object.leftOffset = object.leftOffset + object.width >= template.badge.width ? template.badge.width - object.width : object.leftOffset + 0.02;
            } else if(direction === 'down') {
              //Down Arrow
              object.topOffset = object.topOffset + object.height >= template.badge.height ? template.badge.height - object.height : object.topOffset + 0.02;
            }
          }, 100);

        };

        scope.stopObject = function () {
           $interval.cancel(promise);
        };
    }
  };
});
