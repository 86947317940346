angular.module('app')
.controller('TextboxDropdownCtrl', function ($scope, TemplateSvc) {

  $scope.sortDirection = true;
  $scope.newDropdownItemName = '';
  $scope.newDropdownItemValue = '';

  $scope.sortDropdownItems = function() {
    $scope.do();
    $scope.selectedObject().dropdownItems.sort(function (a, b) {
      return $scope.sortDirection ? (a.name > b.name ? -1 : 1) : (a.name < b.name ? -1 : 1);
    });
    $scope.sortDirection = !$scope.sortDirection;
  };

  $scope.getDropdownItemStyle = function(item) {
    if (compare(item, $scope.newDropdownItemName) === -1 && $scope.newDropdownItemName) {
      return {
        opacity: 0.7
      };
    } else if (compare(item, $scope.newDropdownItemValue) === -1 && $scope.newDropdownItemValue) {
      return {
        opacity: 0.7
      };
    }
  };

  $scope.copyNametoValue = function(name, value) {
    if (value.indexOf(name) === 0 || name.indexOf(value) === 0) {
      return name;
    } else {
      return value;
    }
    /*
    //Disabled as it makes more sense that it just copies
    if ($scope.newDropdownItemName.indexOf($scope.newDropdownItemValue) > -1 || !$scope.newDropdownItemValue || !$scope.newDropdownItemName) {
      $scope.newDropdownItemValue = $scope.newDropdownItemName;
    }
    */
  };

  function compare(item, value) {
    return !value ? 0 : item.name.toLowerCase().indexOf(value.toLowerCase());
  }

  $scope.addDropdownItem = function() {
    if (!$scope.selectedObject().dropdownItems) {
      $scope.selectedObject().dropdownItems = [];
    }

    if ($scope.newDropdownItemName) {
      $scope.do();
      $scope.selectedObject().dropdownItems.unshift({
        name: $scope.newDropdownItemName,
        value: $scope.newDropdownItemValue
      });
      $scope.newDropdownItemName = '';
      $scope.newDropdownItemValue = '';
    } else {
      var originalBg = $(".dropdown-input").css("backgroundColor");
      $(".dropdown-input").animate({ backgroundColor: "#FFB6C1" }, 200).animate({ backgroundColor: originalBg }, 200);
    }
  };

  $scope.deleteDropdownItem = function(item) {
    $scope.do();
    var index = $scope.selectedObject().dropdownItems.indexOf(item);
    if (index > -1) {
      $scope.selectedObject().dropdownItems.splice(index, 1);
    }
  };

  $scope.moveDropdownItem = function(oldIndex, newIndex) {
    $scope.do();
    moveItemInArray($scope.selectedObject().dropdownItems, oldIndex, newIndex);
  };

  $scope.saveDropdown = function() {
    TemplateSvc.saveTemplate($scope.selectedTemplate).success(function(response) {
      if (response._id) {
        $scope.$emit('popup', {
          message: $scope.getCopy('saved'),
          type: 'alert-success'
        });
      }
    });
  };

  $scope.setAltFont = function(dropdownItem, altFont) {
    if (!altFont) {
      dropdownItem.altFontName = '';
      dropdownItem.altFontType = '';
      dropdownItem.altFontDirection = true;
    } else {
      dropdownItem.altFontName = altFont.name;
      dropdownItem.altFontType = altFont.type;
      dropdownItem.altFontDirection = altFont.direction;
    }
  };

  function moveItemInArray (arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    //return arr; // for testing
  }

  $scope.getFontStyle = function(textbox, item, additional) {
    return TemplateSvc.getFontStyle($scope.selectedTemplate, textbox, 1, item, additional);
  };

});
