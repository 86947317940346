angular
  .module('app')
  .controller(
    'TemplateAssignModalCtrl',
    function ($scope, $stateParams, $rootScope, AppSvc, TemplateSvc, CompanySvc, AdminSvc) {
      $scope.search = {
        name: '',
        category: {},
        subcategory: {},
        badge: {
          code: '',
        },
        enabled: true,
      };
      $scope.sorter = 'createDate';
      $scope.reverse = true;
      $scope.random = Math.floor(Math.random() * 100000);

      $scope.sortBy = function (sorter) {
        $scope.reverse = $scope.sorter === sorter ? !$scope.reverse : false;
        $scope.sorter = sorter;
      };

      $scope.getCompanies = function () {
        CompanySvc.getCompanies().then(
          function (response) {
            $scope.companies = response.data;
          },
          function (err) {
            $scope.companies = [];
            console.error(err);
          }
        );
      };
      $scope.getCompanies();

      $scope.assignTemplateToIPAdmin = function () {
        $scope.assignTemplateToCompany(
            AdminSvc.selectedTemplate,
          _.findWhere($scope.companies, { number: 'ipadmin' })
        );
      };

      $scope.assignTemplateToCompany = function (company) {
        TemplateSvc.assignTemplate(AdminSvc.selectedTemplate, company._id).then(templateAssigned, function (error) {
          console.log(error);
          $scope.$emit('popup', {
            message: 'Error while assigning template to ' + company.name,
            type: 'alert-danger',
          });
        });
      };

      $scope.assignTemplateToUser = function (user) {
        TemplateSvc.assignTemplate(AdminSvc.selectedTemplate, user.company, user).then(
          templateAssigned,
          function (error) {
            console.log(error);
            $scope.$emit('popup', {
              message: 'Error while assigning template to ' + company.name,
              type: 'alert-danger',
            });
          }
        );
      };

      const templateAssigned = () => {
          $rootScope.$broadcast('template-assigned');
      };
    }
  );
