angular.module('app')
.directive('templateTextbox', [
  function () {
    return {
        restrict: 'E',
        transclude: true,
        scope: '@',
        templateUrl: 'components/template-textbox.html',
        replace: true,
        link: function (scope, el, attrs) {
          //TODO: Put all Textbox specific functions over here instead of in the controllers / services
        }
    };
  }
]);
