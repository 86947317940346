/**
* Angular User Controller
* @file ng/controllers/user.ctrl.js
* @class angular.controllers.UserCtrl
* @memberOf angular.controllers
*/
angular.module('app')
.controller('UserCtrl', function ($scope, $location, AppSvc, UserSvc, TemplateSvc) {

  if (!AppSvc.user) {
    $location.path('/');
  } else {
    if (AppSvc.user.company.isSingleLogin) {
      $location.path('/');
    }
  }

  $scope.usernameToggle = true;
  $scope.passwordToggle = true;
  $scope.birthDate = new Date(AppSvc.user.birthDate);
  $scope.birthDate = $scope.birthDate.toLocaleDateString();
  $scope.displayRatio = 0.4;
  $scope.search = {
    badge: {
      code: ''
    }
  };

  //Make username editable
  $scope.toggleUsername = function() {
    $scope.usernameToggle = !$scope.usernameToggle;
    $scope.newUsername = AppSvc.user.username;
  };

  //Make password editable
  $scope.togglePassword = function() {
    $scope.newPassword = null;
    $scope.confirmPassword = null;
    $scope.passwordToggle = !$scope.passwordToggle;
  };

  //Checks whether the enter password is correct
  $scope.checkPassword = function (password) {
    if (password) {
      var user = AppSvc.user._id;
      UserSvc.checkPassword(user, password)
      .then(function (response) {
        $scope.togglePassword();
      }, function () {
        var originalBg = $(".password").css("backgroundColor");
        $(".password").animate({ backgroundColor: "#FFB6C1" }, 200).animate({ backgroundColor: originalBg }, 200);
      });
    } else {
      $scope.$emit('popup', {
        message: $scope.getCopy('messageChangePasswordFailed'),
        type: 'alert-danger'
      });
    }
  };

  //Change the password
  $scope.changePassword = function (oldPassword, newPassword, confirmPassword) {
    if (newPassword) {
      if (newPassword === confirmPassword) {
        UserSvc.changePassword(AppSvc.user._id, oldPassword, newPassword)
        .then(function (response) {
          $scope.$emit('popup', {
            message: $scope.getCopy('messageChangedPassword'),
            type: 'alert-success'
          });
          $scope.oldPassword = null;
          $scope.togglePassword();
        }, function () {
          $scope.$emit('popup', {
            message: $scope.getCopy('messageChangePasswordFailed'),
            type: 'alert-danger'
          });
        });
      } else {
        var originalBg = $(".password").css("backgroundColor");
        $(".password").animate({ backgroundColor: "#FFB6C1" }, 200).animate({ backgroundColor: originalBg }, 200);
      }
    }
  };

  //Change the username
  $scope.changeUsername = function (username) {
    UserSvc.changeUsername(AppSvc.user._id, username)
    .then(function (response) {
      $scope.$emit('popup', {
        message: 'Username changed to ' + username,
        type: 'alert-success'
      });
      AppSvc.user.username = username;
    }, function(response) {
      $scope.$emit('popup', {
        message: username + $scope.getCopy('taken'),
        type: 'alert-danger'
      });
    });
  };

  //Update the user
  $scope.updateUser = function () {
    AppSvc.user.birthDate = $scope.birthDate;
    UserSvc.updateUser(AppSvc.user)
    .then(function (response) {
      $scope.$emit('update', response.data);
      $scope.$emit('popup', {
        message: $scope.getCopy('messageProfileUpdated'),
        type: 'alert-success'
      });
    });
  };


  //Triggered when the user selects a new template
  $scope.selectTemplate = function(template) {
    TemplateSvc.setSelectedTemplate(template);
  };

  $scope.cloneTemplate = function(template) {
    TemplateSvc.cloneTemplate(template, AppSvc.user)
    .then(function(response) {
      getUserTemplates();
      if (response._id) {
        $scope.$emit('popup', {
          message: $scope.getCopy('saved'),
          type: 'alert-success'
        });
      }
    }, function(error) {
      console.log(error);
    });
  };

  $scope.deleteTemplate = function(template) {
    //TODO not delete myself
    var question = {
      'title': $scope.getCopy('delete') + ' - ' + template.name,
      'question': $scope.getCopy('deleteTemplate') + ' ' + $scope.getCopy('messageActionIrreversible'),
      'model': 'template',
      'api': 'templates',
      'action': 'delete',
      'data': template,
      'exec': function() {
        TemplateSvc.deleteTemplate(question.data)
        .then(function(response) {
          $scope.$emit('questionExecuted', response, question);
          getUserTemplates();
        });
      }
    };
    $scope.askQueryQuestion(question);
  };

  $scope.setDefaultTemplate = function(template) {
    AppSvc.user.templateDefault = template._id;
    UserSvc.updateUser(AppSvc.user);
  };

  $scope.getBadges = function() {
    if (!$scope.userTemplates) return [];
    return _.uniq($scope.userTemplates.map(function(template) {
      return template.badge;
    }), function (badge) {
      return badge.code;
    }).sort(function (a, b) {
      return a.code < b.code ? -1 : 1;
    });
  };

  $scope.getBadgeName = function(badge) {
    if (!badge) return '';
    var index = _.findIndex(badge.copy, {language: $scope.selectedLanguage});
    if (index >= 0) {
      index = _.findIndex(badge.copy, {language: 'EN'});
    }
    return !badge ? '' : badge.copy[index].name;
  };

  function getUserTemplates() {
    TemplateSvc.getTemplatesByUser(AppSvc.user._id)
    .then(function(templates) {
      $scope.userTemplates = templates;
    });
  }

  getUserTemplates();

});
