/**
* Angular Admin - Company Options Tab Controller
* @file ng/controllers/admin/admin.company.Options.ctrl.js
* @class angular.controllers.AdminCompanyOptionsCtrl
* @memberOf angular.controllers
*/
angular.module('app')
.controller('AdminCompanyOptionsCtrl', function ($scope, $stateParams, CompanySvc, AppSvc) {

  $scope.languages = Object.keys(AppSvc.appCopy.language);

  //Gets all the languages for a badge that do not have a translation yet.
  $scope.getLanguages = function() {
    return $scope.languages;
  };

  $scope.updateOption = function(option, language) {
    option.copy[language] = $('#' + option.code + '_' + newLanguages[i]).val();
  };

  $scope.updateCompany = function () {
    CompanySvc.updateCompany($scope.company)
    .success(function (response) {
      if (response._id) {
        $scope.$emit('popup', {
          message: $scope.getCopy('saved'),
          type: 'alert-success'
        });
      }
      $scope.search = '';
    });
  };

  $scope.deleteOption = function(option, index) {
    $scope.company.templateOptions.splice(index, 1);
    var question = {
      'title': $scope.getCopy('option') + ' - ' + option.name,
      'question': $scope.getCopy('deleteOption') + ' ' + $scope.getCopy('messageActionIrreversible'),
      'model': 'option',
      'api': 'company',
      'action': 'delete',
      'data': {company: $scope.company, data: option.name},
      'exec': function() {
        CompanySvc.executeQuestion(question)
        .then(function (response) {
          $scope.$emit('questionExecuted', response, question);
        });
      }
    };
    $scope.askQueryQuestion(question);
  };

  $scope.addOption = function(option) {
    if (option) {
      $scope.searchOption = '';
      $scope.company.templateOptions.unshift({
        name: option,
        values: [],
        copy: Object.keys(AppSvc.appCopy.language).map(function(language) {
          return { language: language, name: '' };
        })
      });
      $scope.updateCompany();
    }
  };

  $scope.updateOption = function(option, type) {
    var newLanguages = $scope.getLanguages(option.copy);
    for (var i in newLanguages) {
      option.copy.push({
        language: newLanguages[i],
        name: $('#' + option.code + '_' + newLanguages[i]).val()
      });
    }
    option.type = type;
    $scope.updateCompany();
  };

  $scope.deleteValue = function(option, index) {
    option.values.splice(index, 1);
    $scope.updateCompany();
  };

  $scope.addValue = function(option, value) {
    if (value) {
      $scope.searchValue = '';
      option.values.unshift({
        name: value
      });
      $scope.updateCompany();
    }
  };

});
