/**
 * Angular Admin - Company User Templates Tab Controller
 * @file ng/controllers/admin/admin.company.user-templates.ctrl.js
 * @class angular.controllers.AdminCompanyUserTemplatesCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller(
		'AdminCompanyUserTemplatesCtrl',
		function ($scope, $stateParams, AppSvc, TemplateSvc, CompanySvc, BadgeSvc) {
			$scope.displayRatio = 0.3;
			$scope.id = 'admin';
			$scope.search = {
				name: '',
				badge: {
					code: '',
				},
				user: {
					username: '',
					email: '',
				},
			};
			$scope.sorter = 'createDate';
			$scope.reverse = true;
			$scope.random = Math.floor(Math.random() * 100000);

			$scope.$on('init', function () {
				$scope.getTemplates();
				$scope.getCompanies();
			});

			$scope.setDefaultTemplate = function (template) {
				$scope.company.templateDefault = template._id;
				CompanySvc.updateCompany($scope.company);
			};

			$scope.sortBy = function (sorter) {
				$scope.reverse = $scope.sorter === sorter ? !$scope.reverse : false;
				$scope.sorter = sorter;
			};

			$scope.getTemplates = function (selectedTemplates = []) {
				TemplateSvc.getUserTemplatesByCompany($stateParams.company).then(
					function (templates) {
						$scope.templates = templates;
						for (let selectedTemplate of selectedTemplates) {
							let template = _.find(
								$scope.templates,
								template => selectedTemplate._id === template._id
							);
							if (template) template.selected = true;
						}
						console.log(templates);

						$scope.refresh();
					}
				);
			};

			$scope.getTemplateSrc = template => TemplateSvc.getTemplateSrc(template);

			BadgeSvc.getBadges().then(function (response) {
				$scope.badges = response.data
					.map(function (badge) {
						badge.name =
							badge.code + ' - ' + badge.shape + ' - ' + badge.description;
						return badge;
					})
					.sort(function (a, b) {
						return a.code < b.code ? -1 : 1;
					});
			});

			$scope.getBadges = function () {
				if (!$scope.templates) return [];
				return _.uniq(
					$scope.templates.map(function (template) {
						return template.badge;
					}),
					function (badge) {
						return badge.code;
					}
				).sort(function (a, b) {
					return a.code < b.code ? -1 : 1;
				});
			};

			$scope.getBadgeName = function (badge) {
				if (!badge) return '';
				var index = _.findIndex(badge.copy, {
					language: $scope.selectedLanguage,
				});
				if (index >= 0) {
					index = _.findIndex(badge.copy, { language: 'EN' });
				}
				return !badge ? '' : badge.copy[index].name;
			};

			$scope.getTemplates();

			$scope.getCompanies = function () {
				CompanySvc.getCompanies().then(
					function (response) {
						$scope.companies = response.data;
					},
					function (err) {
						$scope.companies = [];
						console.error(err);
					}
				);
			};
			$scope.getCompanies();

			$scope.saveTemplate = function (template) {
				TemplateSvc.saveTemplate(template, true).then(function (response) {
					if (response._id) {
						$scope.$emit('popup', {
							message: $scope.getCopy('saved'),
							type: 'alert-success',
						});
					}
				});
			};

			$scope.cloneTemplate = function (template) {
				TemplateSvc.cloneTemplate(template, AppSvc.user).then(
					function (response) {
						$scope.getTemplates();
						if (response._id) {
							$scope.$emit('popup', {
								message: $scope.getCopy('saved'),
								type: 'alert-success',
							});
						}
					},
					function (error) {
						console.log(error);
					}
				);
			};

			$scope.cloneTemplates = template => {
				Promise.all(
					$scope
						.getSelectedTemplates()
						.map(template =>
							TemplateSvc.cloneTemplate(template, AppSvc.user._id)
						)
				).then(
					function (responses) {
						$scope.getTemplates(responses);
						$scope.$emit('popup', {
							message: $scope.getCopy('saved'),
							type: 'alert-success',
						});
					},
					function (error) {
						console.log(error);
					}
				);
			};

			$scope.deleteTemplate = function (template) {
				var question = {
					title: $scope.getCopy('delete') + ' - ' + template.name,
					question:
						$scope.getCopy('deleteTemplate') +
						' ' +
						$scope.getCopy('messageActionIrreversible'),
					model: 'template',
					api: 'templates',
					action: 'delete',
					data: template,
					exec: function () {
						TemplateSvc.deleteTemplate(question.data).then(function (response) {
							$scope.$emit('questionExecuted', response, question);
						});
					},
				};
				$scope.askQueryQuestion(question);
			};
			$scope.deleteTemplates = function () {
				var question = {
					title: $scope.getCopy('delete'),
					question:
						$scope.getCopy('deleteTemplates') +
						' ' +
						$scope.getCopy('messageActionIrreversible'),
					model: 'template',
					api: 'templates',
					action: 'delete',
					data: '',
					exec: function () {
						Promise.all(
							$scope
								.getSelectedTemplates()
								.map(template => TemplateSvc.deleteTemplate(template))
						).then(
							function (responses) {
								$scope.getTemplates();
								$scope.$emit('questionExecuted', response, question);
							},
							function (error) {
								console.log(error);
							}
						);
					},
				};
				$scope.askQueryQuestion(question);
			};

			$scope.assignTemplate = function (template) {
				$scope.selectedTemplate = template;
				$('#template-assign-modal').modal();
			};

			$scope.editTemplate = template => {
				$scope.$parent.$parent.$broadcast('selectTemplate', template._id);
			};

			$scope.assignTemplateToIPAdmin = function (template) {
				$scope.assignTemplateToCompany(
					template,
					_.findWhere($scope.companies, { number: 'ipadmin' })
				);
			};

			$scope.assignTemplateToCompany = function (template, company) {
				TemplateSvc.assignTemplate(template, company._id).then(
					templatesSaved,
					function (error) {
						console.log(error);
						$scope.$emit('popup', {
							message: 'Error while assigning template to ' + company.name,
							type: 'alert-danger',
						});
					}
				);
			};

			$scope.assignTemplateToUser = function (template, user) {
				TemplateSvc.assignTemplate(template, user.company, user).then(
					templatesSaved,
					function (error) {
						console.log(error);
						$scope.$emit('popup', {
							message: 'Error while assigning template to ' + company.name,
							type: 'alert-danger',
						});
					}
				);
			};

			$scope.assignTemplates = company => {
				let selectedTemplates = $scope.getSelectedTemplates();
				Promise.all(
					selectedTemplates.map(template =>
						TemplateSvc.assignTemplate(template, company._id)
					)
				).then(templatesSaved, function (error) {
					console.log(error);
					$scope.$emit('popup', {
						message: 'Error while assigning templates to ' + company.name,
						type: 'alert-danger',
					});
				});
			};

			$scope.updateTemplates = (attribute, value) => {
				let selectedTemplates = $scope.getSelectedTemplates();
				Promise.all(
					selectedTemplates.map(template => {
						template[attribute] = value;
						return TemplateSvc.saveTemplate(template);
					})
				).then(templatesSaved, function (error) {
					console.log(error);
				});
			};

			//Triggered when another badge size is chosen in design and keeps the aspect of all the objects
			$scope.updateTemplateSizes = function (badge) {
				let selectedTemplates = $scope.getSelectedTemplates();
				Promise.all(
					selectedTemplates.map(template => {
						TemplateSvc.setBadge(template, badge);
						return TemplateSvc.saveTemplate(template);
					})
				).then(templatesSaved, function (error) {
					console.log(error);
				});
			};

			$scope.loadAmount = 10;
			$scope.loadMore = () => {
				$scope.loadAmount += 4;
			};

			$scope.getVisibleTemplates = () =>
				$scope.templates ? $scope.templates.filter($scope.filter) : [];

			$scope.getSelectedTemplates = () =>
				$scope.templates
					? $scope.templates.filter(template => template.selected)
					: [];

			$scope.selectTemplate = template => {
				template.selected = !template.selected;
			};

			$scope.selectAllVisibleTemplates = () => {
				const visibleTemplates = $scope.getVisibleTemplates();
				const select =
					visibleTemplates.length !==
					visibleTemplates.filter(template => template.selected).length;
				for (let template of $scope.templates) {
					if (
						_.some(
							visibleTemplates,
							visibleTemplate => visibleTemplate._id === template._id
						)
					)
						template.selected = select;
				}
			};

			const templatesSaved = templates => {
				$scope.getTemplates(templates);
				$scope.$emit('popup', {
					message: $scope.getCopy('saved'),
					type: 'alert-success',
				});
			};

			$scope.filter = template => {
				if (!$scope.search) return true;
				if (!template || !template.user) return true;
				return (
					template.name.toLowerCase().includes($scope.search.name) &&
					(template.badge.code === $scope.search.badge.code ||
						$scope.search.badge.code === '') &&
					template.user.username.includes($scope.search.user.username) &&
					template.user.email.includes($scope.search.user.email)
				);
			};
		}
	);
