angular.module('app')
	.service('StatsSvc', function($http) {

		var svc = this;

		svc.getTemplateStats = function() {
			return $http.get('/api/stats/templates');
		};

		svc.getSessionStats = function() {
			return $http.get('/api/stats/sessions');
		};

		svc.getStats = function() {
			return $http.get('/api/stats');
		};

	});