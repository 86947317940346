angular.module('app').service('BadgeSvc', function ($http) {
	var svc = this;

	svc.startingBadge = {};

	svc.getBadges = function () {
		return $http.get('/api/badges');
	};

	svc.saveBadge = function (badge) {
		return $http.post('/api/badges/', { badge: badge });
	};

	svc.updateBadge = function (_id, badge) {
		return $http.put('/api/badges/' + _id, { badge: badge });
	};

	svc.setSelectedBadge = badge => {
		svc.selectedBadge = badge;
		if (!svc.startingBadge[badge.code]) {
			svc.startingBadge[badge.code] = 1;
		}
	};

	//Special = a non-basic geometric shape (ie, door tool, medallion,...)
	svc.getShapes = () => ['Rectangle', 'Oval', 'Half Oval', 'Special'];

	const random = Math.floor(Math.random() * 10000000);

	//Displays the border of the badge.
	svc.getShapeBorderStyle = (
		{ shape, code },
		modifier,
		background = 'white',
		type = 'base'
	) => {
		switch (shape) {
			case 'Oval':
				return {
					'border-top-left-radius': `50%`,
					'border-top-right-radius': `50%`,
					'border-bottom-left-radius': `50%`,
					'border-bottom-right-radius': `50%`,
				};
				break;
			case 'Half Oval':
				return {
					'border-top-left-radius': `50% 100%`,
					'border-top-right-radius': `50% 100%`,
					'border-bottom-left-radius': `0px`,
					'border-bottom-right-radius': `0px`,
				};
				break;
			case 'Special':
				switch (type) {
					case 'base':
						//Base shape cut out (default)
						return {
							//Background image
							'background-image': `url("images/backgrounds/${background}.png")`,
							'background-size': '100% 100%',
							'-webkit-mask-image': `url('images/shapes/${code}.png')`,
							'mask-image': `url('images/shapes/${code}.png')`,
							'webkit-mask-size': '100% 100%',
							'mask-size': '100% 100%',
						};
					case 'outline':
						//Dotted line of designable areas
						return {
							'background-image': `url("images/shapes/${code}_outline.png")`,
							'background-size': '100% 100%',
						};
					case 'mask':
						return {
							//Background image
							'background-image': `url("images/backgrounds/${background}.png")`,
							'background-size': '100% 100%',
							'-webkit-mask-image': `url('images/shapes/${code}_${type}.png')`,
							'mask-image': `url('images/shapes/${code}_${type}.png')`,
							'webkit-mask-size': '100% 100%',
							'mask-size': '100% 100%',
						};
					case 'crop':
						//Crop out what's not part of the shape at all
						return {
							'background-color': '#DDDDDD',
							'-webkit-mask-image': `url('images/shapes/${code}_${type}.png')`,
							'mask-image': `url('images/shapes/${code}_${type}.png')`,
							'webkit-mask-size': '100% 100%',
							'mask-size': '100% 100%',
							'border-radius': '2px',
						};
				}
			//Default to rectangle
			case 'Rectangle':
			default:
				return {
					'border-top-left-radius': `${5 * modifier}px`,
					'border-top-right-radius': `${5 * modifier}px`,
					'border-bottom-left-radius': `${5 * modifier}px`,
					'border-bottom-right-radius': `${5 * modifier}px`,
				};
		}
	};

	//Displays the background of the plate
	svc.getShapeStyle = (badge, background = 'white', modifier = 1) => {
		let style = {
			width: `100%`,
			height: `100%`,
			//'margin': 'auto',
			'background-image': `url("images/backgrounds/${background}.png")`,
			'background-size': '100% 100%',
		};
		return {
			...style,
			...svc.getShapeBorderStyle(badge, modifier, background),
		};
	};

	//Hides overflowed object that wouldn't be printed
	svc.getShapeMaskStyle = (
		badge,
		background = 'white',
		modifier = 1,
		type = 'mask'
	) => {
		let style = {
			position: 'absolute',
			top: 0,
			width: `100%`,
			height: `100%`,
			//'margin': 'auto',
			...svc.getShapeBorderStyle(
				{ shape: badge.shape, code: badge.code },
				modifier,
				background,
				type
			),
		};
		if (badge.shape === 'Special') {
			return style;
		} else {
			return style;
		}
	};

	svc.getFilteredBadges = enteredData => {
		const data = enteredData.filter(
			({ badge }) => badge.code === svc.selectedBadge.code
		);
		return data;
	};
});
