/*
    _   _   ___     _      ___   ___
   | | | | / __|   /_\    / __| | __|
   | |_| | \__ \  / _ \  | (_ | | _|
    \___/  |___/ /_/ \_\  \___| |___|

  https://stackoverflow.com/questions/14995884/select-text-on-input-focus

*/

angular.module('app')
.directive('selectOnClick', ['$window', function ($window) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.on('click', function () {
                if (!$window.getSelection().toString()) {
                    // Required for mobile Safari
                    this.setSelectionRange(0, this.value.length);
                }
            });
        }
    };
}]);
