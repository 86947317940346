/**
 * Angular Admin Controller
 * @file ng/controllers/admin/admin.ctrl.js
 * @class angular.controllers.AdminCtrl
 * @memberOf angular.controllers
 */
angular
	.module('app')
	.controller('AdminCtrl', function ($scope, $location, AppSvc) {
		if (!AppSvc.user || !(AppSvc.user.admin || AppSvc.user.options)) {
			$location.path(AppSvc.getURL());
		}
	});
